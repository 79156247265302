import { Box, Flex, Image, Tag, Text, useColorModeValue } from "@chakra-ui/react";
import TextExpanded from "components/@textExpanded";
import moment from "moment";
import { memo } from "react";
import { AiFillFacebook, AiFillInstagram } from "react-icons/ai";

const BasicData = ({ data }) => {
  const textColor = useColorModeValue("navy.700", "white");

  const renderAge = (date) => {
    if (!date) return "";
    const age = moment().diff(date, "years");
    return `${age.toString()}`;
  };

  return (
    <Flex
      borderWidth="1px"
      borderRadius="lg"
      w={{ sm: '100%', md: '100%' }}
      direction={{ base: 'column', md: 'row' }}
      height={{ sm: '100%' }}
      bg={useColorModeValue('white', 'gray.900')}
      boxShadow={'2xl'}
      padding={4}
    >
      <Box marginRight={4}>
        <Image
          borderTopLeftRadius={6}
          borderTopRightRadius={6}
          flex={0.1}
          boxSize="100%"
          height="72"
          width="72"
          objectFit="contain"
          src={
            data?.photo ||
            "https://react.semantic-ui.com/images/wireframe/image.png"
          }
        />
      </Box>
      <Flex direction="column">
        <Text
          fontSize='lg'
          fontWeight='500'
          marginTop={{ base: 4, md: 0 }}
          color={textColor}>
          {data?.name}
          <strong>{data?.isDeath ? " (Falecido) " : ""}</strong>
          {`, ${renderAge(data?.dateOfBirth || "")} anos.`}
        </Text>
        <>
          {data?.socialMidia_facebook ? (
            <Flex alignItems="center" marginTop={2}>
              <Flex marginRight={2}>
                <AiFillFacebook color="blue" />
              </Flex>
              <a
                target="_blank"
                href={data.socialMidia_facebook}
                rel="noreferrer"
              >
                Acessar
              </a>
            </Flex>
          ) : (
            <Flex alignItems="center">
              {" "}
              <Flex marginRight={2}>
                <AiFillFacebook color="blue" />
              </Flex>
              <Text
                marginLeft={2}
                fontSize='md'
                color={textColor}
              >
                {data?.isFace ? "Não tem Facebook." : "Não informado"}
              </Text>
            </Flex>
          )}
          {data?.socialMidia_instagran ? (
            <Flex alignItems="center">
              <Flex marginRight={2}>
                <AiFillInstagram color="#AB00F3" />
              </Flex>
              <a
                target="_blank"
                href={data.socialMidia_instagran}
                rel="noreferrer"
              >
                Acessar
              </a>
            </Flex>
          ) : (
            <Flex alignItems="center">
              <Flex marginRight={2}>
                <AiFillInstagram color="#AB00F3" />
              </Flex>
              <Text
                marginLeft={2}
                fontSize='md'
                color={textColor}
              >
                {data?.isInst ? "Não tem Instagram." : "Não informado"}
              </Text>
            </Flex>
          )}
        </>
        <Flex marginTop={2} alignItems="center">
          <Text
            fontWeight='500'
            fontSize='md'
            marginRight={1}
            color={textColor}>
            Categoria(s):
          </Text>
          <Flex flexWrap="wrap">
            {data?.category?.length > 0 &&
              data?.category?.map((it) => (
                <Tag
                  padding={2}
                  size='md'
                  marginRight={1}
                  colorScheme='orange'
                  borderRadius='md'>
                  {it.name}
                </Tag>
              ))}
          </Flex>
        </Flex>
        <TextExpanded
          marginTop="6"
          text={data?.description || ""}
          textSize={100}
        />
      </Flex>
    </Flex>
  )
}

export default memo(BasicData);