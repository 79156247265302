import { Badge, Flex } from "@chakra-ui/react";
import { useState } from "react";

const TextExpanded = ({onClickReadMore, textLabel, text, textSize = 400, key, ...props }) => {
  const [showText, setShowText] = useState(false);

  return (
    <Flex key={key} w="full" {...props}>
      <div style={{ whiteSpace: "pre-line", marginBottom: 0, color: "#1B254A" }}>
        {textLabel && (
          <span
            style={{
              fontSize: 16,
              fontWeight: 600,
              marginRight: 4,
            }}>
            {textLabel}
          </span>
        )}
        {text.length < textSize ? text : (
          <>
            {showText ? text : text.slice(0, textSize) + '...'}
            {text.length > textSize && (
              <Badge
                cursor="pointer"
                marginLeft="2"
                onClick={(e) => {
                  if (onClickReadMore) {
                    onClickReadMore(e);
                  }
                  e.stopPropagation();
                  setShowText(!showText)
                }}
                colorScheme='red'
              >
                {showText ? 'ver menos' : ' ver mais'}
              </Badge>
            )}
          </>
        )}
      </div>
    </Flex>
  )
}
export default TextExpanded;