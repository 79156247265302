import { Box, Flex, Image, Text, useColorModeValue } from "@chakra-ui/react";
import { memo } from "react";
import { mask } from "utils/mask";


const Contact = ({ data }) => {
  const textColor = useColorModeValue("navy.700", "white");
  return (
    <Flex
      borderWidth="1px"
      borderRadius="lg"
      w={{ sm: '100%', md: '100%' }}
      height={{ sm: '100%' }}
      direction={{ base: 'column', md: 'row' }}
      bg={useColorModeValue('white', 'gray.900')}
      boxShadow={'2xl'}
      padding={4}
    >
      <Box marginRight={4}>
        <Image
          borderTopLeftRadius={6}
          borderTopRightRadius={6}
          flex={0.1}
          boxSize="100%"
          height="72"
          width="72"
          objectFit="contain"
          src={
            data?.photo ||
            "https://react.semantic-ui.com/images/wireframe/image.png"
          }
        />
      </Box>
      <Flex direction="column">
        <Text
          fontSize='lg'
          fontWeight='500'
          marginTop={{ base: 4, md: 0 }}
          color={textColor}>
          {data?.name}
        </Text>
        <br />
        <Text
          fontWeight='600'
          fontSize='md'
          marginRight={2}
          color={textColor}>
          Contato:
        </Text>
        <Flex>
          <Text
            fontWeight='500'
            fontSize='md'
            marginLeft={3}
            marginRight={2}
            color={textColor}>
            WhatsApp:
          </Text>
          {data?.whatsApp && mask(data.whatsApp) ? (
            <a
              style={{ color: "black" }}
              target="_blank"
              href={`whatsapp://send?phone=${data?.whatsApp}`}
              rel="noreferrer"
            >
              <Text
                fontSize='md'
                color={textColor}>
                {mask(data?.whatsApp || " Não informado")}
              </Text>
            </a>
          ) : (
            " Não informado"
          )}
        </Flex>
        <Flex>
          <Text
            fontWeight='500'
            fontSize='md'
            marginRight={2}
            marginLeft={3}
            color={textColor}>
            Contato sem WhatsApp:
          </Text>
          {data?.phone ? (
            <Text
              fontSize='md'
              color={textColor}>
              {mask(data?.phone || " Não informado")}
            </Text>
          ) : (
            " Não informado"
          )}
        </Flex>
        <br />
        <Text
          fontWeight='600'
          fontSize='md'
          marginRight={2}
          color={textColor}>
          Contato para recado:
        </Text>
        <Flex>
          <Text
            fontWeight='500'
            fontSize='md'
            marginLeft={3}
            color={textColor}>
            WhatsApp para recado:
          </Text>
          <a
            style={{ color: "black" }}
            target="_blank"
            href={`whatsapp://send?phone=${data?.whatsAppContact}`}
            rel="noreferrer"
          >
            <Text
              fontSize='md'
              marginLeft={2}
              color={textColor}>
              {data?.whatsAppContact
                ? `${mask(data?.whatsAppContact)}`
                : "Não informado"} {data?.whatsAppContactName ? `(${data?.whatsAppContactName})` : ""}
            </Text>
          </a>
        </Flex>
        <Flex>
          <Text
            fontWeight='500'
            fontSize='md'
            marginLeft={3}
            color={textColor}>
            Contato sem WhatsApp:
          </Text>
          <Text
            fontSize='md'
            marginLeft={2}
            color={textColor}>
            {mask(data?.phoneContact || " Não informado")} {data?.phoneContact ? `(${data?.phoneContactName})` : ""}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}
export default memo(Contact);