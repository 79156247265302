
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  Select as SelectChakra,
} from "chakra-react-select";

import axiosCategorie from "../../services/urls/category";
import SideBar from "components/@sidebar";
import {
  Box,
  Flex,
  Input,
  Text,
  useColorModeValue,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
  Select,
  useToast,
  InputGroup,
  FormLabel,
  FormControl,
  Skeleton,
  Card,
  Image,
  ButtonGroup,
  Stack,
  IconButton,
  Textarea
} from "@chakra-ui/react";

import { MdArrowDropDown } from "react-icons/md";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import user from "../../services/urls/user";
import { AiOutlineSearch } from "react-icons/ai";
import { EditIcon, CheckIcon, ViewIcon, AddIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { mask } from "utils/mask";
import { FaWhatsapp } from "react-icons/fa";

const QuickRegistrationPage = () => {
  const toast = useToast();
  let shoulRequest = useRef(true);

  const { _id, name: accountName } = useSelector(
    (state) =>
      state.user
  );
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [loading, setLoading] = useState(false);
  const history = useNavigate();

  const cancelRef = useRef();
  const { isOpen: showUser, onOpen: openShowUser, onClose: onCloseShowUer } = useDisclosure();
  const [date, setDate] = useState("");
  const [userNameFull, setNameFull] = useState("")
  const [gender, setGender] = useState("")
  const [whatsApp, setWhatsApp] = useState("");
  const [phone, setPhone] = useState("");
  const [usersSearch, setUsersSearch] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categorie, setCategorie] = useState([]);
  const [userId, setUserId] = useState("");
  const [description, setDescription] = useState("");
  const [buttonsVisible, setButtonsVisible] = useState(false);
  const [whatsAppContact, setWhatsAppContact] = useState("");
  const [phoneContact, setPhoneContact] = useState("");

  const searchUser = async () => {
    try {
      setIsLoading(true);
      const response = await user.SearchUser(date);
      if (response.data.data.length === 0) {
        toast({
          title: '',
          description: "Não foi localizado nenhuma informacão para está data!",
          status: 'info',
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });
      }
      else {
        setUsersSearch(response.data.data);
        openShowUser();
      }
      setIsLoading(false);
    } catch (error) {
      toast({
        title: '',
        description: "Erro ao procurar dados",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
      setIsLoading(false);
    }
  }

  const updateUser = async (hasNavigate = false) => {
    try {
      if (!userNameFull || !gender || !date || gender === "Selecione") {
        toast({
          title: '',
          description: "Nome, Gênero e data de nascimento é obrigatório!",
          status: 'info',
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      setLoading(true);
      const mandatory = {
        accountId: _id,
        accountName: accountName,
        day: moment().format("DD/MM/YYYY"),
        hour: moment().format("HH:mm:ss"),
        userAction: "Adicionou",
        dateTime: moment().format(),
        userName: userNameFull,
      }

      if (!userId) {
        const { data } = await user.createUserSpeedUserSpeed(
          userNameFull,
          date,
          categorie.length > 0 ? categorie.map((item) => item.value) : undefined,
          whatsApp,
          phone,
          gender,
          mandatory.accountId,
          mandatory.accountName,
          mandatory.day,
          mandatory.hour,
          mandatory.userAction,
          mandatory.dateTime,
          moment().format("DD/MM/YYYY [às] HH:mm:ss"),
          description,
          whatsAppContact,
          phoneContact,
        );
        setLoading(false)
        toast({
          title: '',
          description: "Cadastro realizado com sucesso",
          status: 'success',
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });

        setUserId(data.data._id);
      } else {
        await user.updateUserSpeed(
          userId,
          mandatory.userName,
          mandatory.userName,
          date,
          categorie.length > 0 ? categorie.map((item) => item.value) : undefined,
          whatsApp,
          phone,
          gender,
          mandatory.accountId,
          mandatory.accountName,
          mandatory.day,
          mandatory.hour,
          mandatory.dateTime,
          description,
          moment().format("DD/MM/YYYY [às] HH:mm:ss"),
          whatsAppContact,
          phoneContact
        );
        setLoading(false)
        toast({
          title: '',
          description: "Dados atualizado com sucesso!",
          status: 'success',
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });
      }
      setButtonsVisible(true);
      if (hasNavigate) {
        history(
          "/editar-dados-do-cadastro",
          {
            state: {
              id: userId,
            }
          },
        );
      }
    } catch (error) {
      toast({
        title: '',
        description: "Erro ao atualizar dados do cadastrado!",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  }

  const renderAge = (date) => {
    if (!date) return "Não informado.";
    const age = moment().diff(date, "years");
    return `${age.toString()}`;
  };

  const requestCategories = useCallback(async () => {
    try {
      const response = await axiosCategorie.GetCategoriesActive();
      const not_category = [];
      const dataConc = not_category.concat(
        response?.data?.data.map((item) => {
          return {
            label: item.name,
            value: item._id,
          };
        })
      );
      setCategories(dataConc);
    } catch (error) {
      toast({
        title: '',
        description: "Erro ao buscar categorias.",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  }, [toast]);

  const whatsAppIsValid = useCallback(() => {
    if (whatsApp.includes("55") || whatsApp.includes("595")) {
      return true
    }
    return false
  }, [whatsApp])

  const whatsAppContactIsValid = useCallback(() => {
    if (whatsAppContact.includes("55") || whatsAppContact.includes("595")) {
      return true
    }
    return false
  }, [whatsAppContact])

  useEffect(() => {
    if (shoulRequest.current) {
      shoulRequest.current = false;
      requestCategories();
    }
  }, [requestCategories]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SideBar />
      <Flex
        flexDirection="column"
        borderRadius={10}
        padding={6}
        marginTop={4}
        marginBottom="10"
        marginLeft={4}
        marginRight={4}
        backgroundColor="white">
        <Flex
          direction="column"
          padding={4}
        >
          <Flex direction={{ base: "column", md: "row" }}>
            <Flex flex={1} justifyContent="space-between">
              <FormControl m={2}>
                <FormLabel
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  display='flex'>
                  Data de nascimento<Text>*</Text>
                </FormLabel>
                <InputGroup size='md'>
                  <Input
                    style={{ backgroundColor: "white" }}
                    value={date}
                    isRequired
                    onChange={(event) => {
                      setDate(event.target.value);
                    }}
                    fontSize='sm'
                    mb='24px'
                    size='lg'
                    type={"date"}
                    variant='auth'
                  />
                </InputGroup>
              </FormControl>
              <Flex marginTop="9" justifyContent="flex-end">
                <IconButton
                  isLoading={isLoading}
                  w='46px'
                  bg="green.400"
                  h='46px'
                  marginRight={1}
                  onClick={() => searchUser()}
                  icon={<AiOutlineSearch color="white" />}
                />
              </Flex>
            </Flex>
            <Flex flex={2} direction={{ base: "column", md: "row" }}>
              <FormControl m={2}>
                <FormLabel
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  display='flex'>
                  Nome<Text>*</Text>
                </FormLabel>
                <InputGroup size='md'>
                  <Input
                    value={userNameFull}
                    onChange={(event) => setNameFull(event.target.value)}
                    fontSize='sm'
                    style={{ backgroundColor: "white" }}
                    isRequired
                    mb='24px'
                    size='lg'
                    type={"text"}
                    variant='auth'
                  />
                </InputGroup>
              </FormControl>
              <FormControl m={2}>
                <FormLabel
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  display='flex'>
                  Gênero<Text>*</Text>
                </FormLabel>
                <Select
                  mb='24px'
                  style={{ backgroundColor: "white" }}
                  size='lg'
                  borderRadius={15}
                  variant='outline'
                  isRequired
                  value={gender}
                  onChange={(event) => setGender(event.target.value)}
                  icon={<MdArrowDropDown />}>
                  <option value={""}>Selecione</option>
                  {["Masculino", "Feminino"].map((item) => (
                    <option value={item}>{item}</option>
                  ))}
                </Select>
              </FormControl>
            </Flex>
          </Flex>
          <Flex flexDirection={{ base: "column", md: "row" }}>
            <FormControl marginLeft={2} marginRight={4}>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                WhatsApp
              </FormLabel>
              <PhoneInput
                placeholder=""
                isValid={whatsApp.length > 2 ? whatsAppIsValid() : true}
                value={whatsApp}
                buttonStyle={{
                  borderTopLeftRadius: 16,
                  borderBottomLeftRadius: 16,
                }}
                inputStyle={{
                  flex: 1,
                  borderRadius: 16,
                  height: 50,
                  borderColor: '#E0E5F2',
                  width: '100%',
                }}
                country='br'
                onlyCountries={['br', 'py',]}
                onChange={setWhatsApp}
              />
            </FormControl>
            <FormControl mt={{ base: 5, md: 0 }} marginLeft={2}>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Telefone sem WhatsApp
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  style={{ backgroundColor: "white" }}
                  value={phone}
                  onChange={(event) => setPhone(event.target.value)}
                  fontSize='sm'
                  mb='24px'
                  size='lg'
                  type={"tel"}
                  variant='auth'
                />
              </InputGroup>
            </FormControl>
          </Flex>
          <Flex flexDirection={{ base: "column", md: "row" }}>
            <Flex direction="row" flex={1}>
              <FormControl marginLeft={2} marginRight={4}>
                <FormLabel
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  display='flex'>
                  WhatsApp para{''}<Text marginLeft={1} fontWeight="bold">recado</Text>
                </FormLabel>
                <PhoneInput
                  placeholder=""
                  isValid={whatsAppContact.length > 2 ? whatsAppContactIsValid() : true}
                  value={whatsAppContact}
                  buttonStyle={{
                    borderTopLeftRadius: 16,
                    borderBottomLeftRadius: 16,
                  }}
                  inputStyle={{
                    flex: 1,
                    borderRadius: 16,
                    height: 50,
                    borderColor: '#E0E5F2',
                    width: '100%',
                  }}
                  country='br'
                  onlyCountries={['br', 'py',]}
                  onChange={setWhatsAppContact}
                />
              </FormControl>
            </Flex>
            <Flex flex={1}>
            <FormControl marginRight={5} marginLeft={2}>
                <FormLabel
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  display='flex'>
                  Telefone sem WhatsApp para<Text marginLeft={1} fontWeight="bold">recado</Text>
                </FormLabel>
                <InputGroup size='md'>
                  <Input
                    style={{ backgroundColor: "white" }}
                    value={phoneContact}
                    onChange={(event) => setPhoneContact(event.target.value)}
                    fontSize='sm'
                    mb='24px'
                    size='lg'
                    type={"tel"}
                    variant='auth'
                  />
                </InputGroup>
              </FormControl>
            </Flex>
          </Flex>
          <Flex flexDirection={{ base: "column", md: "row" }}>
          </Flex>
          <Flex direction="column">
            <FormControl m={2}>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Categoria
              </FormLabel>
              <SelectChakra
                isMulti
                placeholder=""
                onChange={(event) => setCategorie(event)}
                colorScheme="green"
                value={categorie}
                options={categories}
              />
            </FormControl>
            <FormControl m={2}>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Observação do cadastrado
              </FormLabel>
              <InputGroup size='md'>
                <Textarea
                  height="350px"
                  value={description}
                  onChange={(event) =>
                    setDescription(event.target.value)
                  }
                  placeholder="Observação do cadastrado"
                />
              </InputGroup>
            </FormControl>
          </Flex>
          <Flex
            flexWrap="wrap"
            direction={{ base: 'column', md: 'row' }}
            marginTop={10}
            justifyContent={{ base: 'flex-start', md: 'flex-end' }}>
            <Button
              isDisabled={loading}
              isLoading={loading}
              leftIcon={<CheckIcon />}
              mr={{ base: '0', md: '2' }}
              onClick={() => updateUser()}
              type="button"
              variant='brand'
            >
              {userId ? 'Atualizar cadastro' : 'Salvar'}
            </Button>
            {(userId && buttonsVisible) && (
              <>
                <Button
                  leftIcon={<EditIcon />}
                  isDisabled={loading}
                  isLoading={loading}
                  mr={{ base: '0', md: '2' }}
                  mt={{ base: '2', md: '0' }}
                  onClick={() => {
                    if (userId) {
                      updateUser(true)
                    }
                    else {
                      history(
                        "/editar-dados-do-cadastro",
                        {
                          state: {
                            id: userId,
                          }
                        },
                      );
                    }
                  }}
                  type="button"
                  variant='brand'
                >
                  Continuar cadastrando
                </Button>
                <Button
                  leftIcon={<ViewIcon />}
                  isDisabled={loading}
                  mt={{ base: '2', md: '0' }}
                  onClick={() => {
                    history(
                      "/visualizar-cadastrado",
                      {
                        state: {
                          userId: userId,
                        }
                      },
                    );
                  }}
                  type="button"
                  variant='brand'
                >
                  Visualizar cadastro
                </Button>
              </>
            )}
            <Button
              leftIcon={<AddIcon />}
              isDisabled={loading}
              mb={{ base: 2, md: 0 }}
              mt={{ base: '2', md: '0' }}
              mr={{ base: '0', md: '2' }}
              ml={{ base: '0', md: '2' }}
              onClick={() => {
                setUserId("");
                setNameFull("");
                setCategorie([]);
                setWhatsApp("55");
                setPhone("");
                setDate("");
                setGender("");
                setDescription("");
                setButtonsVisible(false);
                setWhatsAppContact("");
                setPhoneContact("");
              }}
              type="button"
              colorScheme='red'
              variant='outline'
            >
              Limpar tela
            </Button>
          </Flex>
        </Flex>
      </Flex>

      <AlertDialog
        size="4xl"
        isOpen={showUser}
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onCloseShowUer();
        }}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              <Text
                textAlign="center"
                color={textColor}
                fontSize='lg'
                marginBottom={2}>
                CADASTRADOS
              </Text>
            </AlertDialogHeader>
            <AlertDialogBody>
              {loading ? (
                <Stack>
                  <Skeleton marginTop="2" borderRadius={15} height='80px' />
                  <Skeleton marginTop="2" borderRadius={15} height='80px' />
                  <Skeleton marginTop="2" borderRadius={15} height='80px' />
                  <Skeleton marginTop="2" borderRadius={15} height='80px' />
                </Stack>
              ) : (
                usersSearch?.map((item) => (
                  <a>
                    <Card
                      key={item._id}
                      padding="2"
                      marginBottom={4}
                      boxShadow="lg"
                      children={
                        <Flex direction={{ base: 'column', md: 'row' }}>
                          <Flex direction={{ base: 'column', md: 'row' }}>
                            <Box>
                              <Flex minW="40">
                                <Image
                                  boxSize={{ base: '100%', md: '120px' }}
                                  borderTopLeftRadius={6}
                                  borderTopRightRadius={6}
                                  marginRight={4}
                                  objectFit="cover"
                                  borderRadius={6}
                                  src={
                                    item.photo ||
                                    "https://react.semantic-ui.com/images/wireframe/image.png"
                                  } />
                              </Flex>
                            </Box>
                            <Flex
                              direction="column"
                              marginTop={{ base: 4, md: 0 }}
                            >
                              <Text
                                color={textColor}
                                fontSize='lg'
                                fontWeight="500">
                                {item.name}, {renderAge(item?.dateOfBirth)} anos.
                              </Text>

                              <Text
                                color={textColor}
                                fontSize='md'
                                fontWeight="normal">
                                {moment(item?.dateOfBirth).format("DD/MM/YYYY")}
                              </Text>

                              <Flex direction="row" alignItems="center" as="a" href={item.whatsApp.length > 2 ? 'whatsapp://send?phone=${whatsApp}' : undefined}>
                                {item.whatsApp.length > 3 && (
                                  <Flex mr="1">
                                    <FaWhatsapp color="green" />
                                  </Flex>
                                )}
                                <Text
                                  color={textColor}
                                  fontSize='md'
                                  fontWeight="normal">
                                  {mask(item?.whatsApp).replace(" - 🇵🇾", "").replace(" - 🇧🇷", "")}
                                </Text>
                              </Flex>
                              <Text
                                color={textColor}
                                fontSize='md'
                                fontWeight="500">
                                {item.whatsAppContact}
                              </Text>
                            </Flex>
                          </Flex>
                          <Flex
                            flex={1}
                            alignItems="center"
                            marginTop={{ base: 6, md: 0 }}
                            justifyContent={{ base: "center", md: "flex-end" }}>
                            <>
                              <ButtonGroup variant='outline' spacing='6' mr="2">
                                <Button
                                  mb={{ base: 2, md: 4 }}
                                  onClick={() => {
                                    setNameFull(item.name);
                                    setGender(item.gender);
                                    if (item?.category) {
                                      setCategorie(item?.category?.length > 0 ? item?.category?.map((it) => {
                                        return {
                                          label: it.name,
                                          value: it._id,
                                        }
                                      }) : []);
                                    }
                                    
                                    setWhatsApp(item?.whatsApp);
                                    setPhone(item?.phone);
                                    setUserId(item._id);
                                    setDescription(item?.description)
                                    setButtonsVisible(true);
                                    setWhatsAppContact(item?.whatsAppContact);
                                    setPhoneContact(item?.phoneContact);
                                    onCloseShowUer();
                                  }}
                                  colorScheme='blue'>
                                  Selecionar
                                </Button>
                              </ButtonGroup>
                            </>
                          </Flex>
                        </Flex>
                      }
                    />
                  </a>
                ))
              )}
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                marginRight={2}
                colorScheme="red"
                onClick={() => {
                  onCloseShowUer();
                }}
              >
                Fechar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};
export default QuickRegistrationPage;