import React from 'react';
import {
  Flex,
  FormLabel,
  Box,
  Image,
  IconButton,
  Tooltip,
  Button,
} from '@chakra-ui/react';
import { AiFillDelete, AiFillFileAdd } from 'react-icons/ai';
import { FaUpload } from 'react-icons/fa';

const ImageUploader = ({
  imageBase64,
  onRemoveImage,
  onFileChange,
  onButtonClick,
  inputFileRef,
  label,
  tooltip,
  textColor,
  isProfile = false,
}) => {
  return (
    <Flex marginLeft={2} direction="column">
      <FormLabel
        ms="4px"
        fontSize="sm"
        fontWeight="500"
        color={textColor}
        display="flex"
      >
        {!isProfile ? label : null}
      </FormLabel>
      {imageBase64 ? (
        <Flex flexDirection="column" marginLeft={1}>
          <Box boxSize="44" position="relative">
            <Image borderRadius={6} objectFit="cover" src={imageBase64} />
            <IconButton
              position="absolute"
              top={2}
              left={2}
              onClick={onRemoveImage}
              icon={<AiFillDelete color="red" />}
            />
          </Box>
        </Flex>
      ) : (
        <Box marginTop={0} marginLeft={2}>
          {!isProfile && (
            <>
              <Tooltip placement="right" label={tooltip}>
                <IconButton
                  colorScheme="blue"
                  onClick={onButtonClick}
                  icon={<AiFillFileAdd />}
                />
              </Tooltip>
            </>
          )}
          {isProfile && (
            <Button onClick={onButtonClick} leftIcon={<FaUpload />} variant='brand'>
            Carregar foto
          </Button>
          )}
          <input
            type="file"
            ref={inputFileRef}
            style={{ display: 'none' }}
            accept="image/*"
            onChange={onFileChange}
          />
        </Box>
      )}
    </Flex>
  );
};

export default ImageUploader;
