import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  Icon,
} from "semantic-ui-react";

import axiosLogger from "../../services/urls/logs";
import { AiFillFilter, AiOutlineUser } from "react-icons/ai";
import { MdArrowDropDown } from 'react-icons/md'

import {
  Box,
  Flex, IconButton,
  Skeleton,
  Button,
  Stack,
  Tag,
  TagLabel,
  Text,
  useColorModeValue,
  Input,
  FormControl,
  FormLabel,
  InputGroup,
  Select,
  Spinner
} from "@chakra-ui/react";
import SideBar from "components/@sidebar";
import IconBox from "components/icons/IconBox";
import Card from "components/card/Card";
import DrawerRigth from "components/drawer_rigth";
import loggerByGroup, { groupByDateTime } from "./sort";

const LogActionsPage = () => {
  const { proprietaryid } = useSelector((state) => state.user);
  let shoulRequest = useRef(true);
  const [data, setData] = useState([]);
  const [copyData, setCopyData] = useState([]);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [perPage] = useState(100);
  const [page, setPage] = useState(0);
  const [isVoid, setIsVoid] = useState(false);

  const [action, setAction] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDateInput, setStartDateInput] = useState("");
  const [endDateInput, setEndDateInput] = useState("");
  const textColor = useColorModeValue("secondaryGray.900", "white");

  const requestLogFilter = async () => {
    try {
      setIsVoid(false);
      setLoading(true);

      const response = await axiosLogger.listLogs(
        page.toString(),
        perPage.toString(),
        action,
        startDate,
        endDate,
        proprietaryid
      );

      setCopyData(response.data.data);

      const orderData = groupByDateTime(copyData.concat(response.data.data))

      setData(orderData);
      setLoadingPage(false);
      setIsVoid(response.data?.data?.length === 0);
      setLoading(false);
      const somePage = page + 1;
      setPage(somePage)
    } catch (_) {
      setLoadingPage(false);
      setLoading(false);
    }
  };

  const requestLogFilterClean = async () => {
    try {
      setIsVoid(false);
      setLoading(true);
      const response = await axiosLogger.listLogs(
        page.toString(),
        perPage.toString(),
        undefined,
        undefined,
        undefined,
        proprietaryid
      );

      setCopyData(response.data.data);

      const orderData = await loggerByGroup(copyData.concat(response.data.data))
      const reorder = orderData.sort(function (a, b) {
        return (
          new Date(
            `${b.date.split("/")[2]}-${b.date.split("/")[1]}-${b.date.split("/")[0]
            }`
          ) -
          new Date(
            `${a.date.split("/")[2]}-${a.date.split("/")[1]}-${a.date.split("/")[0]
            }`
          )
        );
      });

      setData(reorder);
      setIsVoid(response.data.data.length === 0);
      setLoading(false);
    } catch (_) {
      setLoading(false);
    }
  };

  const clear = async () => {
    setPage(0);
    setAction("");
    setStartDate("");
    setEndDate("");
    setStartDateInput("");
    setEndDateInput("");
    requestLogFilterClean();
  };

  const Filter = () => {
    setPage(0);
    requestLogFilter();
  };

  useEffect(() => {
    if (shoulRequest.current) {
      shoulRequest.current = false;
      requestLogFilter();
    }
  }, []);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SideBar />
      <DrawerRigth
        onListener={() => {
          setStartDate("");
          setEndDate("");
          setStartDateInput("");
          setEndDateInput("");
          setOpenDrawer(false);
        }}
        onPressSuccess={() => {
          setPage(0)
          setTimeout(() => {
            Filter();
            setOpenDrawer(false);
          }, 400);
        }}
        onPressCancel={() => {
          clear();
          setOpenDrawer(false);
        }}
        listener={openDrawer}
        children={
          <>
            <FormControl marginTop={8}>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Início
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  required
                  value={startDateInput}
                  onChange={(event) => {
                    setStartDateInput(event.target.value);
                    setStartDate(moment(event.target.value).format("DD/MM/YYYY"));
                  }}
                  fontSize='sm'
                  maxLength={200}
                  mb='24px'
                  size='lg'
                  type={"date"}
                  variant='auth'
                />
              </InputGroup>
            </FormControl>
            <FormControl>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Fim
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  required
                  value={endDateInput}
                  onChange={(event) => {
                    setEndDateInput(event.target.value);
                    setEndDate(moment(event.target.value).format("DD/MM/YYYY"));
                  }}
                  fontSize='sm'
                  maxLength={200}
                  mb='24px'
                  size='lg'
                  type={"date"}
                  variant='auth'
                />
              </InputGroup>
            </FormControl>
            <FormControl>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Ação
              </FormLabel>
              <Select
                mb='24px'
                size='lg'
                borderRadius={15}
                variant='outline'
                isRequired
                value={action}
                onChange={(event) => {
                  if (event.target.value) setAction(event.target.value);
                }}
                icon={<MdArrowDropDown />}>
                {[
                  { key: "Selecionar", text: "Selecionar", value: undefined },
                  { key: "Parabéns", text: "Parabéns", value: "Parabéns" },
                  { key: "Adicionou", text: "Adicionou", value: "Adicionou" },
                  { key: "Editou", text: "Editou", value: "Editou" },
                  { key: "Excluiu", text: "Excluiu", value: "Excluiu" },
                  { key: "Ativou", text: "Ativou", value: "Ativou" },
                  { key: "Desativou", text: "Desativou", value: "Desativou" },
                ].map((item) => (
                  <option value={item.value}>{item.text}</option>
                ))}
              </Select>
            </FormControl>
          </>
        }
      />
      <Flex padding={4} flexDirection="column">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <IconButton
            w='46px'
            isDisabled={loading}
            bg="gray.200"
            h='46px'
            onClick={() => setOpenDrawer(true)}
            icon={<AiFillFilter color="black" />}
          />
        </div>

        {isVoid && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 10,
            }}
          >
            <Text>Sem resultado</Text>
          </div>
        )}
        {loading ? (
          <Stack>
            <Skeleton marginTop="2" borderRadius={15} height='80px' />
            <Skeleton marginTop="2" borderRadius={15} height='80px' />
            <Skeleton marginTop="2" borderRadius={15} height='80px' />
            <Skeleton marginTop="2" borderRadius={15} height='80px' />
            <Skeleton marginTop="2" borderRadius={15} height='80px' />
          </Stack>
        ) : (
          <>
            {data.length > 0 && data.map((item) => {
              return (
                <Box key={item.date}>
                  <Tag marginTop={2} size='lg' colorScheme='red' borderRadius='full'>
                    <TagLabel>{moment(item.date).format("DD/MM/YYYY")}</TagLabel>
                  </Tag>
                  {item.records.map((log) => (
                    <Box key={log._id} marginTop={2}>
                      <Card
                        children={
                          <Flex>
                            <Flex marginRight={3} justifyContent="center">
                              <IconBox
                                w='56px'
                                bg="gray.200"
                                h='56px'
                                icon={
                                  <Icon
                                    w='32px'
                                    h='32px'
                                    as={AiOutlineUser}
                                  />
                                }
                              />
                            </Flex>
                            <Flex flexDirection="column">
                              <Text
                                color={textColor}
                                fontSize="md"
                                fontWeight='700'
                                lineHeight='100%'>
                                {log.accountName}
                              </Text>
                              <Text
                                color="gray.500"
                                fontSize="sm"
                                marginTop={1}
                                lineHeight='100%'
                              > {moment(log.dateTime).format("LLL")}
                              </Text>
                              <Tag marginTop={4} size='lg' colorScheme='blackAlpha' borderRadius={6}>
                                <Text
                                  color={textColor}
                                  lineHeight="6"
                                  padding={2}>
                                  {log.description}
                                </Text>
                              </Tag>
                            </Flex>
                          </Flex>
                        }
                      />
                    </Box>
                  ))}
                </Box>
              );
            })}
          </>
        )}
        {(!loading && !loadingPage) && (
          <Flex justifyContent="center" marginTop={4}>
            <Button
              onClick={() => {
                requestLogFilter()
              }}
              colorScheme="blue">
              Carregar mais...
            </Button>
          </Flex>
        )}
        {loadingPage && (
          <Flex justifyContent="center" marginTop={6}>
            <Spinner colorScheme="blue" size="md" />
          </Flex>
        )}
      </Flex>
    </Box>
  );
};
export default LogActionsPage;
