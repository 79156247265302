import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import apiAccount from "../../services/urls/account";
import apiSituationCategory from "../../services/urls/category_situation";
import api from "../../services/urls/user";
import SideBar from "components/@sidebar";
import {
  Select as SelectChakra,
} from "chakra-react-select";
import {
  Box,
  Flex,
  Image,
  useColorModeValue,
  Text,
  Textarea,
  FormLabel,
  FormControl,
  Select,
  Button,
  useToast,
  IconButton,
  FormErrorMessage,
  CircularProgress
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";

const CreateDemand = () => {
  const toast = useToast();
  const history = useNavigate();
  const userData = useLocation().state;
  const { _id, name } = useSelector(
    (state) =>
      state.user
  );
  let shoulRequest = useRef(true);
  const textColor = useColorModeValue("navy.700", "white");
  const [description, setDescription] = useState("");
  const [demand, setDemand] = useState("");
  const [, setDemandLabel] = useState("");
  const [loading, setLoading] = useState(false);
  const [accountResponsible, setAccountResponsible] = useState("");
  const [descriptionDemand, setDescriptionDemand] = useState("");
  const [categories, setCategoreis] = useState([]);
  const [categorieId, setCategorieId] = useState([]);
  const [situation, setSituation] = useState("");
  const [demandIsRequired, setDemandIsRequired] = useState(false);
  const [statusIsRequired, setStatusIsRequired] = useState(false);
  const [situationIsRequired, setSituationIsRequired] = useState(false);
  const [demandStatusSituation, setDemandStatusSituation] = useState({});
  const [situations, setSituations] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [status, setStatus] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const requestCategories = useCallback(async () => {
    try {
      const response = await apiSituationCategory.GetCategoriesSimple();
      setCategoreis(
        response.data.data.map((item) => {
          return {
            label: item.name,
            value: item._id,
          };
        })
      );
    } catch (error) {
      toast({
        title: '',
        description: "Erro ao listar as situações.",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  }, [toast])

  const requestStatus = useCallback(async () => {
    try {
      const response = await api.getSituationsActive();
      setStatus(
        response.data.data.map((item) => {
          return {
            key: item._id,
            text: `${item.name}`,
            value: item._id,
            situations: item.situations,
          };
        }).filter((item) => item.text !== "Resolvida" && item.text === "Recebida")
      );

      const received = response.data.data.find((item) => item.name === "Recebida");

      if (received) {
        setDemand(received._id || "");
        setSituations(
          received.situations.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map((item) => {
            return {
              key: item._id,
              text: item.name,
              value: item._id,
            };
          })
        );
      }
    } catch (error) {
      toast({
        title: '',
        description: "Erro ao listar as situações.",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
    finally {
      setIsLoading(false);
    }
  }, [toast]);

  const requestAccounts = useCallback(async () => {
    try {
      const response = await apiAccount.GetAllAccountsActive();
      setAccounts(
        response.data.data.map((item) => {
          return {
            key: item._id,
            text: `${item.name}`,
            value: item._id,
          };
        }));
    } catch (error) {
      toast({
        title: '',
        description: "Erro ao listar as usuários",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  }, [toast])

  const submit = async () => {
    try {
      if (!demand || !situation || !description) {
        if (!demand) setStatusIsRequired(true);
        if (!situation) setSituationIsRequired(true);
        if (!description) setDemandIsRequired(true);
        toast({
          title: '',
          description: "Demanda, status da demanda, situação da demanda são obrigatórios",
          status: 'warning',
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }

      setLoading(true);
      const response = await api.createDemand({
        account: _id,
        user: userData?._id,
        active: true,
        date: moment().format(),
        accountId: _id,
        accountName: name,
        day: moment().format("DD/MM/YYYY"),
        hour: moment().format("HH:mm:ss"),
        userAction: "Adicionou",
        userId: userData?._id,
        userName: userData.name,
        dateTime: moment().format(),
        demandDescription: description,
        demandStatus: demand,
        theFirstDemandHistoricString: descriptionDemand || undefined,
        responsible:
          accountResponsible === "Selecione" || accountResponsible === ""
            ? undefined
            : accountResponsible,
        categories: categorieId.map((item) => item.value),
        systemUser: _id,
        demandStatusSituation,
        registeredPerson: userData?._id,
      });

      toast({
        title: '',
        description: "Demanda criada com sucesso",
        status: 'success',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });

      history(
        "/detalhe-da-demanda",
        {
          state: {
            userId: userData?._id,
            userDemandId: response.data.data
          }
        },
      );

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: '',
        description: "Algo deu errado, tente novamente",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (shoulRequest.current) {
      shoulRequest.current = false;
      Promise.all([
        requestAccounts(),
        requestStatus(),
        requestCategories(),
      ])
    }
  }, [requestAccounts, requestCategories, requestStatus]);

  useEffect(() => {
    if (userData?.demandLabel) {
      setDemandLabel(userData?.demandLabel);
    }
    if (userData?.demandId) {
      setDemand(userData?.demandId);
    }
    if (userData?.demand) {
      setDescription(userData.demand?.description);
    }
  }, [userData.demandLabel, userData.demandId, userData.demand]);

  useEffect(() => {
    if (description) setDemandIsRequired(false);
    if (situation) setSituationIsRequired(false);
    if (demand) setStatusIsRequired(false);
  }, [description, situation, demand]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SideBar />
      <Flex
        boxShadow="2xl"
        margin={4}
        direction="column"
        backgroundColor="white"
        borderRadius={8}
      >
        <Flex>
          <IconButton
            marginLeft={10}
            marginTop={4}
            onClick={() => history(-1)}
            icon={<ArrowBackIcon color="blue.500" />}
          />
        </Flex>
        <Flex margin={10} direction="column">
          <Flex>
            <Flex direction={{ base: 'column', md: 'row' }}>
              <Image
                flex={0.1}
                boxSize={{ base: '100%', md: '220px' }}
                borderTopLeftRadius={6}
                borderTopRightRadius={6}
                marginRight={4}
                objectFit="cover"
                borderRadius={6}
                src={
                  userData.photo ||
                  "https://react.semantic-ui.com/images/wireframe/image.png"
                } />
              <Flex>
                <Flex direction="column" marginTop={{ base: 4, md: 0 }}>
                  <Flex>
                    <Text
                      fontSize='md'
                      fontWeight='500'
                      color={textColor}>
                      Nome:
                    </Text>
                    <Text fontSize='md' marginLeft={2}>{userData.name}</Text>
                  </Flex>
                  <Flex>
                    <Text
                      fontSize='md'
                      fontWeight='500'
                      color={textColor}>
                      WhatsApp:
                    </Text>
                    <Text fontSize='md' marginLeft={2}>{userData.whatsApp || " Não informado"}</Text>
                  </Flex>
                  <Flex>
                    <Text
                      fontSize='md'
                      fontWeight='500'
                      color={textColor}>
                      Cidade:
                    </Text>
                    <Text fontSize='md' marginLeft={2}>{userData.city || " Não informado"}</Text>
                  </Flex>
                  <Flex>
                    <Text
                      fontSize='md'
                      fontWeight='500'
                      color={textColor}>
                      Bairro:
                    </Text>
                    <Text fontSize='md' marginLeft={2}>{userData.bairro || " Não informado"}</Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <br />
          {isLoading ? (
            <Flex height="72" alignItems="center" justifyContent="center">
              <CircularProgress />
            </Flex>
          ) : (
            <Flex direction="column">
              <div style={{ width: "100%" }}>
                <FormControl isRequired>
                  <FormLabel
                    display='flex'
                    ms='4px'
                    fontSize='sm'
                    fontWeight='500'
                    color={textColor}
                    mb='8px'>
                    Demanda
                  </FormLabel>
                  <Textarea
                    required
                    rows={2}
                    value={description}
                    maxLength="210"
                    onChange={(event) =>
                      setDescription(event.target.value)
                    }
                    placeholder="..."
                  />
                  {!demandIsRequired && (
                    <FormErrorMessage>Descrição da demanda é obrigatório.</FormErrorMessage>
                  )}
                </FormControl>
                <Flex
                  style={{
                    marginTop: 8,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <Text
                    fontWeight="500"
                    color={textColor}
                    fontSize='md'
                  >
                    Max: {210 - description.length < 0 ? 0 : 210 - description.length}
                  </Text>
                </Flex>
              </div>
              <FormControl isRequired>
                <FormLabel
                  display='flex'
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  mb='8px'>
                  Status da demanda
                </FormLabel>
                <Select
                  required
                  value={demand}
                  onChange={(event) => {
                    if (event.target.value === "Selecione") {
                      setSituations([]);
                      setDemandStatusSituation({})
                      setDemand("");
                      return
                    };
                    setDemandLabel(
                      status?.filter((it) => it.key === event.target.value)[0]?.text
                    );

                    setDemand(event.target.value);
                    setSituation("");
                    setDemandStatusSituation({})
                    setSituations([]);
                    setSituations(
                      status
                        ?.filter((it) => it.value === event.target.value)[0]
                        ?.situations.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map((item) => {
                          return {
                            key: item._id,
                            text: item.name,
                            value: item._id,
                          };
                        })
                    );
                  }}
                >
                  <option value={"Selecione"}>Selecione</option>
                  {status.map((item) => (
                    <option value={item.value}>{item.text}</option>
                  ))}
                </Select>
                {statusIsRequired && (
                  <FormErrorMessage>Status da demanda é obrigatório.</FormErrorMessage>
                )}
              </FormControl>
              <br />
              <FormControl isRequired>
                <FormLabel
                  display='flex'
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  mb='8px'>
                  Situação da demanda
                </FormLabel>
                <Select
                  required
                  onChange={(event) => {
                    setSituation(event.target.value);
                    const demandStatusSituation = situations.find((item) => item.value === event.target.value)
                    setDemandStatusSituation({
                      _id: demandStatusSituation.value,
                      name: demandStatusSituation.text,
                    });
                  }}
                  value={situation}
                >
                  <option value={""}>Selecione</option>
                  {situations.map((item) => (
                    <option value={item.value}>{item.text}</option>
                  ))}
                </Select>
                {situationIsRequired && (
                  <FormErrorMessage>Situação da demanda é obrigatório.</FormErrorMessage>
                )}
              </FormControl>
              <br />
              <FormControl>
                <FormLabel
                  display='flex'
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  mb='8px'>
                  Primeiro histórico da demanda (caso houver)
                </FormLabel>
                <Textarea
                  value={descriptionDemand}
                  onChange={(event) =>
                    setDescriptionDemand(event.target.value)
                  }
                  placeholder="..."
                />
              </FormControl>
              <br />
              <FormControl>
                <FormLabel
                  display='flex'
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  mb='8px'>
                  Responsável
                </FormLabel>
                <Select
                  value={accountResponsible}
                  onChange={(event) => {
                    setAccountResponsible(event.target.value);
                  }}
                >
                  <option value={undefined}>Selecione</option>
                  {accounts.map((item) => (
                    <option value={item.value}>{item.text}</option>
                  ))}
                </Select>
              </FormControl>
              <br />
              <FormControl>
                <FormLabel
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  display='flex'>
                  Categoria(s)
                </FormLabel>
                <SelectChakra
                  isMulti
                  placeholder=""
                  onChange={(event) => {
                    setCategorieId(event);

                  }}
                  colorScheme="green"
                  value={categorieId}
                  options={categories}
                />
              </FormControl>
              <Flex marginTop={6} justifyContent="flex-end">
                <Button
                  isLoading={loading}
                  colorScheme="blue"
                  onClick={() => submit()}
                >
                  {userData.isEdit ? "Atualizar" : "Salvar"}
                </Button>
              </Flex>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};
export default CreateDemand;
