import {
  Flex,
  Stat,
  StatNumber,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";

export default function Cardcomponent(props) {
  const {
    startContent,
    endContent,
    name,
    bgColor,
  } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");

  return (
    <Card py='15px' bgColor={bgColor}>
      <Flex
        h='100%'
        direction={{ base: 'column', md: 'row' }}
        align={{ base: "center", xl: "start" }}
        justifyContent={{ base: "start", xl: "center" }}>
        {startContent}
        <Stat  align={{ base: "center", xl: "start" }} ms={startContent ? "18px" : "0px"}>
          <StatNumber
            color={textColor}
            fontSize={{
              base: "md",
            }}>
            {name}
          </StatNumber>
        </Stat>
        <Flex justifyContent={{ base: "end", xl: "center" }}>
          {endContent}
        </Flex>
      </Flex>
    </Card>
  );
}
