
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import BirthPage from "pages/birth_page";
import SituationCategoryPage from "pages/demand_category_page";
import PeopleOfCategoryPage from "pages/category_of_people_page";
import CategoryPageSituations from "pages/demands_category_page";
import CategoriesOfPeoplePage from "pages/categories_of_people_page";
import UsersSytemPage from "pages/users_system_page";
import LogActionsPage from "pages/log_actions_page";
import UpdatePasswordPage from "pages/update_password_page";
import DemandsPage from "pages/demands_page";
import ShowDemandDetail from "pages/show_demand_page";
import ShowPeoplePage from "pages/show_people_page";
import UsersPage from "pages/peoples_page";
import CreateDemand from "pages/create_demand_page";
import RegistrationPeople from "pages/registration_of_people";
import Auth from "pages/auth_page";
import { useDispatch, useSelector } from "react-redux";
import Map from "pages/maps_of_peoples";
import PeopleUserPage from "pages/people_user_page";
import NotFound from "pages/notFound_page";
import CreateLegislative from "pages/create_legislative_page";
import LegislationPage from "pages/legislations_page";
import ShowLegislationPage from "pages/show_legislation_page";
import QuickRegistrationPage from "pages/quick_registration_page";
import NeighborhoodsPage from "pages/neighborhoods_page";
import { useContext, useEffect} from "react";
import { socket } from "socke";
import { AuthContext } from "context";
import { setCleanUser } from "store/ducks/auth/actions";


const ProtectedRoute = ({ children }) => {
  const { signed, _id } = useSelector((state) => state.user);
  const { setISAuth } = useContext(AuthContext);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      socket.off('connection');
    };
  }, []);

  useEffect(() => {
    if (signed && _id) {
      socket.emit('accountOn', { userId: _id });
    }
  }, [_id, signed])

  useEffect(() => {
    socket.on('exitApp', (data) => {
      const { userId } = data

      if (userId === _id) {
        dispatch(setCleanUser());
        localStorage.removeItem("@token");
        setISAuth(false);
      }
    });

    return () => {
      socket.off('exitApp');
    };
  }, [_id]);
  

  if (!signed) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Auth />} />
        <Route path="/dashboard" element={<ProtectedRoute><BirthPage /></ProtectedRoute>} />
        <Route path="/cadastrodepessoa" element={<ProtectedRoute><RegistrationPeople /></ProtectedRoute>} />
        <Route path="/cadastro-de-categoria-de-demandas" element={<ProtectedRoute><SituationCategoryPage /></ProtectedRoute>} />
        <Route path="/cadastro-de-categoria-de-pessoa" element={<ProtectedRoute><PeopleOfCategoryPage /></ProtectedRoute>} />
        <Route path="/listagem-de-categorias-de-demanda" element={<ProtectedRoute><CategoryPageSituations /></ProtectedRoute>} />
        <Route path="/categorias-de-pessoas" element={<ProtectedRoute><CategoriesOfPeoplePage /></ProtectedRoute>} />
        <Route path="/usuarios" element={<ProtectedRoute><UsersSytemPage /></ProtectedRoute>} />
        <Route path="/log" element={<ProtectedRoute><LogActionsPage /></ProtectedRoute>} />
        <Route path="/atualizar-senha" element={<ProtectedRoute><UpdatePasswordPage /></ProtectedRoute>} />
        <Route path="/demandas" element={<ProtectedRoute><DemandsPage /></ProtectedRoute>} />
        <Route path="/detalhe-da-demanda" element={<ProtectedRoute><ShowDemandDetail /></ProtectedRoute>} />
        <Route path="/detalhe-da-demanda/:userDemandId" element={<ProtectedRoute><ShowDemandDetail /></ProtectedRoute>} />
        <Route path="/visualizar-cadastrado" element={<ProtectedRoute><ShowPeoplePage /></ProtectedRoute>} />
        <Route path="/visualizar-cadastrado/:id" element={<ProtectedRoute><ShowPeoplePage /></ProtectedRoute>} />
        <Route path="/cadastrados" element={<ProtectedRoute><UsersPage /></ProtectedRoute>} />
        <Route path="/criar-nova-demanda" element={<ProtectedRoute><CreateDemand /></ProtectedRoute>} />
        <Route path="/mapa" element={<ProtectedRoute><Map /></ProtectedRoute>} />
        <Route path="/editar-dados-do-cadastro" element={<ProtectedRoute><PeopleUserPage /></ProtectedRoute>} />
        <Route path="/cadastro-lei-de-projeto" element={<ProtectedRoute><CreateLegislative /></ProtectedRoute>} />
        <Route path="/projetos-de-lei" element={<ProtectedRoute><LegislationPage /></ProtectedRoute>} />
        <Route path="/vizualizar-projeto-de-lei" element={<ProtectedRoute><ShowLegislationPage /></ProtectedRoute>} />
        <Route path="/cadastro-rapido" element={<ProtectedRoute><QuickRegistrationPage /></ProtectedRoute>} />
        <Route path="/bairros" element={<ProtectedRoute><NeighborhoodsPage /></ProtectedRoute>} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;