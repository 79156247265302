/* eslint-disable react-hooks/rules-of-hooks */
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox } from 'semantic-ui-react'
import DrawerRigth from "components/drawer_rigth";
import { AiFillFilter, AiFillFilePdf, AiFillFileExcel } from "react-icons/ai";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import {
  Select as SelectChakra,
} from "chakra-react-select";
import _ from 'lodash';


import axiosCategorie from "../../services/urls/category";
import apiUser from "../../services/urls/user";
import { mask } from "../../utils/mask";
import SideBar from "components/@sidebar";
import {
  Box,
  Flex,
  IconButton,
  Input,
  Text,
  useColorModeValue,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
  useToast,
  InputGroup,
  FormLabel,
  FormControl
} from "@chakra-ui/react";

import { UltimatePagination } from "components/pagination";
import ListOfPeople from "./components/listOfPeople";
import apiAccount from "../../services/urls/account";


const UsersPage = () => {
  const toast = useToast();
  let shoulRequest = useRef(true);
  const dispatch = useDispatch();
  const {
    info2,
    search,
    page,
    totalPages,
    indication,
    isDeath,
    withCategory,
    withoutCategory,
    registeredBy,
    editedBy,
    contactNumber,
    document,
    locationType,
    gender,
    documentType,
    contactNumberType,
    neighborhood,
    needsAddresUpdate,
    needsUpdateAddresByStreet,
    needsUpdateAddresByHouseNumber,
    allCategory,
    doesNotHaveAllCategories,
    ageRange,
  } =
    useSelector((state) => state.usersReducer);

  const doc = new jsPDF("l", "pt");

  const [data, setData] = useState([]);
  const { _id, name } = useSelector(
    (state) =>
      state.user
  );
  const [openDrawer, setOpenDrawer] = useState(false);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [loading, setLoading] = useState(false);
  const [perPage] = useState(10);
  const [idDelete, setIdDelete] = useState("");
  const [userName, setUserName] = useState("");
  const [categories, setCategories] = useState([]);

  const [accounDocs, setAccountDoc] = useState(0);
  const [totalDocs, setTotaDocs] = useState(0);
  const [isVoid, setIsVoid] = useState(false);
  const [isfilter, setIsfilter] = useState(false);
  const { isOpen: isOpenDel, onOpen: onOpenDel, onClose: onCloseDel } = useDisclosure();
  const cancelRef = useRef();
  const [accounts, setAccounts] = useState([])
  const [neighborhoods, setNeighborhoods] = useState([])

  const requestUsersFilter = useCallback(async (_page) => {
    try {
      setIsVoid(false);
      setLoading(true);

      const response = await apiUser.GetUsersFilter(
        _page,
        perPage,
        search || info2 || undefined,
        indication || undefined,
        isDeath,
        withCategory.map((item) => item.value),
        withoutCategory.map((item) => item.value),
        registeredBy?.value,
        editedBy?.value,
        contactNumber || undefined,
        document || undefined,
        locationType?.value,
        gender?.value,
        documentType?.value,
        contactNumberType?.value,
        neighborhood?.value,
        needsAddresUpdate,
        needsUpdateAddresByStreet,
        needsUpdateAddresByHouseNumber,
        allCategory,
        doesNotHaveAllCategories,
        ageRange,
      );

      if (
        search ||
        info2 ||
        withCategory?.length > 0 ||
        indication ||
        isDeath ||
        withoutCategory?.length > 0 ||
        registeredBy ||
        editedBy ||
        contactNumber ||
        document ||
        locationType ||
        gender ||
        documentType ||
        contactNumberType ||
        neighborhood ||
        needsAddresUpdate ||
        needsUpdateAddresByStreet ||
        needsUpdateAddresByHouseNumber ||
        allCategory ||
        doesNotHaveAllCategories ||
        ageRange.startDate ||
        ageRange.endDate
      ) {
        setIsfilter(true);
      } else {
        setIsfilter(false);
      }

      setTotaDocs(response?.data?.data?.totalDocs);
      setData(response.data.data.docs);
      setIsVoid(response.data.data.docs.length === 0);
      dispatch({
        type: "@SET_TOTAL_PAGES",
        payload: {
          totalPages: response?.data?.data?.totalPages,
        },
      });

      setLoading(false);
    } catch (_) {
      setLoading(false);
    }
  }, [
    contactNumber,
    contactNumberType,
    dispatch,
    document,
    documentType,
    editedBy,
    gender,
    indication,
    info2,
    isDeath,
    locationType,
    needsAddresUpdate,
    neighborhood,
    perPage,
    registeredBy,
    search,
    withCategory,
    withoutCategory,
    needsUpdateAddresByStreet,
    needsUpdateAddresByHouseNumber,
    allCategory,
    doesNotHaveAllCategories,
    ageRange,
  ])

  const requestUsers = async () => {
    try {
      setIsVoid(false);
      setLoading(true);
      const response = await apiUser.GetUsersFilter();

      setTotaDocs(response?.data?.data?.totalDocs);
      setData(response.data.data.docs);
      setIsVoid(response.data.data.docs.length === 0);
      dispatch({
        type: "@SET_TOTAL_PAGES",
        payload: {
          totalPages: response?.data?.data?.totalPages,
        },
      });
      setLoading(false);
    } catch (_) {
      setLoading(false);
    }
  };

  const requestUsersSearch = async () => {
    try {
      setIsfilter(true);
      setIsVoid(false);
      setLoading(true);
      const response = await apiUser.GetUsersFilter(
        undefined,
        undefined,
        info2,
        undefined
      );

      setTotaDocs(response?.data?.data?.totalDocs);
      setData(response.data.data.docs);
      setIsVoid(response.data.data.docs.length === 0);
      dispatch({
        type: "@SET_TOTAL_PAGES",
        payload: {
          totalPages: response?.data?.data?.totalPages,
        },
      });
      setLoading(false);
    } catch (_) {
      setLoading(false);
    }
  };

  const deleRegisteredPerson = async () => {
    try {
      await apiUser.deleteUser(idDelete, {
        accountId: _id,
        accountName: name,
        day: moment().format("DD/MM/YYYY"),
        hour: moment().format("HH:mm:ss"),
        userAction: "Excluiu",
        userId: idDelete,
        userName,
        dateTime: moment().format(),
        description: `Excluiu o cadastrado "${userName}"`,
      });

      let userAccount = accounDocs - 1;
      let userTotalDoc = totalDocs - 1;

      setTotaDocs(userTotalDoc);
      setAccountDoc(userAccount);
      setData(data.filter((item) => item._id !== idDelete));

      toast({
        title: '',
        description: "Usuário deletado com sucesso.",
        status: 'success',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
      setIdDelete("");
      setUserName("");
      onCloseDel();
    } catch (error) {
      toast({
        title: '',
        description: "Erro ao remover usuário, tente novamente.",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const renderAge = (date) => {
    if (!date) return "Não informado.";
    const age = moment().diff(date, "years");
    return `${age.toString()}`;
  };

  const getCurrentDate = () => {
    const date = moment().format('LLLL')
    return date.charAt(0).toUpperCase() + date.slice(1);
  }

  const generatePDF = (data) => {
    try {
      const marginLeft = 40;
      const titleMarginTop = 40;
      const descriptionMarginTop = 60;

      doc.setFontSize(11);
      doc.text(`Total de cadastrados: ${data.length}`, marginLeft, titleMarginTop + 10);

      doc.setFontSize(11);
      doc.text(getCurrentDate(), marginLeft, descriptionMarginTop + 10);
      autoTable(doc, { html: "#my-table", });
      autoTable(doc, {
        startY: 120,
        styles: { overflow: "linebreak", minCellWidth: 100 },
        columnStyles: {
          0: { cellWidth: 80 },
          1: { cellWidth: 40 },
          2: { cellWidth: 140 },
          3: { cellWidth: 190 },
          4: { cellWidth: 340 },
        },
        columns: [
          { header: "Nome", dataKey: "name" },
          { header: "Idade", dataKey: "idade" },
          { header: "Contatos", dataKey: "contacts" },
          { header: "Categoria", dataKey: "categoria" },
          { header: "Observação", dataKey: "observacao" },
        ],
        body: data,
      });

      doc.save("lista.pdf");
    } catch (_) {
      toast({
        title: '',
        description: "Erro ao gerar PDF.",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const requestDataToFiles = useCallback(async () => {
    const response = await apiUser.getPdf(
      info2 || search,
      indication,
      _id,
      name,
      "Gerou PDF",
      moment().format("DD/MM/YYYY"),
      moment().format("HH:mm:ss"),
      moment().format(),
      isDeath,
      withCategory.map((item) => item.value),
      withoutCategory.map((item) => item.value),
      registeredBy?.value,
      editedBy?.value,
      contactNumber || undefined,
      document || undefined,
      locationType?.value,
      gender?.value,
      documentType?.value,
      contactNumberType?.value,
      neighborhood?.value,
      needsAddresUpdate || null,
      needsUpdateAddresByStreet,
      needsUpdateAddresByHouseNumber,
      allCategory,
      doesNotHaveAllCategories,
      ageRange,
    );
    return response.data.data;
  }, [info2, search, indication, _id, name, isDeath, withCategory, withoutCategory, registeredBy?.value, editedBy?.value, contactNumber, document, locationType?.value, gender?.value, documentType?.value, contactNumberType?.value, neighborhood?.value, needsAddresUpdate, needsUpdateAddresByStreet, needsUpdateAddresByHouseNumber, allCategory, doesNotHaveAllCategories, ageRange])

  const requestPDF = async () => {
    try {
      toast({
        title: '',
        description: "Seu arquivo está sendo processado, aguarde o download.",
        status: 'success',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
      const response = await requestDataToFiles()

      const newData = response.map(
        (item) => {
          return {
            name: `${item?.name} ${item?.apelido ? `${`(${item?.apelido})`}` : ""
              }`,
            idade: item?.dateOfBirth ?renderAge(item?.dateOfBirth) : "--",
            contacts:`${item?.whatsApp?.length > 2
              ? `WhatsApp: ${mask(item?.whatsApp).replace(" - 🇵🇾", "").replace(" - 🇧🇷", "")}\n`
              : ""
              }${item?.phone?.length > 2
                ? `Telefone: ${item?.phone}\n`
                : ""
              }${item?.whatsAppContact?.length > 2
                ? `WhatsApp para recado: ${mask(item?.whatsAppContact)
                  .replace(" - 🇵🇾", "")
                  .replace(" - 🇧🇷", "")}\n`
                : ""
              }${item?.phoneContact?.length > 2
                ? `Telefone para recado: ${item?.phoneContact}`
                : ""
              }
              `,
            bairro: item?.neighborhoodId?.name || "Não informado.",
            categoria:
              item?.category?.length === 0
                ? "Não informado."
                : item?.category?.map((it) => `• ${it.name}.`).join("\n"),
            observacao: item?.description || "Não informado.",
          };
        }
      );
      generatePDF(newData);
    } catch (error) {
      setLoading(false);
      toast({
        title: '',
        description: "Erro ao gerar PDF, tente novamente.",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const requestPDF2 = async () => {
    try {
      toast({
        title: '',
        description: "Seu arquivo está sendo processado, aguarde o download.",
        status: 'success',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });

      const response = await requestDataToFiles()

      const newData = response.map(
        (item) => {
          return {
            name: `${item?.name} ${item?.apelido ? `${`(${item.apelido})`}` : ""
              }`,
            category:
              item?.category?.length > 0
                ? item?.category?.map((it) => `• ${it.name}.`).join("\n")
                : "Não informado.",
          };
        }
      );
      const doc2 = new jsPDF("portrait", "pt");
      const marginLeft = 40;
      const titleMarginTop = 40;
      const descriptionMarginTop = 60;

      doc2.setFontSize(11);
      doc2.text(`Total de cadastrados: ${newData.length}`, marginLeft, titleMarginTop + 10);

      doc2.setFontSize(11);
      doc2.text(getCurrentDate(), marginLeft, descriptionMarginTop + 10);

      autoTable(doc2, { html: "#my-table", });
      autoTable(doc2, {
        startY: 120,
        styles: { overflow: "linebreak" },
        columnStyles: {
          0: { cellWidth: 200 },
          2: { cellWidth: 250 },
        },
        columns: [
          { header: "Nome", dataKey: "name" },
          { header: "Categorias", dataKey: "category" },
        ],
        body: newData,
      });

      doc2.save("lista.pdf");
    } catch (error) {
      setLoading(false);
      toast({
        title: '',
        description: "Erro ao gerar PDF, tente novamente.",
        status: 'success',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const requestPDF3 = async () => {
    try {
      toast({
        title: '',
        description: "Seu arquivo está sendo processado, aguarde o download.",
        status: 'success',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });

      const response = await requestDataToFiles()

      const newData = response.map(
        (item) => {
          return {
            name: `${item?.name} ${item?.apelido ? `${`(${item.apelido})`}` : ""
              }`,
            contatos: `${item?.whatsApp?.length > 2
              ? `WhatsApp: ${mask(item?.whatsApp).replace(" - 🇵🇾", "").replace(" - 🇧🇷", "")}\n`
              : ""
              }${item?.phone?.length > 2
                ? `Telefone: ${item?.phone}\n`
                : ""
              }${item?.whatsAppContact?.length > 2
                ? `WhatsApp para recado: ${mask(item?.whatsAppContact)
                  .replace(" - 🇵🇾", "")
                  .replace(" - 🇧🇷", "")}\n`
                : ""
              }${item?.phoneContact?.length > 2
                ? `Telefone para recado: ${item?.phoneContact}`
                : ""
              }
              `,
          };
        }
      );
      const doc2 = new jsPDF("portrait", "pt");
      const marginLeft = 40;
      const titleMarginTop = 40;
      const descriptionMarginTop = 60;

      doc2.setFontSize(11);
      doc2.text(`Total de cadastrados: ${newData.length}`, marginLeft, titleMarginTop + 10);

      doc2.setFontSize(11);
      doc2.text(getCurrentDate(), marginLeft, descriptionMarginTop + 10);
      autoTable(doc2, { html: "#my-table" });
      autoTable(doc2, {
        startY: 120,
        styles: { overflow: "linebreak" },
        columnStyles: {
          0: { cellWidth: 200 },
          2: { cellWidth: 250 },
          // 3: { cellWidth: 100 },
        },
        columns: [
          { header: "Nome", dataKey: "name" },
          { header: "Contatos", dataKey: "contatos" },
          // { header: "Observação", dataKey: "observacao" },
        ],
        body: newData,
      });

      doc2.save("lista.pdf");
    } catch (error) {
      setLoading(false);
      toast({
        title: '',
        description: "Erro ao gerar PDF, tente novamente.",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const requestPDF4 = async () => {
    try {
      toast({
        title: '',
        description: "Seu arquivo está sendo processado, aguarde o download.",
        status: 'success',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });

      const response = await requestDataToFiles()

      const newData = response.map(
        (item) => {
          return {
            name: `${item?.name} ${item?.apelido ? `${`(${item.apelido})`}` : ""}`,
            neighborhoods: `${capitalizeItemList(item?.neighborhoodId?.name || "") || "Não informado."}`,
            place: `${item?.public_placeId?.name || "Não informado."}`,
            houseNumber: `${item?.houseNumber || "Não informado."}`,
            addressReference: item?.addressReference || "Não informado.",
            localization: item?.localization ? "Sim" : "Não",
          };
        }
      );
      const doc2 = new jsPDF("landscape", "pt");
      const marginLeft = 40;
      const titleMarginTop = 40;
      const descriptionMarginTop = 60;

      doc2.setFontSize(11);
      doc2.text(`Total de cadastrados: ${newData.length}`, marginLeft, titleMarginTop + 10);

      doc2.setFontSize(11);
      doc2.text(getCurrentDate(), marginLeft, descriptionMarginTop + 10);
      autoTable(doc2, { html: "#my-table" });
      autoTable(doc2, {
        startY: 120,
        styles: { overflow: "linebreak" },
        columnStyles: {
          0: { cellWidth: 100 },
          1: { cellWidth: 100 },
          2: { cellWidth: 170 },
          3: { cellWidth: 80 },
          4: { cellWidth: 210 },
          5: { cellWidth: 100 },
        },
        columns: [
          { header: "Nome", dataKey: "name" },
          { header: "Bairro", dataKey: "neighborhoods" },
          { header: "Rua", dataKey: "place" },
          { header: "Número", dataKey: "houseNumber" },
          { header: "Ponto de referência", dataKey: "addressReference" },
          { header: "Localização", dataKey: "localization" },
        ],
        body: newData,
      });

      doc2.save("lista.pdf");
    } catch (error) {
      setLoading(false);
      toast({
        title: '',
        description: "Erro ao gerar PDF, tente novamente.",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const requestCategories = useCallback(async () => {
    try {
      const response = await axiosCategorie.GetCategoriesActive();
      const not_category = [];
      const dataConc = not_category.concat(
        response?.data?.data.map((item) => {
          return {
            label: item.name,
            value: item._id,
          };
        })
      );
      setCategories(dataConc);
    } catch (error) {
      toast({
        title: '',
        description: "Erro ao buscar categorias.",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  }, [toast])

  const generateExcel = useCallback(async () => {
    toast({
      title: '',
      description: "Seu arquivo está sendo processado, aguarde o download.",
      status: 'success',
      duration: 5000,
      position: "top-right",
      isClosable: true,
    });

    const response = await requestDataToFiles()
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('My Sheet');

    worksheet.columns = [
      { header: 'Qtd', key: 'uni', width: 10 },
      { header: 'Nome', key: 'name', width: 30 },
      { header: 'WhatsApp', key: 'whatsApp', width: 20 },
      { header: 'Contato sem WhatsApp', key: 'phone', width: 20 },
      { header: 'WhatsApp para recado', key: 'whatsAppContact', width: 20 },
      { header: 'Contato para recado (sem whats)', key: 'phoneContact', width: 20 },
    ];

    for (let index = 0; index < response.length; index++) {
      worksheet.addRow({ uni: index, name: response[index]?.name, whatsApp: response[index]?.whatsApp, phone: response[index]?.phone, whatsAppContact: response[index]?.whatsAppContact, phoneContact: response[index]?.phoneContact });
    }

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, 'cadastrados.xlsx');
  }, [requestDataToFiles, toast])

  const requestAccounts = useCallback(async () => {
    try {
      const response = await apiAccount.GetAllAccounts();
      setAccounts(
        response.data.data.map((item) => {
          return {
            label: item.name,
            value: item._id,
          };
        })
      );
    } catch (error) {
      toast({
        title: '',
        description: "Erro ao listar accounts",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  }, [toast]);

  const requestAccount = useCallback(async () => {
    try {
      const response = await apiUser.getAccountUser();
      setAccountDoc(response.data.data);
    } catch (error) {
      toast({
        title: '',
        description: "Erro ao lista quantidade de usuários.",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  }, [toast])

  const loadNeighborhood = useCallback(async () => {
    try {
      const { data } = await apiUser.getNeighborhoods();
      const neighborhoods = data.data.map((item) => ({ label: item.name, value: item._id }))

      setNeighborhoods(neighborhoods.sort((a, b) => {
        const nameA = a.label.toLowerCase();
        const nameB = b.label.toLowerCase();

        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      }));

    } catch (error) {
      toast({
        title: '',
        description: "Erro ao lista bairros",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  }, [toast])


  const clear = () => {
    dispatch({
      type: "@SET_CLEAN_FILTER",
    });
    dispatch({
      type: "@SET_PAGE",
      payload: {
        page: 1,
      },
    });
    setIsfilter(false);
    setTotaDocs(0);
    requestUsers();
  };

  useEffect(() => {
    if (shoulRequest.current) {
      shoulRequest.current = false;
      requestCategories();
      requestAccount();
      requestUsersFilter(page);
      requestAccounts()
      loadNeighborhood();
    }
  }, [loadNeighborhood, page, requestAccount, requestAccounts, requestCategories, requestUsersFilter]);

  const debouncedRequest = useCallback(
    _.debounce((query) => requestUsersSearch(query), 600),
    [info2]
  );

  const capitalizeItemList = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  useEffect(() => {
    if (info2?.length > 3) {
      dispatch({
        type: "@SET_PAGE",
        payload: {
          page: 1,
        },
      });
      debouncedRequest()
      dispatch({
        type: "@SET_CATEGORIE",
        payload: {
          categorie: [],
        },
      });
      dispatch({
        type: "@SET_INIDICATION",
        payload: {
          indication: "",
        },
      });
      setIsfilter(true);
      dispatch({
        type: "@SET_SEARCH",
        payload: {
          search: "",
        },
      });
    }
    if (info2?.length === 0 && isfilter) {
      setIsfilter(false);
      requestUsersFilter(page);
    }
  }, [info2]);

  const Filter = () => {
    setIsfilter(true);
    dispatch({
      type: "@SET_INFO",
      payload: {
        info2: "",
      },
    });
    dispatch({
      type: "@SET_PAGE",
      payload: {
        page: 1,
      },
    });
    requestUsersFilter(1);
  };


  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SideBar />
      <DrawerRigth
        onListener={() => {
          setOpenDrawer(false);
        }}
        onPressSuccess={() => {
          setTimeout(() => {
            setIsfilter(true);
            Filter();
            setOpenDrawer(false);
          }, 400);
        }}
        closeDrawer={() => setOpenDrawer(false)}
        onPressCancel={() => {
          clear();
        }}
        listener={openDrawer}
        children={
          <>
            <FormControl marginTop={4}>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Pesquisar:
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  required
                  placeholder="Pesquisar na observação"
                  value={search}
                  onChange={(event) => {
                    dispatch({
                      type: "@SET_SEARCH",
                      payload: {
                        search: event.target.value,
                      },
                    });
                  }}
                  fontSize='sm'
                  maxLength={200}
                  mb='4px'
                  size='md'
                  type={"text"}
                  variant='auth'
                />
              </InputGroup>
            </FormControl>
            <FormControl>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Número do Documento:
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  value={document}
                  onChange={(event) => {
                    dispatch({
                      type: "@SET_DOCUMENT",
                      payload: {
                        document: event.target.value,
                      },
                    });
                  }}
                  fontSize='sm'
                  maxLength={200}
                  mb='4px'
                  size='md'
                  type={"text"}
                  variant='auth'
                />
              </InputGroup>
            </FormControl>
            <FormControl>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Número do Contato:
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  value={contactNumber}
                  onChange={(event) => {
                    dispatch({
                      type: "@SET_CONTACT_NUMBER",
                      payload: {
                        contactNumber: event.target.value,
                      },
                    });
                  }}
                  fontSize='sm'
                  maxLength={200}
                  mb='4px'
                  size='md'
                  type={"text"}
                  variant='auth'
                />
              </InputGroup>
            </FormControl>
            <FormControl>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Que tenha pelo menos uma das categorias:
              </FormLabel>
              <SelectChakra
                isMulti
                placeholder=""
                onChange={(event) => {
                  dispatch({
                    type: "@SET_WITH_CATEGORY",
                    payload: {
                      withCategory: event,
                    },
                  });

                }}
                colorScheme="green"
                value={withCategory}
                options={categories}
              />
            </FormControl>
            <FormControl>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                 Que NÃO tenha pelo menos uma das categorias:
              </FormLabel>
              <SelectChakra
                isMulti
                placeholder=""
                onChange={(event) => {
                  dispatch({
                    type: "@SET_WITHOUT_CATEGORY",
                    payload: {
                      withoutCategory: event,
                    },
                  });

                }}
                colorScheme="green"
                value={withoutCategory}
                options={categories}
              />
            </FormControl>
            <FormControl>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Cadastrado por:
              </FormLabel>
              <SelectChakra
                placeholder=""
                onChange={(event) => {
                  dispatch({
                    type: "@SET_REGISTERED_BY",
                    payload: {
                      registeredBy: event,
                    },
                  });

                }}
                colorScheme="green"
                value={registeredBy}
                options={accounts}
              />
            </FormControl>
            <FormControl>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Editado por:
              </FormLabel>
              <SelectChakra
                placeholder=""
                onChange={(event) => {
                  dispatch({
                    type: "@SET_EDITED_BY",
                    payload: {
                      editedBy: event,
                    },
                  });

                }}
                colorScheme="green"
                value={editedBy}
                options={accounts}
              />
            </FormControl>
            <FormControl>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Endereço completo sem link de localização:
              </FormLabel>
              <SelectChakra
                placeholder=""
                onChange={(event) => {
                  dispatch({
                    type: "@SET_LOCATION_TYPE",
                    payload: {
                      locationType: event,
                    },
                  });

                }}
                colorScheme="green"
                value={locationType}
                options={[
                  { label: "Informado", value: "informed" },
                  { label: "Não informado", value: "uninformed" }
                ]}
              />
            </FormControl>
            <FormControl>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Bairro:
              </FormLabel>
              <SelectChakra
                formatOptionLabel={(option) => capitalizeItemList(option.label)}
                placeholder=""
                onChange={(event) => {
                  dispatch({
                    type: "@SET_NEIGHBORHOOD",
                    payload: {
                      neighborhood: event
                    }
                  })
                }}
                colorScheme="green"
                value={neighborhood}
                options={neighborhoods}
              />
            </FormControl>
            <FormControl>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Gênero:
              </FormLabel>
              <SelectChakra
                placeholder=""
                onChange={(event) => {
                  dispatch({
                    type: "@SET_GENDER",
                    payload: {
                      gender: event,
                    },
                  });

                }}
                colorScheme="green"
                value={gender}
                options={[
                  { label: "Masculino", value: "Masculino" },
                  { label: "Feminino", value: "Feminino" },
                  { label: "Não informado", value: "uninformed" }
                ]}
              />
            </FormControl>
            <FormControl marginTop={4}>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Intervaldo de idade:
              </FormLabel>
              <InputGroup size='md'>
              <Input
                  required
                  placeholder="De"
                  value={ageRange.startDate}
                  onChange={(event) => {
                    dispatch({
                      type: "@SET_AGE_RANGE_START_DATE",
                      payload: {
                        startDate: event.target.value,
                      },
                    });
                  }}
                  fontSize='sm'
                  maxLength={200}
                  mb='4px'
                  size='md'
                  type={"number"}
                  variant='auth'
                />
                <Box mr="2" />
                <Input
                  required
                  placeholder="Até"
                  value={ageRange.endDate}
                  onChange={(event) => {
                    dispatch({
                      type: "@SET_AGE_RANGE_END_DATE",
                      payload: {
                        endDate: event.target.value,
                      },
                    });
                  }}
                  fontSize='sm'
                  maxLength={200}
                  mb='4px'
                  size='md'
                  type={"number"}
                  variant='auth'
                />
              </InputGroup>
            </FormControl>
            <FormControl>
              <Flex mt={3}>
                <Checkbox
                  checked={isDeath}
                  onChange={() => {
                    dispatch({
                      type: "@SET_IS_DEATH",
                      payload: {
                        isDeath: !isDeath,
                      },
                    });
                  }}
                >
                </Checkbox>
                <FormLabel
                  ms='4px'
                  marginLeft={3}
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  display='flex'>
                  Falecido
                </FormLabel>
              </Flex>
            </FormControl>
            <FormControl>
              <Flex mt={2}>
                <Checkbox
                  checked={!!allCategory}
                  onChange={() => {
                    dispatch({
                      type: "@SET_WITH_ALL_CATEGORY",
                      payload: {
                        allCategory: allCategory === "" ? 'allCategory' : "",
                      },
                    });
                  }}
                >
                </Checkbox>
                <FormLabel
                  ms='4px'
                  marginLeft={3}
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  display='flex'>
                  Que tenha todas as categorias
                </FormLabel>
              </Flex>
            </FormControl>
            <FormControl>
              <Flex mt={2}>
                <Checkbox
                  checked={!!doesNotHaveAllCategories}
                  onChange={() => {
                    dispatch({
                      type: "@SET_NOT_HAS_CATEGORY",
                      payload: {
                        doesNotHaveAllCategories: doesNotHaveAllCategories === "" ? 'doesNotHaveAllCategories' : "",
                      },
                    });
                  }}
                >
                </Checkbox>
                <FormLabel
                  ms='4px'
                  marginLeft={3}
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  display='flex'>
                  Que não tenha nenhuma das categorias
                </FormLabel>
              </Flex>
            </FormControl>
            <FormControl>
              <Flex mt={2}>
                <Checkbox
                  checked={!!needsAddresUpdate}
                  onChange={() => {
                    dispatch({
                      type: "@SET_NEEDS_ADDRESUPDATE",
                      payload: {
                        needsAddresUpdate: needsAddresUpdate === "" ? "needsAddresUpdate" : "",
                      },
                    });
                  }}
                >
                </Checkbox>
                <FormLabel
                  ms='4px'
                  marginLeft={3}
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  display='flex'>
                  Tem bairro antigo e não tem bairro novo
                </FormLabel>
              </Flex>
            </FormControl>
            <FormControl>
              <Flex mt={2}>
                <Checkbox
                  checked={!!needsUpdateAddresByStreet}
                  onChange={() => {
                    dispatch({
                      type: "@SET_UPDATE_BY_STREET",
                      payload: {
                        needsUpdateAddresByStreet: needsUpdateAddresByStreet === "" ? 'needsUpdateAddresByStreet' : "",
                      },
                    });
                  }}
                >
                </Checkbox>
                <FormLabel
                  ms='4px'
                  marginLeft={3}
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  display='flex'>
                  Tem rua antiga e não tem rua nova
                </FormLabel>
              </Flex>
            </FormControl>
            <FormControl>
              <Flex mt={2}>
                <Checkbox
                  checked={!!needsUpdateAddresByHouseNumber}
                  onChange={() => {
                    dispatch({
                      type: "@SET_UPDATE_BY_HOUSE_NUMBER",
                      payload: {
                        needsUpdateAddresByHouseNumber: needsUpdateAddresByHouseNumber === "" ? 'needsUpdateAddresByHouseNumber' : "",
                      },
                    });
                  }}
                >
                </Checkbox>
                <FormLabel
                  ms='4px'
                  marginLeft={3}
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  display='flex'>
                  Falta APENAS número de casa
                </FormLabel>
              </Flex>
            </FormControl>
          </>
        }
      />
      <Flex
        direction="column"
        padding={4}
      >
        <Flex alignItems="center">
          <Flex w="full" justifyContent="center" marginTop={6} alignItems="center">
            <Input
              value={info2}
              bg="white"
              onChange={(event) => {
                dispatch({
                  type: "@SET_INFO",
                  payload: {
                    info2: event.target.value,
                  },
                });
              }}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='email'
              placeholder="Pesquisar"
              mb='24px'
              fontWeight='500'
              size='lg'
            />
          </Flex>
          <IconButton
            w='46px'
            isDisabled={loading}
            bg="gray.200"
            h='46px'
            marginRight={1}
            marginLeft={2}
            onClick={() => setOpenDrawer(true)}
            icon={<AiFillFilter color="#3311DB" />}
          />

          <IconButton
            w='46px'
            isDisabled={loading}
            bg="gray.200"
            h='46px'
            marginRight={1}
            onClick={() => requestPDF()}
            icon={<AiFillFilePdf color="#3311DB" />}
          />
          <IconButton
            w='46px'
            isDisabled={loading}
            bg="gray.200"
            h='46px'
            marginRight={1}
            onClick={() => requestPDF2()}
            icon={<AiFillFilePdf color="#3311DB" />}
          />

          <IconButton
            w='46px'
            isDisabled={loading}
            bg="gray.200"
            h='46px'
            marginRight={1}
            onClick={() => requestPDF3()}
            icon={<AiFillFilePdf color="#3311DB" />}
          />

          <IconButton
            w='46px'
            isDisabled={loading}
            bg="gray.200"
            h='46px'
            marginRight={1}
            onClick={() => requestPDF4()}
            icon={<AiFillFilePdf color="#3311DB" />}
          />

          <IconButton
            w='46px'
            isDisabled={loading}
            bg="gray.200"
            h='46px'
            marginRight={1}
            onClick={() => generateExcel()}
            icon={<AiFillFileExcel color="green" />}
          />
        </Flex>
        <Flex flexDirection="column">
          {!loading && (
            <Flex marginBottom={4} flexDirection="column">
              <Text
                color={textColor}
                fontSize='md'
                fontWeight='700'
              >
                Total de cadastro: {accounDocs}
              </Text>
              {isfilter && (
                <Text
                  color={textColor}
                  fontSize='md'
                  fontWeight='700'
                >
                  Total do filtro: {totalDocs.toString()}
                </Text>
              )}
            </Flex>
          )}
          {isVoid && (
            <Flex justifyContent="center">
              <Text
                color={textColor}
                fontSize='md'
              >
                Sem resultado para a pesquisa..
              </Text>
            </Flex>
          )}
          <ListOfPeople
            loading={loading}
            data={data}
            onOpenDel={onOpenDel}
            setIdDelete={setIdDelete}
            setUserName={setUserName}
          />
          {(!loading && !isVoid) && (
            <Flex justifyContent="center" marginTop="4" marginBottom="4">
              {totalPages > 0 && (
                <UltimatePagination
                  currentPage={page}
                  onChange={(p) => {
                    dispatch({
                      type: "@SET_PAGE",
                      payload: {
                        page: p,
                      },
                    });
                    requestUsersFilter(p);
                  }}
                  totalPages={totalPages}
                />
              )}
            </Flex>
          )}
        </Flex>
      </Flex>
      <AlertDialog
        isOpen={isOpenDel}
        leastDestructiveRef={cancelRef}
        onClose={() => {
          setUserName("");
          setIdDelete("");
          onCloseDel();
        }}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Deletar cadastrado
            </AlertDialogHeader>

            <AlertDialogBody>
              Tem certeza que dejesa deletar?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => {
                setUserName("");
                setIdDelete("");
                onCloseDel();
              }}>
                Cancelar
              </Button>
              <Button colorScheme='red' onClick={deleRegisteredPerson} ml={3}>
                Deletar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};
export default UsersPage;
