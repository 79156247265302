import moment from "moment";
import "moment/locale/pt-br";
import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { TbFiles } from 'react-icons/tb'
import { Icon, Form } from "semantic-ui-react";
import CardContainer from "components/card/Card";
import {
  EditIcon,
  AttachmentIcon,
  AddIcon,
  DeleteIcon,
  ArrowBackIcon,
} from "@chakra-ui/icons";
import {
  Tab,
  Image,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Button,
  IconButton,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
  Textarea,
  Select,
  Stack,
  useToast,
  Divider,
  Spinner,
  Box,
  Skeleton,
  Flex,
  useColorModeValue,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";

import apiAccount from "../../services/urls/account";
import CardList from "./view_files";
import SideBar from "components/@sidebar";
import { StatusColors } from "../../utils/situationColors";
import PingStatus from "./ping_progress_status";
import { getLegislativeDetail } from "services/urls/legislative";
import { updateProject } from "services/urls/legislative";
import { listHistoricFiles } from "services/urls/legislative_historic";
import { listHistoricInsideProject } from "services/urls/legislative_historic";
import { updateHistoric } from "services/urls/legislative_historic";
import { getLegislativeStatus } from "services/urls/legislative_status";
import { createHistoric } from "services/urls/legislative_historic";
import TextExpanded from "components/@textExpanded";

const ShowLegislationPage = () => {
  const legislative = useLocation().state;
  const [legislativeDetail, setLegislativeDetail] = useState(null);
  const [projectName, setProjectName] = useState("");
  const [overview, setOverview] = useState("");
  const [projectId, setprojectId] = useState("");
  const [
    loadingEditProjectLegislative,
    setEditProjectLegislative
  ] = useState(false);
  const [historicsProject, setHistoricsProjects] = useState([]);
  const [historicId, setHistoricId] = useState("");
  const [firstHistoric, setFirstHistoric] = useState("");
  const [statusId, setStatusId] = useState("");
  const [situations, setSituations] = useState([]);
  const [statusLabel, setStatusLabel] = useState("");
  const [protocolIn, setProtocolIn] = useState("");
  const [projectNumber, setProjectNumber] = useState("");
  const [inCommissionSince, setInCommissionSince] = useState("");
  const [sanctionedIn, setSanctionedIn] = useState("");
  const [filed_under, setFiled_under] = useState("");
  const [progressStatusLabel, setProgressStatusLabel] = useState("");
  const [lawNumber, setlawNumber] = useState("");
  const [publishedIn, setPublishedIn] = useState("");
  const [progressStatusId, setProgressStatusId] = useState("");
  const [responsible, setResponsible] = useState("");
  const [opposition_opinion_published, setOpposition_opinion_published] = useState("");
  const [project_approved_and_ent_to_the_executive, setProject_approved_and_ent_to_the_executive] = useState("");

  const [status, setStatus] = useState([]);
  const {
    isOpen: isOpenModalNewHistoric,
    onOpen: onOpenModalNewHistoric,
    onClose: onCloseModalNewHistoric,
  } = useDisclosure();

  const {
    isOpen: isOpenProjectLegislative,
    onOpen: onOpenProjectLegislative,
    onClose: onCloseModalProjectLegislative,
  } = useDisclosure();



  const toast = useToast();
  const shoulRequest = useRef(true);
  const history = useNavigate();
  const userData = useLocation().state;
  const { _id, name } = useSelector(
    (state) => state.user
  );

  const textColor = useColorModeValue("navy.700", "white");
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [descriptionUpdated, setDescriptionUpdated] = useState("");
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingNew, setLoadingNew] = useState(false);
  const [file, setFile] = useState([]);
  const [fileBase64, setFileBase64] = useState([]);

  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();

  const cancelRef = React.useRef();
  const [accounts, setAccounts] = useState([]);


  const submit = async () => {
    try {
      if (
        !statusId ||
        !progressStatusId ||
        !progressStatusLabel ||
        !firstHistoric ||
        responsible === "Selecione" ||
        !responsible) {
        toast({
          title: '',
          description: "Status, Histórico, Situação e Responsável são obrigatórios",
          status: 'warning',
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (statusLabel === "Protocolado") {
        if (!protocolIn) {
          toast({
            title: '',
            description: "Protocolo em, é obrigatório",
            status: 'warning',
            duration: 5000,
            position: "top-right",
            isClosable: true,
          });
          return;
        }
        if (progressStatusLabel === "Revisado (aguardando leitura em sessão)") {
          if (!projectNumber) {
            toast({
              title: '',
              description: "Número do projeto é obrigatório",
              status: 'warning',
              duration: 5000,
              position: "top-right",
              isClosable: true,
            });
            return;
          }
        }
      }
      if (statusLabel === "Em comissões") {
        if (!inCommissionSince && !opposition_opinion_published) {
          toast({
            title: '',
            description: "Na comissão desde, é obrigatórios",
            status: 'warning',
            duration: 5000,
            position: "top-right",
            isClosable: true,
          });
          return;
        }
      }
      if (statusLabel === "Arquivada") {
        if (progressStatusLabel === "Parecer contrário publicado") {
          if (!opposition_opinion_published || !filed_under) {
            toast({
              title: '',
              description: "Parecer contrário publicado em e Arquivado em, é obrigatório",
              status: 'warning',
              duration: 5000,
              position: "top-right",
              isClosable: true,
            });
            return;
          }
        }
        else {
          if (!filed_under) {
            toast({
              title: '',
              description: "Parecer contrário publicado em e Arquivado em, é obrigatório",
              status: 'warning',
              duration: 5000,
              position: "top-right",
              isClosable: true,
            });
            return;
          }
        }
      }
      if (statusLabel === "Lei aprovada") {
        if (!sanctionedIn || !publishedIn || !lawNumber) {
          toast({
            title: '',
            description: "Lei aprovada em, Lei nº: e Lei publicada em, é obrigatório",
            status: 'warning',
            duration: 5000,
            position: "top-right",
            isClosable: true,
          });
          return;
        }
      }
      setLoadingNew(true);

      const formData = new FormData();
      file.map((item) => {
        formData.append(`file`, item.path);
        return item;
      });
      formData.append("description", firstHistoric);
      formData.append("status", statusId);
      formData.append("progress_status_id", progressStatusId);
      formData.append("progress_status_name", progressStatusLabel);
      formData.append("responsible", responsible);
      formData.append("active", true);
      formData.append("account", _id);
      formData.append("created", moment().format("YYYY-MM-DD"));
      formData.append("accountId", _id);
      formData.append("accountName", name);
      formData.append("day", moment().format("DD/MM/YYYY"));
      formData.append("hour", moment().format("HH:mm:ss"));
      formData.append("userAction", "Adicionou");
      formData.append("dateTime", moment().format());
      formData.append("descriptionUserAction", `${name} adicionou um histórico para o projeto de lei "${legislativeDetail.name}"`);
      formData.append("in_commission_since", inCommissionSince);
      formData.append("project_number", projectNumber);
      formData.append("sanctioned_in", sanctionedIn);
      formData.append("protocol_in", protocolIn);
      formData.append("lawNumber", lawNumber);
      formData.append("publishedIn", publishedIn);
      formData.append("filed_under", filed_under);
      formData.append("legislativeId", legislativeDetail._id);
      formData.append("opposition_opinion_published", opposition_opinion_published);
      formData.append("project_approved_and_ent_to_the_executive", project_approved_and_ent_to_the_executive)

      await createHistoric(formData);
      await requestHistoric();
      await requestLegislativeDetail(legislative.legislativeId);

      toast({
        title: '',
        description: "Histórico criado com sucesso",
        status: 'success',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
      onCloseModalNewHistoric();
      setLoadingNew(false);
      clearStates();
    } catch (error) {
      setLoadingNew(false);
      toast({
        title: '',
        description: "Algo deu errado, tente novamente",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const clearStates = () => {
    setFirstHistoric("");
    setProgressStatusLabel("");
    setProgressStatusId("");
    setFile([]);
    setFileBase64([]);
    setProtocolIn("");
    setInCommissionSince("");
    setProjectNumber("");
    setSanctionedIn("");
    setStatusLabel("");
    setPublishedIn("");
    setlawNumber("");
    setFiled_under("");
    setResponsible("");
    setOpposition_opinion_published("");
    setProject_approved_and_ent_to_the_executive("");
    setSituations([]);
    setFile([]);
    setFileBase64([]);
  }

  const requestAccounts = async () => {
    try {
      const response = await apiAccount.GetAllAccountsActive();
      const newAccounts = [];
      setAccounts(
        newAccounts.concat(
          response.data.data.map((item) => {
            return {
              key: item._id,
              text: item.name,
              value: item._id,
            };
          })
        )
      );
    } catch (error) {
      toast({
        title: "Erro",
        description: "Erro ao listar as usuários",
        status: "error",
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const requestLegislativeStatus = async () => {
    try {
      const response = await getLegislativeStatus();
      setStatus(
        response.data.data.map((item) => {
          return {
            key: item._id,
            text: `${item.status}`,
            value: item._id,
            situations: item.situations,
          };
        })
      );
    } catch (error) {
      toast({
        title: '',
        description: "Erro ao listar os status.",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const requestUpdateHistoric = async () => {
    try {
      setLoadingSave(true);
      await updateHistoric(
        {
          dateTime: moment().format(),
          userAction: "Editou",
          hour: moment().format("HH:mm:ss"),
          day: moment().format("DD/MM/YYYY"),
          accountId: _id,
          accountName: name,
          historic: descriptionUpdated,
          account: _id,
          descriptionUserAction: `${name} editou um histórico para o projeto de lei "${legislativeDetail.name}"`
        }
        , historicId);
      setHistoricId("");
      setDescriptionUpdated("");
      toast({
        title: "Parabéns",
        description: "Histórico atualizado com sucesso",
        status: "success",
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
      requestHistoric();
      onCloseModal();
      setLoadingSave(false);
    } catch (error) {
      setLoadingSave(false);
      toast({
        title: "Erro",
        description: "Erro ao atualizar histórico",
        status: "error",
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  }

  const requestHistoric = async () => {
    try {
      const response = await listHistoricInsideProject(legislative.legislativeId);
      setHistoricsProjects(response.data.data.reverse());
    } catch (error) {
      toast({
        title: "Erro",
        description: "Erro ao listar histórico",
        status: "error",
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  }

  const requestFiles = async (callIsLoading = true) => {
    try {
      if (callIsLoading) {
        setLoadingFiles(true);
      }

      const response = await listHistoricFiles(legislative.legislativeId);
      setFiles(response.data.data);
      setLoadingFiles(false);
    } catch (error) {
      setLoadingFiles(false);
      toast({
        title: "Erro",
        description: "Erro ao listar arquivos",
        status: "error",
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const requestLegislativeDetail = async (id) => {
    try {
      const response = await getLegislativeDetail(id);
      setLegislativeDetail(response.data.data);
    } catch (error) {
      toast({
        title: "Erro",
        description: "Erro ao listar o detalhe do projeto de lei",
        status: "error",
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  }

  const updateProjectLegislative = async () => {
    try {
      setEditProjectLegislative(true);
      await updateProject(
        {
          dateTime: moment().format(),
          userAction: "Editou",
          hour: moment().format("HH:mm:ss"),
          day: moment().format("DD/MM/YYYY"),
          accountId: _id,
          accountName: name,
          historic: descriptionUpdated,
          descriptionUserAction: `${name} editou o projeto de lei "${legislativeDetail.name}"`,
          name: projectName,
          overview,
          responsible: responsible,
          edited: moment().format('DD/MM/YYYY [às] HH:mm:ss')
        },
        projectId
      );
      toast({
        description: "Projeto de lei atualizado com sucesso.",
        status: 'success',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
      await requestLegislativeDetail(legislative.legislativeId);
      setOverview("");
      setResponsible("");
      setProjectName("");
      setprojectId("");
      onCloseModalProjectLegislative();
      setEditProjectLegislative(false);
    } catch (error) {
      setEditProjectLegislative(false);
      toast({
        title: "Erro",
        description: "Erro ao atualizar projeto de lei",
        status: "error",
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  }

  useEffect(() => {
    if (shoulRequest.current) {
      shoulRequest.current = false;
      setLoading(true);
      Promise.all([
        requestLegislativeStatus(),
        requestAccounts(),
        requestLegislativeDetail(legislative.legislativeId),
        requestHistoric(),
      ]).then(() => setLoading(false));
    }
  }, []);

  const toBase64 = (file, id) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let typeFile = "";

        if (file.type.includes("image")) {
          typeFile = "image";
        }
        if (file.type.includes("audio")) {
          typeFile = "audio";
        }
        if (file.type.includes("video")) {
          typeFile = "video";
        }
        if (file.type.includes("pdf")) {
          typeFile = "pdf";
        }
        const isFile = {
          name: file.name,
          id,
          type: typeFile,
          url: reader.result,
        };

        setFileBase64((oldArray) => [...oldArray, isFile]);
        resolve();
      };
      reader.onerror = reject;
    });

  const handleFileChange = (e) => {
    try {
      if (e.target.files) {
        const files = Array.from(e.target.files).map((item) => {
          const id = Math.floor(Math.random() * 1000000000000);
          toBase64(item, id);
          return {
            id,
            path: item,
          };
        });
        setFile((oldArray) => [...oldArray, ...files]);
      }
    } catch (_) { }
  };

  const removeFileList = (it) => {
    setFile(file.filter((item) => item.id !== it));
    setFileBase64(fileBase64.filter((item) => item.id !== it));
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SideBar />
      <Flex
        boxShadow="2xl"
        margin={4}
        direction="column"
        backgroundColor="white"
        borderRadius={8}
      >
        <Flex>
          <IconButton
            marginLeft={10}
            marginTop={4}
            onClick={() => history(-1)}
            icon={<ArrowBackIcon color="blue.500" />}
          />
        </Flex>
        <Tabs marginLeft={5} marginRight={5} marginTop={2} isManual variant="soft-rounded">
          <TabList>
            <Tab onClick={() => {
              requestLegislativeDetail(legislative.legislativeId);
              requestHistoric();
            }} marginLeft={5}>Projeto de lei</Tab>
            <Tab onClick={() => requestFiles()}>Feed com anexo</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Flex flexDirection="column">
                <Divider />
                {loading ? (
                  <Stack marginTop={4}>
                    <Skeleton borderRadius="2" height="60px" />
                    <Skeleton borderRadius="2" height="60px" />
                    <Skeleton borderRadius="2" height="60px" />
                    <Skeleton borderRadius="2" height="60px" />
                    <Skeleton borderRadius="2" height="120px" />
                  </Stack>
                ) : (
                  <>
                    <Flex marginTop="6" justifyContent="center">
                      <Text
                        fontSize="19"
                        color={textColor}
                        fontWeight="bold"
                        marginLeft={2}>
                        {legislativeDetail?.name || "--"}
                      </Text>
                    </Flex>
                    <TextExpanded
                      marginTop="6"
                      textLabel="Resumo:"
                      text={legislativeDetail?.overview || ""}
                    />
                    <br />
                    <CardContainer
                      bg={StatusColors(legislativeDetail?.status.status)}
                      children={
                        <div style={{ margin: 8 }}>
                          <Flex direction={{ base: "column", md: "row-reverse" }} justifyContent="space-between">
                            <Flex direction={{ base: 'column', md: 'row' }} justifyContent="flex-end">
                              <Flex>
                                <div style={{ whiteSpace: "pre-line", marginBottom: 0, color: "#1B254A" }}>
                                  <span
                                    style={{
                                      fontSize: 16,
                                      fontWeight: 600,
                                      marginRight: 4,
                                    }}>
                                    Responsavél:
                                  </span>
                                  {legislativeDetail?.responsible?.name}
                                </div>
                              </Flex>
                            </Flex>
                            <Flex>
                              <div style={{ whiteSpace: "pre-line", marginBottom: 0, color: "#1B254A" }}>
                                <span
                                  style={{
                                    fontSize: 16,
                                    fontWeight: 600,
                                    marginRight: 4,
                                  }}>
                                  Cadastrado em:
                                </span>
                                {moment(legislativeDetail?.created).format("DD/MM/YYYY")}
                              </div>
                            </Flex>
                          </Flex>
                          {historicsProject.some((item) => item.doc?.length) && (
                            <Flex marginBottom="4">
                              <Text
                                fontSize='md'
                                fontWeight='600'
                                marginRight={1}
                                color={textColor}
                              >Anexo:
                              </Text>
                              <TbFiles size="20" color="blue" />
                            </Flex>
                          )}
                          <Flex>
                            <Text
                              fontSize='md'
                              fontWeight='600'
                              color={textColor}
                            >Protocolado em:</Text>
                            <Text
                              fontSize='md'
                              color={textColor}
                              marginLeft={2}>
                              {legislativeDetail?.protocol_in ? moment(legislativeDetail?.protocol_in).format("DD/MM/YYYY") : "--"}
                            </Text>
                          </Flex>
                          <Flex>
                            <div style={{ whiteSpace: "pre-line", marginBottom: 0, color: "#1B254A" }}>
                              <span
                                style={{
                                  fontSize: 16,
                                  fontWeight: 600,
                                  marginRight: 4,
                                }}>
                                Número do projeto de lei:
                              </span>
                              {legislativeDetail?.project_number || "--"}
                            </div>
                          </Flex>
                          <Flex marginBottom="4">
                            <Text
                              fontSize='md'
                              fontWeight='600'
                              color={textColor}
                            >
                              Nas comissões desde:
                            </Text>
                            <Text
                              fontSize='md'
                              color={textColor}
                              marginLeft={2}>
                              {legislativeDetail?.in_commission_since ?
                                moment(legislativeDetail?.in_commission_since).format("DD/MM/YYYY") : "--"}
                            </Text>
                          </Flex>
                          <Flex>
                            <Text
                              fontSize='md'
                              fontWeight='600'
                              color={textColor}
                            >Aprovado e enviado ao executivo em:</Text>
                            <Text
                              fontSize='md'
                              color={textColor}
                              marginLeft={2}>
                              {legislativeDetail?.project_approved_and_ent_to_the_executive
                                ? moment(legislativeDetail?.project_approved_and_ent_to_the_executive
                                ).format("DD/MM/YYYY") : "--"}
                            </Text>
                          </Flex>
                          <Flex>
                            <Text
                              fontSize='md'
                              fontWeight='600'
                              color={textColor}
                            >Parecer contrário publicado em:</Text>
                            <Text
                              fontSize='md'
                              color={textColor}
                              marginLeft={2}>
                              {legislativeDetail?.opposition_opinion_published
                                ? moment(legislativeDetail?.opposition_opinion_published
                                ).format("DD/MM/YYYY") : "--"}
                            </Text>
                          </Flex>
                          <Flex marginBottom="4">
                            <Text
                              fontSize='md'
                              fontWeight='600'
                              color={textColor}
                            >Projeto arquivado em:</Text>
                            <Text
                              fontSize='md'
                              color={textColor}
                              marginLeft={2}>
                              {legislativeDetail?.filed_under ? moment(legislativeDetail?.filed_under).format("DD/MM/YYYY") : "--"}
                            </Text>
                          </Flex>
                          <Flex>
                            <Text
                              fontSize='md'
                              fontWeight='600'
                              color={textColor}
                            >Lei número:</Text>
                            <Text
                              fontSize='md'
                              color={textColor}
                              marginLeft={2}>
                              {legislativeDetail?.law_number}
                            </Text>
                          </Flex>
                          <Flex marginBottom="">
                            <Text
                              fontSize='md'
                              fontWeight='600'
                              color={textColor}
                            >
                              Lei sancionada em:
                            </Text>
                            <Text
                              fontSize='md'
                              color={textColor}
                              marginLeft={2}>
                              {legislativeDetail?.sanctioned_in ? moment(legislativeDetail?.sanctioned_in).format("DD/MM/YYYY") : "--"}
                            </Text>
                          </Flex>
                          <Flex marginBottom="4">
                            <Text
                              fontSize='md'
                              fontWeight='600'
                              color={textColor}
                            >
                              Lei publicada em:
                            </Text>
                            <Text
                              fontSize='md'
                              color={textColor}
                              marginLeft={2}>
                              {legislativeDetail?.publishedIn ? moment(legislativeDetail?.publishedIn).format("DD/MM/YYYY") : "--"}
                            </Text>
                          </Flex>
                          <Flex>
                            <Text
                              fontSize='md'
                              fontWeight='600'
                              color={textColor}
                            >
                              Status:
                            </Text>
                            <Text
                              fontSize='md'
                              color={textColor}
                              marginLeft={2}>
                              {legislativeDetail?.status?.status || "--"}
                            </Text>
                          </Flex>
                          <Flex
                            direction={{ base: 'column', md: 'row' }}
                            justifyContent="space-between"
                          >
                            <Flex>
                              <Text
                                fontSize='md'
                                fontWeight='600'
                                color={textColor}
                              >
                                Situação:
                              </Text>
                              <Text
                                fontSize='md'
                                color={textColor}
                                marginLeft={2}>
                                {legislativeDetail?.progress_status?.name || "--"}
                              </Text>
                            </Flex>
                            <Button
                              marginTop={{ sm: '8px', md: '0px' }}
                              size="sm"
                              marginBottom={4}
                              leftIcon={<EditIcon color="red" />}
                              colorScheme="blackAlpha"
                              onClick={() => {
                                setprojectId(legislativeDetail?._id);
                                setProjectName(legislativeDetail?.name)
                                setOverview(legislativeDetail?.overview);
                                setResponsible(legislativeDetail?.responsible?._id);
                                onOpenProjectLegislative();
                              }}
                            >
                              Editar projeto de lei
                            </Button>
                          </Flex>
                          <Flex>
                            <Text
                              fontSize='md'
                              fontWeight='600'
                              color={textColor}
                            >
                              Cadastrado por:
                            </Text>
                            <Text
                              fontSize='md'
                              color={textColor}
                              marginLeft={2}>
                              {legislativeDetail?.createdBy?.name} {moment(legislativeDetail?.created).format('DD/MM/YYYY [às] HH:mm:ss')}
                            </Text>
                          </Flex>
                          {legislativeDetail?.updatedBy && (
                            <Flex>
                              <Text
                                fontSize='md'
                                fontWeight='600'
                                color={textColor}
                              >
                                Editado por:
                              </Text>
                              <Text
                                fontSize='md'
                                color={textColor}
                                marginLeft={2}>
                                {legislativeDetail?.updatedBy?.name} {moment(legislativeDetail?.edited).format('DD/MM/YYYY [às] HH:mm:ss')}
                              </Text>
                            </Flex>
                          )}
                        </div>
                      }
                    />
                    <PingStatus />
                    <Flex
                      direction={{ base: 'column-reverse', md: 'row' }}
                      marginTop={10}
                      justifyContent="space-between"
                      alignItems={{ base: 'center', md: 'center' }}
                    >
                      <Text
                        fontSize='md'
                        fontWeight='500'
                        color={textColor}
                      >
                        HISTÓRICO DO PROJETO DE LEI
                      </Text>
                      <Flex
                      >
                        <Button
                          marginBottom={{ base: "6" }}
                          leftIcon={<AddIcon />}
                          colorScheme="blue"
                          onClick={() => {
                            setResponsible(legislativeDetail?.responsible._id);
                            setStatusId(legislativeDetail?.status._id);
                            setProgressStatusLabel(legislativeDetail?.progress_status.name);
                            setProgressStatusId(legislativeDetail?.progress_status._id);
                            setProtocolIn(legislativeDetail?.protocol_in);
                            setInCommissionSince(legislativeDetail?.in_commission_since);
                            setProjectNumber(legislativeDetail?.project_number);
                            setSanctionedIn(legislativeDetail?.sanctioned_in);
                            setPublishedIn(legislativeDetail.publishedIn);
                            setlawNumber(legislativeDetail.law_number);
                            setFiled_under(legislativeDetail?.filed_under);
                            setOpposition_opinion_published(legislativeDetail?.opposition_opinion_published);
                            setProject_approved_and_ent_to_the_executive(legislativeDetail?.project_approved_and_ent_to_the_executive);

                            const progress = status.find((item) => item.value === legislativeDetail?.status._id);
                            if (progress) {
                              setStatusLabel(progress.text);
                              setSituations(progress.situations.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map((item) => {
                                return {
                                  key: item._id,
                                  text: item.name,
                                  value: item._id,
                                };
                              }) || []);
                            }
                            onOpenModalNewHistoric();
                          }}
                        >
                          Novo histórico
                        </Button>
                      </Flex>
                    </Flex>
                    <br />
                    {historicsProject.map((item) => (
                      <Box marginTop={4}>
                        <CardContainer
                          boxShadow="2xl"
                          children={
                            <Flex direction={{ base: 'column', md: 'row' }}>
                              <Flex flex={1} direction="column">
                                <Flex alignItems="center">
                                  <Text
                                    fontSize='md'
                                    color={textColor}>
                                    {moment(item.created).format("DD/MM/YYYY")}
                                  </Text>
                                </Flex>
                                {item.doc.length > 0 && (
                                  <Flex alignItems="center">
                                    <Text
                                      fontSize='md'
                                      fontWeight='600'
                                      marginRight={1}
                                      color={textColor}
                                    >Anexo:
                                    </Text>
                                    {item.doc && (
                                      <TbFiles size="20" color="blue" />
                                    )}
                                  </Flex>
                                )}
                                <Flex>
                                  <Text
                                    fontSize='md'
                                    fontWeight='600'
                                    color={textColor}
                                  >Status:</Text>
                                  <Text marginLeft={2}>{item?.status?.status}</Text>
                                </Flex>
                                <Flex marginBottom="2.5">
                                  <Text
                                    fontSize='md'
                                    fontWeight='600'
                                    color={textColor}
                                  >
                                    Situação:
                                  </Text>
                                  <Text marginLeft={2}>{item?.progress_status?.name}</Text>
                                </Flex>
                                {item?.status.status === "Protocolado" && (
                                  <Flex>
                                    <Text
                                      fontSize='md'
                                      fontWeight='600'
                                      color={textColor}
                                    >
                                      Protocolado em:
                                    </Text>
                                    <Text marginLeft={2}>{moment(item?.legislative?.protocol_in).format("DD/MM/YYYY")}</Text>
                                  </Flex>
                                )}
                                {item?.status.status === "Em comissões" && (
                                  <Flex>
                                    <Text
                                      fontSize='md'
                                      fontWeight='600'
                                      color={textColor}
                                    >
                                      Nas comissões desde:
                                    </Text>
                                    <Text marginLeft={2}>{moment(item?.legislative.in_commission_since).format("DD/MM/YYYY")}</Text>
                                  </Flex>
                                )}
                                {item?.status.status === "Lei aprovada" && (
                                  <>
                                    <Flex>
                                      <Text
                                        fontSize='md'
                                        fontWeight='600'
                                        color={textColor}
                                      >
                                        Lei sancionada em:
                                      </Text>
                                      <Text marginLeft={2}>{moment(item?.legislative.sanctioned_in).format("DD/MM/YYYY")}</Text>
                                    </Flex>
                                    <Flex>
                                      <Text
                                        fontSize='md'
                                        fontWeight='600'
                                        color={textColor}
                                      >
                                        Lei publicada em:
                                      </Text>
                                      <Text marginLeft={2}>{moment(item?.legislative.publishedIn).format("DD/MM/YYYY")}</Text>
                                    </Flex>
                                  </>
                                )}
                                {item?.status.status === "Arquivada" && (
                                  <Flex>
                                    <Text
                                      fontSize='md'
                                      fontWeight='600'
                                      color={textColor}
                                    >
                                      Projeto arquivado em:
                                    </Text>
                                    <Text marginLeft={2}>{moment(item?.legislative?.filed_under).format("DD/MM/YYYY")}</Text>
                                  </Flex>
                                )}
                                {item?.progress_status?.name === "Projeto aprovado e enviado ao executivo" && (
                                  <Flex>
                                    <Text
                                      fontSize='md'
                                      fontWeight='600'
                                      color={textColor}
                                    >
                                      Aprovado e enviado ao executivo em:
                                    </Text>
                                    <Text
                                      fontSize='md'
                                      color={textColor}
                                      marginLeft={2}>
                                      {item?.legislative?.project_approved_and_ent_to_the_executive
                                        ? moment(item?.legislative.project_approved_and_ent_to_the_executive
                                        ).format("DD/MM/YYYY") : "--"}
                                    </Text>
                                  </Flex>
                                )}
                                {item?.progress_status?.name === "Parecer contrário publicado" && (
                                  <Flex>
                                    <Text
                                      fontSize='md'
                                      fontWeight='600'
                                      color={textColor}
                                    >Parecer contrário publicado em:</Text>
                                    <Text
                                      fontSize='md'
                                      color={textColor}
                                      marginLeft={2}>
                                      {item?.legislative.opposition_opinion_published
                                        ? moment(item?.legislative.opposition_opinion_published
                                        ).format("DD/MM/YYYY") : "--"}
                                    </Text>
                                  </Flex>
                                )}
                                <Flex marginBottom="2.5">
                                  <TextExpanded
                                    marginTop="2"
                                    key={item._id}
                                    text={item?.description || "Não há histórico"}
                                    textLabel="Último histórico:"
                                  />
                                </Flex>
                                <Flex marginBottom="4" direction={{ base: 'column', md: 'row' }} justifyContent="space-between">
                                  <Flex>
                                    <Text
                                      fontSize='md'
                                      fontWeight='600'
                                      color={textColor}
                                    >Responsável:</Text>
                                    <Text
                                      marginLeft={2}
                                    >
                                      {item?.responsible?.name}
                                    </Text>
                                  </Flex>
                                  <Flex
                                    marginTop={{ base: 2, md: 0 }}
                                    direction="column"
                                    justifyContent={{ base: "space-between", md: "center" }}
                                    alignItems={{ base: "flex-start", md: "flex-end" }}
                                  >
                                    <Text
                                      fontSize='md'
                                      color={textColor}
                                    >
                                      Criado por {item?.createdBy?.name}{" "}
                                      {moment(item?.date).format(
                                        "DD/MM/YYYY [às] H:mm:ss"
                                      )}
                                    </Text>
                                    <Text
                                      display={item?.updatedBy ? 'flex' : 'none'}
                                      fontSize='md'
                                      color={textColor}
                                    >
                                      Editado por {item?.updatedBy?.name}{" "}
                                      {moment(item?.updated).format("DD/MM/YYYY [às] H:mm:ss")}
                                    </Text>
                                  </Flex>
                                </Flex>
                              </Flex>
                              <Flex
                                position="absolute"
                                top="2"
                                right="2"
                              >
                                <IconButton
                                  marginBottom={{ base: 0, md: 4 }}
                                  aria-label="Editar"
                                  icon={<EditIcon color="orange.500" />}
                                  onClick={() => {
                                    setHistoricId(item._id);
                                    setDescriptionUpdated(item?.description);
                                    onOpenModal();
                                  }}
                                />
                              </Flex>
                            </Flex>
                          }
                        />
                      </Box>
                    ))}
                  </>
                )}
                <div style={{ marginBottom: 10 }} />
              </Flex>
            </TabPanel>
            <TabPanel>
              {loadingFiles ? (
                <Box
                  display="flex"
                  justifyContent="center"

                  marginTop="10"
                  height="container.lg"
                >
                  <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    colorScheme="blue"
                    size="lg"
                  />
                </Box>
              ) : (
                <div>
                  {files.length > 0 ? (
                    <>
                      {files.map((item) => {
                        return (
                          <CardList
                            projectName={legislativeDetail.name}
                            id={item._id}
                            onRefresh={() => requestFiles(false)}
                            accountName={item?.createdBy?.name}
                            date={item.date}
                            path={item.doc}
                            data={{
                              accountId: _id,
                              accountName: name,
                              userAction: "Editou",
                              userId: undefined,
                              userName: undefined,
                              description: item.description || ""
                            }}
                          />
                        );
                      })}
                    </>
                  ) : (
                    <Box display="flex" minH="container.lg" justifyContent="center">
                      <Text>Sem arquivos</Text>
                    </Box>
                  )}
                </div>
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
        <AlertDialog
          motionPreset="slideInBottom"
          leastDestructiveRef={cancelRef}
          onClose={onCloseModal}
          isOpen={isOpenModal}
          isCentered
          size="4xl"
        >
          <AlertDialogOverlay />
          <AlertDialogContent>
            <AlertDialogHeader

              textAlign="center"
              justifyContent="center"
            >
              EDIÇÃO DO HISTÓRICO DO PROJETO DE LEI
            </AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody borderStyle="solid" borderWidth={1}>
              <Textarea
                minHeight="56"
                required
                value={descriptionUpdated}
                onChange={(event) => setDescriptionUpdated(event.target.value)}
                placeholder="..."
              />
            </AlertDialogBody>
            <AlertDialogFooter >
              <Button colorScheme="red" onClick={onCloseModal}>
                Fechar
              </Button>
              <Button
                marginLeft={2}
                colorScheme="green"
                isLoading={loadingSave}
                isDisabled={loadingSave}
                type="button"
                onClick={() => requestUpdateHistoric()}
              >
                Salvar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
        <AlertDialog
          motionPreset="slideInBottom"
          leastDestructiveRef={cancelRef}
          onClose={() => {
            onCloseModalNewHistoric();
            clearStates();
          }}
          isOpen={isOpenModalNewHistoric}
          size="5xl"
        >
          <AlertDialogOverlay />
          <AlertDialogContent>
            <AlertDialogHeader textAlign="center">
              Novo histórico
            </AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody
              borderStyle="solid"
              borderWidth={1}
              paddingTop={10}
              paddingBottom={8}
            >
              <Flex direction="column">
                <Flex direction="column" flex={1}>
                  <Flex justifyContent="center" fontWeight="bold" marginBottom="2">
                    <Text fontFamily="18">{legislativeDetail?.name}</Text>
                  </Flex>
                  <FormControl marginTop="4" isRequired>
                    <FormLabel
                      display='flex'
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      color={textColor}
                      mb='8px'>
                      Status
                    </FormLabel>
                    <Select
                      required
                      value={statusId}
                      onChange={(event) => {
                        if (event.target.value === "Selecione") {
                          setSituations([]);
                          setStatusId("");
                          return
                        };

                        setProtocolIn("");
                        setInCommissionSince("");
                        setProjectNumber("");
                        setSanctionedIn("");
                        setPublishedIn("");
                        setlawNumber("");
                        setProgressStatusLabel("");
                        setFiled_under("");
                        setOpposition_opinion_published("");
                        setProject_approved_and_ent_to_the_executive("");

                        setStatusLabel(status?.filter((it) => it.key === event.target.value)[0]?.text)

                        setStatusId(event.target.value);
                        setSituations([]);
                        setSituations(
                          status
                            ?.filter((it) => it.value === event.target.value)[0]
                            ?.situations.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map((item) => {
                              return {
                                key: item._id,
                                text: item.name,
                                value: item._id,
                              };
                            })
                        );
                      }}
                    >
                      <option value={"Selecione"}>Selecione</option>
                      {status.map((item) => (
                        <option value={item.value}>{item.text}</option>
                      ))}
                    </Select>
                  </FormControl>
                  <br />
                  {statusLabel === "Em comissões" && (
                    <div>
                      <FormControl isRequired marginBottom="6">
                        <FormLabel
                          display='flex'
                          ms='4px'
                          fontSize='sm'
                          fontWeight='500'
                          color={textColor}
                          mb='8px'>
                          Na comissão desde:
                        </FormLabel>
                        <Input
                          type="date"
                          required
                          value={inCommissionSince}
                          onChange={(event) =>
                            setInCommissionSince(event.target.value)
                          }
                          placeholder="..."
                        />
                      </FormControl>
                    </div>
                  )}
                  {statusLabel === "Lei aprovada" && (
                    <>
                      <FormControl isRequired marginBottom="6">
                        <FormLabel
                          display='flex'
                          ms='4px'
                          fontSize='sm'
                          fontWeight='500'
                          color={textColor}
                          mb='8px'>
                          Lei sancionada em:
                        </FormLabel>
                        <Input
                          required
                          value={sanctionedIn}
                          onChange={(event) =>
                            setSanctionedIn(event.target.value)
                          }
                          placeholder="..."
                          type="date"
                        />
                      </FormControl>
                      <FormControl isRequired marginBottom="6">
                        <FormLabel
                          display='flex'
                          ms='4px'
                          fontSize='sm'
                          fontWeight='500'
                          color={textColor}
                          mb='8px'>
                          Lei nº:
                        </FormLabel>
                        <Input
                          required
                          value={lawNumber}
                          onChange={(event) =>
                            setlawNumber(event.target.value)
                          }
                          placeholder="..."
                          type="text"
                        />
                      </FormControl>
                      <FormControl isRequired marginBottom="6">
                        <FormLabel
                          display='flex'
                          ms='4px'
                          fontSize='sm'
                          fontWeight='500'
                          color={textColor}
                          mb='8px'>
                          Lei publicada em:
                        </FormLabel>
                        <Input
                          required
                          value={publishedIn}
                          onChange={(event) =>
                            setPublishedIn(event.target.value)
                          }
                          placeholder="..."
                          type="date"
                        />
                      </FormControl>
                    </>
                  )}
                  <FormControl isRequired>
                    <FormLabel
                      display='flex'
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      color={textColor}
                      mb='8px'>
                      Situação
                    </FormLabel>
                    <Select
                      required
                      onChange={(event) => {
                        const value = situations.find((item) => item.value === event.target.value);
                        setProgressStatusLabel(value?.text);
                        setProgressStatusId(event.target.value);
                      }}
                      value={progressStatusId}
                    >
                      <option value={""}>Selecione</option>
                      {situations.map((item) => (
                        <option value={item.value}>{item.text}</option>
                      ))}
                    </Select>
                  </FormControl>
                  {statusLabel === "Arquivada" && (
                    <FormControl isRequired marginTop="6">
                      <FormLabel
                        display='flex'
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        color={textColor}
                        mb='8px'>
                        Arquivado em:
                      </FormLabel>
                      <Input
                        required
                        value={filed_under}
                        onChange={(event) =>
                          setFiled_under(event.target.value)
                        }
                        placeholder="..."
                        type="date"
                      />
                    </FormControl>
                  )}
                  {progressStatusLabel === "Projeto aprovado e enviado ao executivo" && (
                    <FormControl isRequired marginTop="6">
                      <FormLabel
                        display='flex'
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        color={textColor}
                        mb='8px'>
                        Aprovado e enviado ao executivo em:
                      </FormLabel>
                      <Input
                        required
                        value={project_approved_and_ent_to_the_executive}
                        onChange={(event) =>
                          setProject_approved_and_ent_to_the_executive(event.target.value)
                        }
                        placeholder="..."
                        type="date"
                      />
                    </FormControl>
                  )}
                  {progressStatusLabel === "Parecer contrário publicado" && (
                    <FormControl isRequired marginTop="6">
                      <FormLabel
                        display='flex'
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        color={textColor}
                        mb='8px'>
                        Parecer contrário publicado em:
                      </FormLabel>
                      <Input
                        required
                        value={opposition_opinion_published}
                        onChange={(event) =>
                          setOpposition_opinion_published(event.target.value)
                        }
                        placeholder="..."
                        type="date"
                      />
                    </FormControl>
                  )}
                  {progressStatusLabel === "Revisado (aguardando leitura em sessão)" && (
                    <FormControl isRequired marginBottom="5" marginTop="8">
                      <FormLabel
                        display='flex'
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        color={textColor}
                        mb='8px'>
                        Número do projeto:
                      </FormLabel>
                      <Input
                        required
                        value={projectNumber}
                        onChange={(event) => {
                          setProjectNumber(event.target.value)
                        }}
                        placeholder="..."
                        type="text"
                      />
                    </FormControl>
                  )}
                  {statusLabel === "Protocolado" && (
                    <FormControl isRequired marginBottom="6" marginTop="6">
                      <FormLabel
                        display='flex'
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        color={textColor}
                        mb='8px'>
                        Protocolado em:
                      </FormLabel>
                      <Input
                        required
                        type="date"
                        value={protocolIn}
                        onChange={(event) =>
                          setProtocolIn(event.target.value)
                        }
                        placeholder="..."
                      />
                    </FormControl>
                  )}
                  <FormControl marginTop="6" isRequired>
                    <FormLabel
                      display='flex'
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      color={textColor}
                      mb='8px'>
                      Histórico
                    </FormLabel>
                    <Textarea
                      value={firstHistoric}
                      onChange={(event) =>
                        setFirstHistoric(event.target.value)
                      }
                      placeholder="..."
                    />
                  </FormControl>
                  <Text marginBottom={1} marginTop={4}>
                    Responsável
                  </Text>
                  <Select
                    value={responsible}
                    onChange={(event) => {
                      setResponsible(event.target.value);
                    }}
                  >
                    {accounts.map((item) => (
                      <option value={item.value}>{item.text}</option>
                    ))}
                  </Select>
                </Flex>
                <Flex marginTop={10} direction="column">
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {fileBase64
                      .filter((item) => item.type === "image")
                      .map((item) => (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            width: 100,
                            height: 120,
                          }}
                        >
                          <Image
                            marginRight={2}
                            borderRadius={2}
                            maxH={90}
                            bgSize="contain"
                            src={item.url}
                            alt="doc"
                          />
                          <IconButton
                            height={10}
                            marginRight={2}
                            onClick={() => removeFileList(item.id)}
                            icon={<DeleteIcon color="red" />}
                            aria-label=""
                          />
                        </div>
                      ))}
                  </div>
                  <div style={{ display: "flex", flexWrap: "wrap", marginTop: 20 }}>
                    {fileBase64
                      .filter((item) => item.type === "audio")
                      .map((item) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginBottom: 10,
                          }}
                        >
                          <ReactPlayer
                            url={item.url}
                            width="332px"
                            height="35px"
                            playing={false}
                            controls
                          />
                          <IconButton
                            height={10}
                            borderRadius={40}
                            marginLeft={2}
                            onClick={() => removeFileList(item.id)}
                            icon={<DeleteIcon color="red" />}
                            aria-label=""
                          />
                        </div>
                      ))}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: 20,
                    }}
                  >
                    {fileBase64
                      .filter((item) => item.type === "video")
                      .map((item, index) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginBottom: 10,
                          }}
                        >
                          <Stack marginBottom={5}>
                            <Icon size="large" color="blue" name="play circle" />
                          </Stack>
                          <Text>{item.name}</Text>
                          <IconButton
                            marginBottom={1}
                            marginTop={2}
                            borderRadius={40}
                            marginLeft={2}
                            onClick={() => removeFileList(item.id)}
                            icon={<DeleteIcon color="red" />}
                            aria-label=""
                          />
                        </div>
                      ))}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: 20,
                    }}
                  >
                    {fileBase64
                      .filter((item) => item.type === "pdf")
                      .map((item, index) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginBottom: 10,
                          }}
                        >
                          <Stack marginBottom={5}>
                            <Icon size="large" color="blue" name="file pdf" />
                          </Stack>
                          <Text>{item.name}</Text>
                          <IconButton
                            marginBottom={1}
                            marginTop={2}
                            borderRadius={40}
                            marginLeft={2}
                            onClick={() => removeFileList(item.id)}
                            icon={<DeleteIcon color="red" />}
                            aria-label=""
                          />
                        </div>
                      ))}
                  </div>
                  <Flex>
                    <input
                      onChange={handleFileChange}
                      multiple
                      accept="image/png, image/jpeg, video/mp4, video/webm, video/mp3, video/ogv, audio/*, application/pdf"
                      type="file"
                      id="actual-btn"
                      hidden
                    />
                    <div
                      style={{
                        backgroundColor: "#2F8559",
                        color: "white",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        height: 35,
                        paddingRight: 12,
                        paddingLeft: 12,
                        justifyContent: "center",
                        borderRadius: 6,
                      }}
                    >
                      <label
                        style={{ cursor: "pointer" }}
                        // @ts-ignore
                        htmlFor="actual-btn"
                      >
                        <AttachmentIcon /> Upload de arquivo
                      </label>
                    </div>
                  </Flex>
                </Flex>
              </Flex>
            </AlertDialogBody>
            <AlertDialogFooter >
              <Button
                colorScheme="red"
                marginRight="2"
                disabled={loadingNew}
                onClick={() => {
                  onCloseModalNewHistoric();
                  clearStates();
                }}
              >
                Cancelar
              </Button>
              <Button
                colorScheme="green"
                disabled={loadingNew}
                isLoading={loadingNew}
                onClick={() => submit()}
              >
                Salvar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
        <AlertDialog
          motionPreset="slideInBottom"
          leastDestructiveRef={cancelRef}
          onClose={() => {
            setOverview("");
            setProjectName("");
            setprojectId("");
            setResponsible("");
            onCloseModalProjectLegislative();
          }}
          isOpen={isOpenProjectLegislative}
          isCentered
          size="4xl"
        >
          <AlertDialogOverlay />
          <AlertDialogContent>
            <AlertDialogHeader textAlign="center">
              Projeto de lei
            </AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody borderStyle="solid" borderWidth={1} paddingTop={10}>
              <Form onSubmit={submit} style={{ heigth: "100%" }}>
                <FormControl isRequired marginBottom="6">
                  <FormLabel
                    display='flex'
                    ms='4px'
                    fontSize='sm'
                    fontWeight='500'
                    color={textColor}
                    mb='8px'>
                    Nome do projeto de lei
                  </FormLabel>
                  <Input
                    required
                    value={projectName}
                    onChange={(event) =>
                      setProjectName(event.target.value)
                    }
                    placeholder="..."
                  />
                </FormControl>
                <FormControl isRequired marginBottom="6">
                  <FormLabel
                    display='flex'
                    ms='4px'
                    fontSize='sm'
                    fontWeight='500'
                    color={textColor}
                    mb='8px'>
                    Resumo do projeto de lei
                  </FormLabel>
                  <Textarea
                    required
                    rows={5}
                    value={overview}
                    onChange={(event) =>
                      setOverview(event.target.value)
                    }
                    placeholder="..."
                  />
                </FormControl>
                <FormControl>
                  <FormLabel
                    ms='4px'
                    fontSize='sm'
                    fontWeight='500'
                    color={textColor}
                    display='flex'>
                    Responsavél
                  </FormLabel>
                  <Select
                    value={responsible}
                    onChange={(event) => {
                      setResponsible(event.target.value);
                    }}
                  >
                    {accounts.map((item) => (
                      <option value={item.value}>{item.text}</option>
                    ))}
                  </Select>
                </FormControl>
                <br />
              </Form>
            </AlertDialogBody>
            <AlertDialogFooter >
              <Button
                marginRight={2}
                colorScheme="red"
                disabled={loadingEditProjectLegislative}
                onClick={() => {
                  setOverview("");
                  setProjectName("");
                  setprojectId("");
                  setResponsible("");
                  onCloseModalProjectLegislative();
                }}
              >
                Cancelar
              </Button>
              <Button
                colorScheme="green"
                disabled={loadingEditProjectLegislative}
                isLoading={loadingEditProjectLegislative}
                onClick={() => updateProjectLegislative()}
              >
                Salvar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </Flex>
    </Box>
  );
};
export default ShowLegislationPage;
