import InputMask from "react-input-mask";
import { cpf as CPF } from "cpf-cnpj-validator";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Resizer from "react-image-file-resizer";
import { AiFillDelete } from "react-icons/ai";
import 'react-phone-number-input/style.css'
import {
  CreatableSelect,
  Select as SelectChakra,
} from "chakra-react-select";
import { MdArrowDropDown } from 'react-icons/md'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Webcam from "react-webcam";
import {
  Form,
} from "semantic-ui-react";

import axiosCategorie from "../../services/urls/category";
import axiosUser from "../../services/urls/user";
import b64toBlob from "../../utils/blob";
import SideBar from "components/@sidebar";
import {
  Box,
  Button,
  useColorModeValue,
  Text,
  Flex,
  Input,
  InputGroup,
  Image,
  FormLabel,
  IconButton,
  FormControl,
  Select,
  Checkbox,
  Textarea,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import Upload from "components/upload_file";
import { HelpIcon } from "components/icons/Icons";
import axiosDefault from "services/api";

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

const videoConstraints = {
  width: 450,
  height: 260,
  facingMode: FACING_MODE_USER,
};
const RegistrationPeople = () => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = React.useRef()
  const textColor = useColorModeValue("secondaryGray.900", "white");
  let shoulRequest = useRef(true);
  const history = useNavigate();
  const [facingMode, setFacingMode] = React.useState(FACING_MODE_USER);
  const [basic, setBasic] = useState(false);
  const [complete, setComplete] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [description, setDescription] = useState("");
  const [categorie, setCategorie] = useState([]);
  const [indication, setIndication] = useState("");
  const [phone, setPhone] = useState("");
  const [whats, setWhats] = useState("");
  const [photo, setPhoto] = useState("");
  const [photoPreView, setPhotoPreView] = useState("");
  const [rgOrgao, setRgOrgao] = useState("");
  const [pais, setPais] = useState("");
  const [apelido, setApelido] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [gender, setGender] = useState("");
  const [bairro, setBairro] = useState("");
  const [localization, setLocalization] = useState("");
  const [rg, setRg] = useState("");
  const [cpf, setCpf] = useState("");
  const [cnh, setCnh] = useState("");
  const [tituto, setTituto] = useState("");
  const [zona, setZona] = useState("");
  const [section, setSection] = useState("");
  const [facebook, setFacebook] = useState("");
  const [insta, setInsta] = useState("");
  const [occupation, setOccupation] = useState("");
  const [file, setfile] = useState();
  const [state, setstate] = useState("");
  const [email, setEmail] = useState("");
  const [isFace, setIsFace] = useState(false);
  const [isInst, setIsInst] = useState(false);
  const [phoneContact, setPhoneContact] = useState("");
  const [phoneContactName, setPhoneContactName] = useState("");

  const [addressReference, setAddressReference] = useState("");
  const [whatsAppContactName, setWhatsAppContactName] = useState("");
  const [whatsAppContact, setWhatsAppContact] = useState("");
  const [susNumero, setSusNumero] = useState("");
  const [tituloError, setTituloError] = useState(false);
  const [tituloMsg, setTituloMsg] = useState(null);
  const [cpfError, setCpfError] = useState(false);
  const [cpfMsg, setCpfMsg] = useState(null);
  const [rgError, setRgError] = useState(false);
  const [rgMsg, setRgMsg] = useState(null);
  const [states, setstates] = useState([]);
  const [publicPlaces, setPublicPlaces] = useState([]);
  const [publicPlace, setPublicPlace] = useState(null);
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [countries, setCountries] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [cities, setCities] = useState([]);
  const [stateId, setStateId] = useState('');
  const [countryId, setCountryId] = useState('');
  const [cityId, setCityId] = useState('');
  const [neighborhood, setNeighborhood] = useState(null);
  const [districtId, setDistrictId] = useState(null);
  const [isLoadingDistricts, setIsLoadingDistricts] = useState(false);
  const [isCitiesLoading, setIsCitiesLoading] = useState("");
  const [isPlaceLoading, setIsLoadingPlaces] = useState(false);
  const [isNeighborhoodsLoading, setIsNeighborhoodsLoading] = useState(false)

  const { _id, name: accountName } = useSelector(
    (state) => state.user
  );

  const [gategories, setCategories] = useState([]);

  const handleClick = React.useCallback(() => {
    setFacingMode((prevState) =>
      prevState === FACING_MODE_USER
        ? FACING_MODE_ENVIRONMENT
        : FACING_MODE_USER
    );
  }, []);

  const requestCategories = useCallback(async () => {
    try {
      const response = await axiosCategorie.GetCategoriesActive();
      setCategories(
        response?.data?.data.map((item) => {
          return {
            label: item.name,
            value: item._id,
          };
        })
      );
    } catch (error) {
      toast({
        title: '',
        description: "Erro ao buscar categorias.",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  }, [toast])

  const clearStates = () => {
    setName("");
    setstate("");
    setAge("");
    setDescription("");
    setCategorie([]);
    setPhone("");
    setWhats("");
    setIndication("");
    setPhoto("");
    setPais("");
    setApelido("");
    setCity("");
    setAddress("");
    setHouseNumber("");
    setBairro("");
    setstate("");
    setLocalization("");
    setRg("");
    setCpf("");
    setCnh("");
    setTituto("");
    setZona("");
    setSection("");
    setFacebook("");
    setInsta("");
    setOccupation("");
    setfile(null);
    setEmail("");
  };

  const saveUser = async () => {
    try {
      if (cpfError || tituloError || rgError) {
        toast({
          title: '',
          description: "Verifique as informações do formulário.",
          status: 'warning',
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }

      setLoading(true);
      const response = await axiosUser.saveDataBasicUser(
        file,
        name.trim(),
        age,
        _id,
        phone,
        whats,
        indication,
        categorie.length > 0 ? categorie.map((item) => item.value) : undefined,
        description,
        apelido,
        photo,
        pais === null ? undefined : pais,
        city,
        address,
        houseNumber,
        bairro,
        localization,
        rg,
        cpf,
        cnh,
        tituto,
        zona,
        section,
        facebook,
        insta,
        occupation,
        state.toUpperCase() || undefined,
        rgOrgao,
        whatsAppContact,
        whatsAppContactName,
        addressReference,
        susNumero,
        email,
        isFace,
        isInst,
        accountName,
        moment().format("DD/MM/YYYY"),
        moment().format("HH:mm:ss"),
        "Adicionou",
        moment().format(),
        phoneContact,
        phoneContactName,
        gender,
        countryId,
        stateId?.value,
        cityId?.value,
        neighborhood.__isNew__ ? undefined : neighborhood?.value,
        publicPlace.__isNew__ ? undefined : publicPlace?.value,
        neighborhood.__isNew__ ? neighborhood?.label : undefined,
        publicPlace.__isNew__ ? publicPlace?.label : undefined,
        districtId?.value,
      );
      toast({
        title: '',
        description: "Cadastro realizado com sucesso.",
        status: 'success',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
      clearStates();
      const userId = response.data.data;
      history(
        "/visualizar-cadastrado",
        {
          state: {
            userId,
          }
        },
      );
    } catch (error) {
      toast({
        title: '',
        description: "Algo deu errado, reveja os dados informados e tente novamente.",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const createFile = (ImageURL) => {
    const block = ImageURL.split(";");
    const contentType = block[0].split(":")[1];
    const realData = block[1].split(",")[1];
    const blob = b64toBlob(realData, contentType, "");
    setfile(blob);
  };

  const webcamRef = React.useRef(null);
  const capture = React.useCallback(() => {
    // @ts-ignore
    const imageSrc = webcamRef.current.getScreenshot();
    setPhoto(imageSrc);
    setPhotoPreView(imageSrc);
    createFile(imageSrc);
  }, [webcamRef]);

  const getImage = (file) =>
    new Promise(() => {
      Resizer.imageFileResizer(
        file,
        400,
        200,
        "PNG",
        100,
        0,
        (uri) => {
          setPhoto(uri.toString());
        },
        "base64"
      );
    });
  const resizeImage = (file) => setfile(file);

  const WebcamCapture = () => (
    <>
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        style={{ width: "100%" }}
        videoConstraints={{
          facingMode,
        }}
      />
      <div
        style={{
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Button
          marginTop={4}
          colorScheme="green"
          onClick={capture}
        >
          Capturar foto
        </Button>
      </div>
    </>
  );

  const validateRG = useCallback(async () => {
    try {
      await axiosUser.validationDoc(undefined, rg, undefined);
      setRgError(false);
      setRgMsg(null);
    } catch (error) {
      setRgError(true);
      setRgMsg(error?.response?.data?.error);
      toast({
        title: '',
        description: error?.response?.data?.error,
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  }, [rg, toast])

  const validateTitulo = useCallback(async () => {
    try {
      await axiosUser.validationDoc(undefined, undefined, tituto);
      setTituloError(false);
      setTituloMsg(null);
    } catch (error) {
      setTituloError(true);
      setTituloMsg(error?.response?.data?.error);
      toast({
        title: '',
        description: error?.response?.data?.error,
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  }, [tituto, toast])

  const validateCPF = useCallback(async () => {
    try {
      await axiosUser.validationDoc(cpf, undefined, tituto);
      setCpfError(false);
      setCpfMsg(null);
    } catch (error) {
      setCpfError(true);
      setCpfMsg(error?.response?.data?.error);
      toast({
        title: '',
        description: error?.response?.data?.error,
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  }, [cpf, tituto, toast])

  const loadAllCountries = useCallback(async () => {
    try {
      const { data } = (await axiosDefault.get("place/all-coutries")).data;
      setCountries(data.map((item) => {
        return {
          label: item.name,
          value: item._id,
        }
      }));
    } catch (error) {

    }
  }, []);

  const loadAllNeighborhood = useCallback(async () => {
    try {
      setIsNeighborhoodsLoading(true)
      const { data } = (await axiosDefault.get(`place/list-all-neighborhoods/${cityId?.value}`)).data;
      setNeighborhoods(data.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).map((item) => {
        return {
          label: item.name,
          value: item._id,
          cityId: item.cityId,
        }
      }));
      setIsNeighborhoodsLoading(false)
    } catch (error) {
      setIsNeighborhoodsLoading(false)
    }
  }, [cityId?.value]);

  const loadDistricts = useCallback(async () => {
    try {
      setIsLoadingDistricts(true)
      const { data } = (await axiosDefault.get(`place/list-all-districts/${stateId?.value}`)).data;
      setDistricts(data.map((item) => {
        return {
          label: item.name,
          value: item._id,
          cityId: item.cityId,
        }
      }));
      setIsLoadingDistricts(false)
    } catch (error) {
      setIsLoadingDistricts(false)
    }
  }, [stateId]);

  const loadCities = useCallback(async () => {
    try {
      const citiesReordered = []
      const pontapordId = "66199fa822eddc4da088b30d"
      setIsCitiesLoading(true)
      const { data } = (await axiosDefault.get(`place/list-all-cities/${stateId?.value}`)).data;

      const firstCity = data.find((city) => city._id === pontapordId)

      if (firstCity) {
        citiesReordered.push({
          label: firstCity.name,
          value: firstCity._id,
          stateId: firstCity.stateId,
        })
      }

      const citiesApi = data.filter((city) => city._id !== pontapordId).map((item) => {
        return {
          label: item.name,
          value: item._id,
          stateId: item.stateId,
        }
      })

      setCities(citiesReordered.concat(citiesApi));
      setIsCitiesLoading(false)
    } catch (error) {
      setIsCitiesLoading(false)
    }
  }, [stateId]);

  const loadAllPlaces = useCallback(async () => {
    try {
      setIsLoadingPlaces(true)
      const { data } = (await axiosDefault.get(`/place/all-streets/${neighborhood?.value || districtId?.value}`)).data;

      setPublicPlaces(data.map((item) => {
        return {
          label: item.name,
          value: item._id,
          stateId: item.stateId,
        }
      }));
      setIsLoadingPlaces(false)
    } catch (error) {
      setIsLoadingPlaces(false)
    }
  }, [districtId, neighborhood]);

  const requestStates = useCallback(async () => {
    try {
      const stateMsId = "62c8305e150ad743b7e77788";
      const response = await axiosUser.getStates();
      const newStates = [];

      const msState = response?.data?.data.find((it) => it._id === stateMsId)

      if (msState) {
        newStates.push({
          key: msState._id,
          text: msState.name,
          value: msState._id,
          countryId: msState.countryId,
        })
      }

      response?.data?.data.filter((it) => it._id !== stateMsId).map((item) => {
        newStates.push({
          key: item._id,
          text: item.name,
          value: item._id,
          countryId: item.countryId,
        });
        return item;
      });

      setstates(newStates);
    } catch (error) {
      console.log(error)
      toast({
        title: '',
        description: "Erro ao buscar os Estados",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  }, [toast]);

  useEffect(() => {
    if (neighborhood?.value) {
      loadAllPlaces()
    }
    if (stateId?.value) {
      loadCities()
      loadDistricts()
    }
    if (cityId?.value) {
      loadAllNeighborhood()
    }
    if (districtId?.value) {
      loadAllPlaces()
    }
  }, [countryId, loadAllPlaces, loadCities, stateId, loadDistricts, cityId, loadAllNeighborhood, neighborhood, districtId?.value])

  useEffect(() => {
    if (
      cpf.replaceAll(".", "").replaceAll("_", "").replace("-", "").length === 11
    ) {
      const isValid = CPF.isValid(cpf.replaceAll(".", "").replace("-", ""));
      if (!isValid) {
        setCpfError(true);
        setCpfMsg("CPF inválido.");
        toast({
          title: '',
          description: "CPF inválido.",
          status: 'warning',
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });
      } else {
        validateCPF();
      }
    }
    if (
      cpf.replaceAll(".", "").replaceAll("_", "").replace("-", "").length === 0
    ) {
      setCpfError(false);
      setCpfMsg(null);
    }
  }, [cpf, toast, validateCPF]);

  useEffect(() => {
    if (
      rg.replaceAll(".", "").replaceAll("_", "").replace("-", "").length === 9
    ) {
      validateRG();
    }
    if (
      rg.replaceAll(".", "").replaceAll("_", "").replace("-", "").length === 0
    ) {
      setRgError(false);
      setRgMsg(null);
    }
  }, [rg, validateRG]);

  useEffect(() => {
    if (tituto.length === 12) {
      validateTitulo();
    }
    if (tituto.length === 0) {
      setTituloError(false);
      setTituloMsg(null);
    }
  }, [tituto, validateTitulo]);

  useEffect(() => {
    if (shoulRequest.current) {
      shoulRequest.current = false;
      requestCategories();
      loadAllCountries();
      requestStates();
    }
  }, [loadAllCountries, requestCategories, requestStates]);

  useEffect(() => {
    if (basic) {
      setCpfError(false);
      setCpfMsg(null);
      setRgError(false);
      setRgMsg(null);
      setTituloError(false);
      setTituloMsg(null);
    }
  }, [basic]);

  const capitalizeItemList = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SideBar />
      <Flex
        flexDirection="column"
        borderRadius={10}
        padding={6}
        marginTop={4}
        marginBottom="10"
        marginLeft={4}
        marginRight={4}
        backgroundColor="white">
        <Flex
          position='fixed'
          right='14'
          zIndex='1'
        >
          <IconButton colorScheme="orange">
            <HelpIcon />
          </IconButton>
        </Flex>
        <Flex flex={1}>
          <Button
            size="sm"
            marginRight={2}
            paddingLeft={4}
            paddingRight={4}
            bg={!complete ? "#3311DB" : "#ab9bf7"}
            color="white"
            fontSize="sm"
            marginBottom={{ base: 2, md: 0 }}
            onClick={() => {
              setBasic(false);
              setComplete(false);
            }}
          >
            Cadastro Básico
          </Button>
          <Button
            size="sm"
            color="white"
            paddingLeft={4}
            paddingRight={4}
            bg={!complete ? "#ab9bf7" : "#3311DB"}
            fontSize="sm"
            onClick={() => {
              setBasic(true);
              setComplete(!complete);
            }}
          >
            Cadastro Completo
          </Button>
        </Flex>
        <Form onSubmit={saveUser} loading={loading}>
          <Flex marginTop="20" justifyContent="center" alignItems="center">
            <Flex>
              <Flex flexDirection="column" marginRight={2}>
                <Flex>
                  <Image bgSize="cover" borderRadius={6} src={photo === "" ? "" : photo} />
                </Flex>
                <Flex justifyContent="center">
                  {photo && (
                    <IconButton onClick={() => {
                      setfile(null);
                      setPhoto("");
                      setPhotoPreView("");
                    }}>
                      <AiFillDelete color="red" />
                    </IconButton>
                  )}
                </Flex>
              </Flex>
              {!photo && (
                <Flex flexDirection="column" alignItems="center">
                  <Upload
                    onChangeFile={(event) => {
                      getImage(event);
                      resizeImage(event);
                    }}
                    title="Upload de imagem"
                    subTitle="PNG, JPEG ..." />
                  <Text>Ou</Text>
                  <Button
                    variant='brand'
                    marginTop={2}
                    onClick={() =>
                      onOpen()}>
                    Abrir câmera
                  </Button>
                </Flex>
              )}
            </Flex>
          </Flex>
          <Flex>
            <Text
              color={textColor}
              fontSize='18px'
              fontWeight='700'
              marginBottom={10}
              marginTop={10}
              marginLeft={2}
              lineHeight='100%'>
              Apresentação Pessoal
            </Text>
          </Flex>
          <Flex display={{ md: 'flex' }}>
            <FormControl m={2}>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Nome<Text>*</Text>
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  fontSize='sm'
                  isRequired
                  mb='24px'
                  size='lg'
                  type={"text"}
                  variant='auth'
                />
              </InputGroup>
            </FormControl>
            <FormControl m={2}>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Gênero<Text>*</Text>
              </FormLabel>
              <Select
                mb='24px'
                size='lg'
                borderRadius={15}
                variant='outline'
                isRequired
                value={gender}
                onChange={(event) => setGender(event.target.value)}
                icon={<MdArrowDropDown />}>
                <option
                  fontSize='sm'
                  color={textColor}
                  value={""}
                >Selecione</option>
                {["Masculino", "Feminino"].map((item) => (
                  <option value={item}>{item}</option>
                ))}
              </Select>
            </FormControl>
            <FormControl m={2}>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Como gostaria de ser chamado
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  value={apelido}
                  onChange={(event) => setApelido(event.target.value)}
                  fontSize='sm'
                  mb='24px'
                  size='lg'
                  type={"text"}
                  variant='auth'
                />
              </InputGroup>
            </FormControl>
            <FormControl m={2}>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Data de nascimento<Text>*</Text>
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  value={age}
                  isRequired
                  onChange={(event) => {
                    const day = moment(moment().format("YYYY-MM-DD"));
                    const diff = day.diff(event.target.value, "day");
                    if (diff >= 0) {
                      setAge(event.target.value);
                    } else {
                      toast({
                        title: '',
                        description: "Data inválida.",
                        status: 'warning',
                        duration: 5000,
                        position: "top-right",
                        isClosable: true,
                      });
                    }
                  }}
                  fontSize='sm'
                  mb='24px'
                  size='lg'
                  type={"date"}
                  variant='auth'
                />
              </InputGroup>
            </FormControl>
          </Flex>
          <Flex>
            <Text
              color={textColor}
              fontSize='18px'
              fontWeight='700'
              marginBottom={10}
              marginTop={10}
              marginLeft={2}
              lineHeight='100%'>
              Contato do cadastrado
            </Text>
          </Flex>
          <Flex flexDirection="column">
            <FormControl mb="10">
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                WhatsApp
              </FormLabel>
              <PhoneInput
                placeholder=""
                value={whats}
                buttonStyle={{
                  borderTopLeftRadius: 16,
                  borderBottomLeftRadius: 16,
                }}
                inputStyle={{
                  flex: 1,
                  borderRadius: 16,
                  height: 50,
                  borderColor: '#E0E5F2',
                  width: '100%',
                }}
                country='br'
                onlyCountries={['br', 'py',]}
                onChange={setWhats} />
            </FormControl>
            <FormControl>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Contato sem WhatsApp
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  value={phone}
                  onChange={(event) => setPhone(event.target.value)}
                  fontSize='sm'
                  mb='24px'
                  size='lg'
                  type={"tel"}
                  variant='auth'
                />
              </InputGroup>
            </FormControl>
          </Flex>
          <Flex>
            <Text
              color={textColor}
              fontSize='18px'
              fontWeight='700'
              marginBottom={10}
              marginTop={10}
              marginLeft={2}
              lineHeight='100%'>
              Contato para recado
            </Text>
          </Flex>
          <Flex flexDirection="column">
            <Flex display={{ md: 'flex' }}>
              <FormControl m={2}>
                <FormLabel
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  display='flex'>
                  WhatsApp para recado
                </FormLabel>
                <PhoneInput
                  placeholder=""
                  value={whatsAppContact}
                  buttonStyle={{
                    borderTopLeftRadius: 16,
                    borderBottomLeftRadius: 16,
                  }}
                  inputStyle={{
                    flex: 1,
                    borderRadius: 16,
                    height: 50,
                    borderColor: '#E0E5F2',
                    width: '100%',
                  }}
                  country='br'
                  onlyCountries={['br', 'py',]}
                  onChange={setWhatsAppContact}
                />
              </FormControl>
              <FormControl m={2}>
                <FormLabel
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  display='flex'>
                  Com quem vamos falar?
                </FormLabel>
                <InputGroup size='md'>
                  <Input
                    value={whatsAppContactName}
                    onChange={(event) => setWhatsAppContactName(event.target.value)}
                    fontSize='sm'
                    mb='24px'
                    size='lg'
                    type={"text"}
                    variant='auth'
                  />
                </InputGroup>
              </FormControl>
            </Flex>
            <Flex display={{ md: 'flex' }}>
              <FormControl m={2}>
                <FormLabel
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  display='flex'>
                  Contato sem WhatsApp
                </FormLabel>
                <InputGroup size='md'>
                  <Input
                    value={phoneContact}
                    onChange={(event) => setPhoneContact(event.target.value)}

                    fontSize='sm'
                    mb='24px'
                    size='lg'
                    type={"tel"}
                    variant='auth'
                  />
                </InputGroup>
              </FormControl>
              <FormControl m={2}>
                <FormLabel
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  display='flex'>
                  Com quem vamos falar?
                </FormLabel>
                <InputGroup size='md'>
                  <Input
                    value={phoneContactName}
                    onChange={(event) => setPhoneContactName(event.target.value)}
                    fontSize='sm'
                    mb='24px'
                    size='lg'
                    type={"text"}
                    variant='auth'
                  />
                </InputGroup>
              </FormControl>
            </Flex>
          </Flex>

          {basic && (
            <>
              <Flex>
                <Text
                  color={textColor}
                  fontSize='18px'
                  fontWeight='700'
                  marginBottom={10}
                  marginTop={10}
                  marginLeft={2}
                  lineHeight='100%'>
                  Endereço
                </Text>
              </Flex>
              <Flex flexDirection={{ base: "column", md: "row" }}>
                <FormControl m={2}>
                  <FormLabel
                    ms='4px'
                    fontSize='sm'
                    fontWeight='500'
                    color={textColor}
                    display='flex'>
                    País
                  </FormLabel>
                  <Select
                    mb='24px'
                    size='md'
                    borderRadius={15}
                    variant='outline'
                    value={countryId}
                    onChange={(event) => {
                      setCountryId(event.target.value)
                      setStateId(null)
                      setCityId(null)
                      setNeighborhood(null)
                      setPublicPlace(null)
                      setDistrictId(null)
                    }}
                    icon={<MdArrowDropDown />}>
                    <option value={""}>Selecione</option>
                    {countries.map((item) => (
                      <option value={item.value}>{item.label}</option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl m={2}>
                  <FormLabel
                    ms='4px'
                    fontSize='sm'
                    fontWeight='500'
                    color={textColor}
                    display='flex'>
                    Estado
                  </FormLabel>
                  <CreatableSelect
                    isMulti={false}
                    isDisabled={!countryId}
                    value={stateId}
                    onChange={(newValue, _) => {
                      setStateId(newValue);
                      setNeighborhood(null)
                      setPublicPlace(null)
                      setCityId(null)
                    }}
                    menuPortalTarget={document.body}
                    placeholder='Selecione'
                    noOptionsMessage={() => 'Nenhuma opção disponível'}
                    formatCreateLabel={inputValue => `Criar "${inputValue}"`}
                    styles={{
                      menuPortal: base => ({ ...base, zIndex: 9999 }),
                      container: () => ({
                        flex: 1,
                        color: 'blue'
                      }),
                      control: (base, _) => ({
                        ...base,
                        borderRadius: '15px',
                        borderColor: '#EAEEF2',
                        borderWidth: 1,
                      }),
                      valueContainer: (base, state) => ({
                        ...base,
                        height: '45px',
                        padding: '0 6px',
                        borderRadius: 30,
                      }),
                      input: (base, state) => ({
                        ...base,
                        margin: '0px',
                        padding: '0px',
                      }),
                      menu: () => ({
                        zIndex: 2,
                        position: 'relative',
                        backgroundColor: '#F4F7FD'
                      })
                    }}
                    isClearable
                    options={states.filter((it) => it.countryId === countryId).map((state) => ({ value: state.value, label: state.text }))}
                  />
                </FormControl>
                <FormControl m={2}>
                  <FormLabel
                    ms='4px'
                    fontSize='sm'
                    fontWeight='500'
                    color={textColor}
                    display='flex'>
                    Cidade
                  </FormLabel>
                  <CreatableSelect
                    isMulti={false}
                    isLoading={isCitiesLoading}
                    isDisabled={!stateId}
                    value={cityId}
                    onChange={(newValue, _) => {
                      setCityId(newValue)
                      setNeighborhood(null)
                      setPublicPlace(null)
                      setDistrictId(null)
                      setPublicPlaces([])
                    }}
                    menuPortalTarget={document.body}
                    placeholder='Selecione'
                    noOptionsMessage={() => 'Nenhuma opção disponível'}
                    formatCreateLabel={inputValue => `Criar "${inputValue}"`}
                    styles={{
                      menuPortal: base => ({ ...base, zIndex: 9999 }),
                      container: () => ({
                        flex: 1,
                        color: 'blue'
                      }),
                      control: (base, _) => ({
                        ...base,
                        borderRadius: '15px',
                        borderColor: '#EAEEF2',
                        borderWidth: 1,
                      }),
                      valueContainer: (base, state) => ({
                        ...base,
                        height: '45px',
                        padding: '0 6px',
                        borderRadius: 30,
                      }),
                      input: (base, state) => ({
                        ...base,
                        margin: '0px',
                        padding: '0px',
                      }),
                      menu: () => ({
                        zIndex: 2,
                        position: 'relative',
                        backgroundColor: '#F4F7FD'
                      })
                    }}
                    isClearable
                    isValidNewOption={() => false}
                    options={cities}
                  />
                </FormControl>
              </Flex>
              <Flex flexDirection={{ base: "column", md: "row" }}>

                {!neighborhood && (
                  <FormControl m={2}>
                    <FormLabel
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      color={textColor}
                      display='flex'>
                      Distrito
                    </FormLabel>
                    <CreatableSelect
                      isMulti={false}
                      isLoading={isLoadingDistricts}
                      value={districtId}
                      isDisabled={!cityId}
                      isValidNewOption={() => false}
                      onChange={(newValue, _) => {
                        setDistrictId(newValue)
                        setNeighborhood(null);
                        setPublicPlace(null)
                        setPublicPlaces([])
                      }}
                      menuPortalTarget={document.body}
                      placeholder='Selecione'
                      noOptionsMessage={() => 'Nenhuma opção disponível'}
                      formatCreateLabel={inputValue => `Criar "${inputValue}"`}
                      styles={{
                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                        container: () => ({
                          flex: 1,
                          color: 'blue'
                        }),
                        control: (base, _) => ({
                          ...base,
                          borderRadius: '15px',
                          borderColor: '#EAEEF2',
                          borderWidth: 1,
                        }),
                        valueContainer: (base, state) => ({
                          ...base,
                          height: '45px',
                          padding: '0 6px',
                          borderRadius: 30,
                        }),
                        input: (base, state) => ({
                          ...base,
                          margin: '0px',
                          padding: '0px',
                        }),
                        menu: () => ({
                          zIndex: 2,
                          position: 'relative',
                          backgroundColor: '#F4F7FD'
                        })
                      }}
                      isClearable
                      options={districts.filter((district) => district.cityId === cityId?.value)}
                    />
                  </FormControl>
                )}

                {!districtId && (
                  <FormControl m={2}>
                    <FormLabel
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      color={textColor}
                      display='flex'>
                      Bairro
                    </FormLabel>
                    <CreatableSelect
                      isMulti={false}
                      isLoading={isNeighborhoodsLoading}
                      value={neighborhood}
                      isDisabled={!cityId}
                      isValidNewOption={() => false}
                      onChange={(newValue, _) => {
                        setNeighborhood(newValue);
                        setPublicPlace(null)
                        setDistrictId(null)
                        setPublicPlaces([])
                      }}
                      menuPortalTarget={document.body}
                      placeholder='Selecione'
                      noOptionsMessage={() => 'Nenhuma opção disponível'}
                      formatCreateLabel={inputValue => `Criar "${inputValue}"`}
                      styles={{
                        menuPortal: base => ({ ...base, zIndex: 9999, textTransform: "capitalize", }),
                        container: () => ({
                          flex: 1,
                          color: 'blue',
                          textTransform: "capitalize",
                        }),
                        control: (base, _) => ({
                          ...base,
                          borderRadius: '15px',
                          borderColor: '#EAEEF2',
                          borderWidth: 1,
                          textTransform: "capitalize",
                        }),
                        valueContainer: (base, state) => ({
                          ...base,
                          height: '45px',
                          padding: '0 6px',
                          borderRadius: 30,
                          textTransform: "capitalize",
                        }),
                        input: (base, state) => ({
                          ...base,
                          margin: '0px',
                          padding: '0px',
                          textTransform: "capitalize",
                        }),
                        menu: () => ({
                          zIndex: 2,
                          position: 'relative',
                          backgroundColor: '#F4F7FD',
                          textTransform: "capitalize",
                        })
                      }}
                      isClearable
                      options={neighborhoods.filter((neighborhood) => neighborhood.cityId === cityId?.value)}
                    />
                  </FormControl>
                )}

                <FormControl m={2}>
                  <FormLabel
                    ms='4px'
                    fontSize='sm'
                    fontWeight='500'
                    color={textColor}
                    display='flex'>
                    Rua
                  </FormLabel>
                  <CreatableSelect
                    isMulti={false}
                    isLoading={isPlaceLoading}
                    isDisabled={neighborhood === null ? true : false}
                    value={publicPlace}
                    onChange={(newValue, _) => {
                      setPublicPlace(newValue);
                    }}
                    menuPortalTarget={document.body}
                    placeholder='Selecione'
                    noOptionsMessage={() => 'Nenhuma opção disponível'}
                    formatCreateLabel={inputValue => `Criar "${inputValue}"`}
                    styles={{
                      menuPortal: base => ({ ...base, zIndex: 9999 }),
                      container: (base, _) => ({
                        ...base,
                        flex: 1,
                        width: '100%'
                      }),
                      control: (base, _) => ({
                        ...base,
                        borderRadius: '15px',
                        borderColor: '#EAEEF2',
                        borderWidth: 1,
                      }),
                      valueContainer: (base, state) => ({
                        ...base,
                        height: '45px',
                        padding: '0 6px',
                        borderRadius: 30,
                      }),
                      input: (base, state) => ({
                        ...base,
                        margin: '0px',
                        padding: '0px',
                      }),
                      menu: () => ({
                        zIndex: 2,
                        position: 'relative',
                        backgroundColor: '#F4F7FD'
                      })
                    }}
                    isClearable
                    options={publicPlaces}
                  />
                </FormControl>
                <FormControl m={2}>
                  <FormLabel
                    ms='4px'
                    fontSize='sm'
                    fontWeight='500'
                    color={textColor}
                    display='flex'>
                    Número
                  </FormLabel>
                  <InputGroup size='md'>
                    <Input
                      value={houseNumber}
                      onChange={(event) => setHouseNumber(event.target.value)}
                      fontSize='sm'
                      mb='24px'
                      size='md'
                      type={"number"}
                      variant='auth'
                    />
                  </InputGroup>
                </FormControl>
              </Flex>
              <Flex flexDirection="row">
                <FormControl m={2}>
                  <FormLabel
                    ms='4px'
                    fontSize='sm'
                    fontWeight='500'
                    color={textColor}
                    display='flex'>
                    Ponto de referência
                  </FormLabel>
                  <InputGroup size='md'>
                    <Input
                      value={addressReference}
                      onChange={(event) => setAddressReference(event.target.value)}
                      fontSize='sm'
                      mb='24px'
                      size='lg'
                      type="text"
                      variant='auth'
                    />
                  </InputGroup>
                </FormControl>
                <FormControl m={2}>
                  <FormLabel
                    ms='4px'
                    fontSize='sm'
                    fontWeight='500'
                    color={textColor}
                    display='flex'>
                    Link da localização
                  </FormLabel>
                  <InputGroup size='md'>
                    <Input
                      value={localization}
                      onChange={(event) => setLocalization(event.target.value)}
                      fontSize='sm'
                      mb='24px'
                      size='lg'
                      type="text"
                      variant='auth'
                    />
                  </InputGroup>
                </FormControl>
              </Flex>
            </>
          )}

          {basic && (
            <>
              <Flex>
                <Text
                  color={textColor}
                  fontSize='18px'
                  fontWeight='700'
                  marginBottom={10}
                  marginTop={10}
                  marginLeft={2}
                  lineHeight='100%'>
                  Documento pessoal
                </Text>
              </Flex>
              <Flex flexDirection={{ base: "column", md: "row" }}>
                <FormControl m={2}>
                  <FormLabel
                    ms='4px'
                    fontSize='sm'
                    fontWeight='500'
                    color={textColor}
                    display='flex'>
                    RG
                  </FormLabel>
                  <InputGroup size='md'>
                    <InputMask
                      value={rg}
                      onChange={(event) => {
                        setRg(event.target.value.replace(/\D/g, ''));
                      }}
                      fontSize='sm'
                      mb='24px'
                      style={
                        {
                          height: 46,
                          paddingLeft: 12,
                          borderRadius: 14,
                          borderColor: "#E0E5F2",
                          borderWidth: 1,
                          width: "100%"
                        }}
                      size='lg'
                      type="tel"
                      mask="**.***.***-*"

                    />
                  </InputGroup>
                </FormControl>
                <FormControl m={2}>
                  <FormLabel
                    ms='4px'
                    fontSize='sm'
                    fontWeight='500'
                    color={textColor}
                    display='flex'>
                    Órgão expedidor
                  </FormLabel>
                  <InputGroup size='md'>
                    <Input
                      value={rgOrgao}
                      onChange={(event) => {
                        setRgOrgao(event.target.value.replace(/[^a-zA-Z]/g, ''));
                      }}
                      fontSize='sm'
                      mb='24px'
                      size='lg'
                      type="tel"
                      variant='auth'
                    />
                  </InputGroup>
                </FormControl>
                <FormControl m={2}>
                  <FormLabel
                    ms='4px'
                    fontSize='sm'
                    fontWeight='500'
                    color={textColor}
                    display='flex'>
                    CPF
                  </FormLabel>
                  <InputGroup size='md'>
                    <InputMask
                      value={cpf}
                      onChange={(event) => setCpf(event.target.value.replace(/\D/g, ''))}
                      fontSize='sm'
                      mb='24px'
                      type="tel"
                      style={
                        {
                          height: 46,
                          paddingLeft: 12,
                          borderRadius: 14,
                          borderColor: "#E0E5F2",
                          borderWidth: 1,
                          width: "100%"
                        }}
                      size='lg'
                      mask="***.***.***-**"
                      variant='auth'
                    />
                  </InputGroup>
                </FormControl>
                <FormControl m={2}>
                  <FormLabel
                    ms='4px'
                    fontSize='sm'
                    fontWeight='500'
                    color={textColor}
                    display='flex'>
                    CNH
                  </FormLabel>
                  <InputGroup size='md'>
                    <Input
                      value={cnh}
                      onChange={(event) => setCnh(event.target.value)}
                      fontSize='sm'
                      mb='24px'
                      size='lg'
                      type={"number"}
                      variant='auth'
                    />
                  </InputGroup>
                </FormControl>
                <FormControl m={2}>
                  <FormLabel
                    ms='4px'
                    fontSize='sm'
                    fontWeight='500'
                    color={textColor}
                    display='flex'>
                    Número do SUS
                  </FormLabel>
                  <InputGroup size='md'>
                    <InputMask
                      value={susNumero}
                      onChange={(event) => {
                        const value = event.target.value.replace(/[^0-9]/g, '');
                        setSusNumero(value);
                      }}
                      fontSize='sm'
                      mb='24px'
                      style={
                        {
                          height: 46,
                          paddingLeft: 12,
                          borderRadius: 14,
                          borderColor: "#E0E5F2",
                          borderWidth: 1,
                          width: "100%"
                        }}
                      size='lg'
                      type="tel"
                      mask={"*** **** **** ****"}
                      variant='auth'
                    />
                  </InputGroup>
                </FormControl>
              </Flex>
            </>
          )}
          {basic && (
            <>
              <Flex>
                <Text
                  color={textColor}
                  fontSize='18px'
                  fontWeight='700'
                  marginBottom={10}
                  marginTop={10}
                  marginLeft={2}
                  lineHeight='100%'>
                  Título Eleitoral
                </Text>
              </Flex>
              <Flex flexDirection={{ base: "column", md: "row" }}>
                <FormControl m={2}>
                  <FormLabel
                    ms='4px'
                    fontSize='sm'
                    fontWeight='500'
                    color={textColor}
                    display='flex'>
                    Número do título
                  </FormLabel>
                  <InputGroup size='md'>
                    <Input
                      value={tituto}
                      onChange={(event) => {
                        const outString = event.target.value.replace(
                          /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
                          ""
                        );
                        setTituto(outString.replace(/\D/g, ""));
                      }
                      }
                      fontSize='sm'
                      mb='24px'
                      size='lg'
                      type={"tel"}
                      variant='auth'
                    />
                  </InputGroup>
                </FormControl>
                <FormControl m={2}>
                  <FormLabel
                    ms='4px'
                    fontSize='sm'
                    fontWeight='500'
                    color={textColor}
                    display='flex'>
                    Zona
                  </FormLabel>
                  <InputGroup size='md'>
                    <Input
                      value={zona}
                      onChange={(event) => setZona(event.target.value.replace(/[^0-9]/g, ''))}
                      fontSize='sm'
                      mb='24px'
                      size='lg'
                      type={"tel"}
                      variant='auth'
                    />
                  </InputGroup>
                </FormControl>
                <FormControl m={2}>
                  <FormLabel
                    ms='4px'
                    fontSize='sm'
                    fontWeight='500'
                    color={textColor}
                    display='flex'>
                    Sessão
                  </FormLabel>
                  <InputGroup size='md'>
                    <Input
                      value={section}
                      onChange={(event) => setSection(event.target.value.replace(/[^0-9]/g, ''))}
                      fontSize='sm'
                      mb='24px'
                      size='lg'
                      type={"tel"}
                      variant='auth'
                    />
                  </InputGroup>
                </FormControl>
              </Flex>
              <Flex>
                <Text
                  color={textColor}
                  fontSize='18px'
                  fontWeight='700'
                  marginBottom={10}
                  marginTop={10}
                  marginLeft={2}
                  lineHeight='100%'>
                  Redes sociais
                </Text>
              </Flex>
              <Flex flexDirection={{ base: "column", md: "row" }}>
                <Flex flexDirection="column" flex={1} m={2}>
                  <FormControl>
                    <FormLabel
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      color={textColor}
                      display='flex'>
                      Link do Facebook
                    </FormLabel>
                    <InputGroup size='md'>
                      <Input
                        value={facebook}
                        onChange={(event) => setFacebook(event.target.value)}
                        fontSize='sm'
                        mb='24px'
                        size='lg'
                        type={"text"}
                        variant='auth'
                      />
                    </InputGroup>
                  </FormControl>
                  <Checkbox
                    checked={isFace || false}
                    onChange={() => setIsFace(!isFace)}
                  >Não tem Facebook</Checkbox>
                </Flex>
                <Flex flexDirection="column" flex={1} m={2}>
                  <FormControl>
                    <FormLabel
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      color={textColor}
                      display='flex'>
                      Link do Instagram
                    </FormLabel>
                    <InputGroup size='md'>
                      <Input
                        value={insta}
                        onChange={(event) => setInsta(event.target.value)}
                        fontSize='sm'
                        mb='24px'
                        size='lg'
                        type={"text"}
                        variant='auth'
                      />
                    </InputGroup>
                  </FormControl>
                  <Checkbox
                    checked={isInst || false}
                    onChange={() => setIsInst(!isInst)}
                  >Não tem Facebook</Checkbox>
                </Flex>
              </Flex>
            </>
          )}
          <Flex>
            <Text
              color={textColor}
              fontSize='18px'
              fontWeight='700'
              marginBottom={10}
              marginTop={10}
              marginLeft={2}
              lineHeight='100%'>
              Outros
            </Text>
          </Flex>
          <Flex display={{ md: 'flex' }}>
            <FormControl m={2}>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Foi indicado por
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  value={indication}
                  onChange={(event) => setIndication(event.target.value)}
                  fontSize='sm'
                  mb='24px'
                  size='lg'
                  type={"text"}
                  variant='auth'
                />
              </InputGroup>
            </FormControl>
            <FormControl m={2}>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Ocupação
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  value={occupation}
                  onChange={(event) => setOccupation(event.target.value)}
                  fontSize='sm'
                  mb='24px'
                  size='lg'
                  type={"text"}
                  variant='auth'
                />
              </InputGroup>
            </FormControl>
            <FormControl m={2}>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                E-mail
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  fontSize='sm'
                  mb='24px'
                  size='lg'
                  type={"email"}
                  variant='auth'
                />
              </InputGroup>
            </FormControl>
          </Flex>
          <Flex>
            <FormControl m={2}>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Categoria
              </FormLabel>

              <SelectChakra
                isMulti
                placeholder=""
                onChange={(event) => setCategorie(event)}
                colorScheme="green"
                value={categorie}
                options={gategories}
              />
            </FormControl>
          </Flex>
          <br />
          <Flex>
            <FormControl m={2}>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Observação do cadastrado
              </FormLabel>
              <InputGroup size='md'>
                <Textarea
                  height="350px"
                  value={description}
                  onChange={(event) =>
                    setDescription(event.target.value)
                  }
                  placeholder="Observação do cadastrado"
                />
              </InputGroup>
            </FormControl>
          </Flex>
          <Flex marginTop={10} justifyContent="flex-end">
            <Button
              isLoading={loading}
              w="60"
              type="submit"
              variant='brand'
            >
              Salvar
            </Button>
          </Flex>
        </Form>
        <AlertDialog
          size="4xl"
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={() => {
            setOpen(false);
            setPhotoPreView("");
            setPhoto("");
            setfile(null);
            onClose();
          }}
          isCentered
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                Capturar imagem
              </AlertDialogHeader>
              <AlertDialogBody>
                {photoPreView ? (
                  <Image bgSize="cover" src={photoPreView} />
                ) : (
                  <div style={{ width: 300, minHeight: 500 }}>
                    <WebcamCapture />
                  </div>
                )}
                {photo && (
                  <Button
                    onClick={() => {
                      setPhoto(photoPreView);
                      setOpen(false);
                    }}
                  />
                )}
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button
                  colorScheme="red"
                  marginRight={2}
                  onClick={() => {
                    onClose();
                    setPhotoPreView("");
                    setPhoto("");
                    setfile(null);
                  }}
                >
                  Fechar
                </Button>
                {photo && (
                  <Button
                    marginRight={2}
                    colorScheme="red"
                    onClick={() => {
                      setPhotoPreView("");
                      setPhoto("");
                    }}
                  >
                    Capturar outra foto
                  </Button>
                )}
                <Button
                  marginRight={2}
                  colorScheme="messenger"
                  onClick={() => {
                    handleClick();
                  }}
                >
                  Alterar câmera
                </Button>
                {photo && (
                  <Button
                    colorScheme="green"
                    onClick={() => {
                      setPhoto(photoPreView);
                      onClose();
                    }}
                  >
                    Selecionar está imagem
                  </Button>
                )}
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>

      </Flex>
    </Box>
  );
};

export default RegistrationPeople;
