/* eslint-disable react-hooks/rules-of-hooks */
import moment from "moment";
import "moment/locale/pt-br";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Form } from "semantic-ui-react";
import {
  Select as SelectChakra,
} from "chakra-react-select";
import {
  AttachmentIcon,
  AddIcon,
  ArrowBackIcon,
} from "@chakra-ui/icons";
import {
  Tab,
  Image,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Button,
  IconButton,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
  Textarea,
  Select,
  useToast,
  Divider,
  Spinner,
  Box,
  Flex,
  useColorModeValue,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";

import apiAccount from "../../services/urls/account";
import apiSituationCategory from "../../services/urls/category_situation";
import api from "../../services/urls/user";
import { mask } from "../../utils/mask";
import CardList from "./card_list";
import SideBar from "components/@sidebar";
import PingStatus from "./ping_status";
import HistoriListView from "./historic_list_view";
import CardDemandView from "./card_demand_view";
import LoadPageView from "./load_page_view";
import ImagePreView from "./imgaePreView";

const ShowDemandDetail = () => {
  const toast = useToast();
  const shoulRequest = useRef(true);
  let inputRef = useRef();
  const cancelRef = React.useRef();

  const history = useNavigate();
  const { userDemandId } = useParams();
  const stateParams = useLocation().state;

  const { _id, name } = useSelector(
    (state) => state.user
  );

  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();
  const {
    isOpen: isOpenModalNewHistoric,
    onOpen: onOpenModalNewHistoric,
    onClose: onCloseModalNewHistoric,
  } = useDisclosure();

  const {
    isOpen: isOpenModalDemand,
    onOpen: onOpenModalDemand,
    onClose: onCloseModalDemand,
  } = useDisclosure();


  let [userData, setUserData] = useState(null)
  const [historiIdDemand, setHistoricIdDemand] = useState("")
  const textColor = useColorModeValue("navy.700", "white");
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [historicDemands, setHistoricDemands] = useState([]);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingNew, setLoadingNew] = useState(false);
  const [categories, setCategoreis] = useState([]);
  const [categoriesSelected, setCategoriesSelected] = useState([]);
  const [file, setFile] = useState([]);
  const [fileBase64, setFileBase64] = useState([]);
  const [demandDescription, setDemandDescription] = useState("");
  const [demandDescriptionOld, setDemandDescriptionOld] = useState("");
  const [userDemand, setUserDemand] = useState(null);

  const [demandId, setDemandId] = useState("");
  const [demandHistoric, setDemandHistoric] = useState("");
  const [demandStatus, setDemandStatus] = useState("");
  const [demandStatusSituation, setDemandStatusSituation] = useState("");
  const [responsible, setResponsible] = useState("");

  const [situations, setSituations] = useState([]);
  const [status, setStatus] = useState([]);
  const [accounts, setAccounts] = useState([]);

  const [situationLabel, setSituationLabel] = useState("")
  const [demandLabel, setDemandLabel] = useState("");

  const [hasSendfile, setHasSendFile] = useState("");
  const [hasSendMidia, setHasSendMidia] = useState("");
  const [hasReturn, setHasReturn] = useState("");

  useEffect(() => {
    if (stateParams !== null) {
      setUserData(stateParams)
    }
  }, [stateParams]);


  const requestDemandByUserId = useCallback(async () => {
    const { data } = (await api.GetDemanById(stateParams?.userDemandId || userDemandId)).data;
    setUserDemand(data);
  }, [stateParams, userDemandId]);

  const requestHistoricFiles = useCallback(async () => {
    setLoadingFiles(true);
    const { data } = (await api.GetHistoricByDemand(stateParams?.userDemandId || userDemandId, 'onlyFiles')).data;
    setFiles(data);
    setLoadingFiles(false);
  }, [stateParams, userDemandId]);

  const requestHistoric = useCallback(async () => {
    const { data } = (await api.GetHistoricByDemand(stateParams?.userDemandId || userDemandId)).data;
    setHistoricDemands(data.reverse());
  }, [stateParams, userDemandId]);


  const submitNewHistoric = async () => {
    try {
      if (!demandStatus && demandLabel !== "Resolvida") {
        toast({
          title: "",
          description: "Informe a situação da demanda",
          status: "warning",
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (demandLabel === "Resolvida") {
        if (hasSendMidia === "Sim" || hasSendfile === "Sim") {
          if (file.length === 0) {
            toast({
              title: "",
              description: "Insira os arquivos",
              status: "warning",
              duration: 5000,
              position: "top-right",
              isClosable: true,
            });
            return;
          }
        }
        else if (!hasSendMidia || !hasSendfile || !hasReturn) {
          toast({
            title: "",
            description: "Campo obrigatório não informado!",
            status: "warning",
            duration: 5000,
            position: "top-right",
            isClosable: true,
          });
          return;
        }
      }
      if (!demandStatusSituation) {
        toast({
          title: "",
          description: "Informe a situação",
          status: "warning",
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!demandHistoric) {
        toast({
          title: "",
          description: "Informe o histórico",
          status: "warning",
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }

      setLoadingNew(true);

      const formData = new FormData();
      file.map((item) => {
        formData.append(`file`, item.path);
        return item;
      });

      const situationSelected = situations.find((it) => it.value === demandStatusSituation);

      formData.append("created", moment().format());
      formData.append("accountId", _id);
      formData.append("accountName", name);
      formData.append("day", moment().format("DD/MM/YYYY"));
      formData.append("hour", moment().format("HH:mm:ss"));
      formData.append("userId", userData?.userId);
      formData.append("user", userData?.userId);
      formData.append("userName", userData?.name);
      formData.append("dateTime", moment().format());
      formData.append("demandId", demandId);
      formData.append("demandHistoric", demandHistoric);
      formData.append("demandStatus", demandStatus);
      formData.append("demandStatusSituationId", situationSelected.value);
      formData.append("demandStatusSituationName", situationSelected.text);
      formData.append("responsible", responsible === "0" ? undefined : responsible);
      formData.append("systemUser", _id);
      formData.append("registeredPerson", userDemand.registeredPerson._id);
      formData.append("sendMidia", hasSendMidia);
      formData.append("sendDoc", hasSendfile);
      formData.append("hasReturn", hasReturn);
      formData.append("demandDescription", demandDescription);
      formData.append("demandDescriptionOld", demandDescriptionOld);

      await api.createHistoricDemand(formData);

      setDemandId("");
      setHasReturn("");
      setHasSendMidia("");
      setHasSendFile("");
      setLoadingNew(false);
      setDemandStatus("");
      setDemandStatusSituation("");
      setDemandHistoric("");
      setDemandLabel("");
      setDemandDescription("");
      setDemandDescriptionOld("");

      setSituationLabel("");
      setFile([]);
      setFileBase64([]);
      toast({
        title: "Parabéns",
        description: "Histórico da demanda adicionanda com sucesso",
        status: "success",
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
      Promise.all([
        requestHistoric(),
        requestDemandByUserId(),
      ]);

      onCloseModalNewHistoric();
    } catch (error) {
      setLoadingNew(false);
      toast({
        title: "Erro",
        description: "Algo deu errado, tente novamente",
        status: "error",
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const updateDemandOriginApi = async () => {
    try {

      if (!demandDescription) {
        toast({
          title: "",
          description: "Demanda é obrigatório!",
          status: "warning",
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      setLoadingNew(true);

      await api.updateDemandUser(userDemand._id, {
        categories: categoriesSelected.length > 0 ? categoriesSelected.map((item) => item.value) : undefined,
        responsible: responsible === "0" ? null : responsible,
        demandDescription: demandDescription,
        accountId: _id,
        accountName: name,
        day: moment().format("DD/MM/YYYY"),
        hour: moment().format("HH:mm:ss"),
        userAction: "Editou",
        userId: userData?._id,
        userName: userData?.name,
        dateTime: moment().format(),
      });

      await Promise.all([requestDemandByUserId(), requestHistoric()])

      setDemandDescription("");
      setResponsible("");
      setCategoriesSelected([]);
      onCloseModalDemand();

      onCloseModalDemand();
      setLoadingNew(false);
      toast({
        title: "Parabéns",
        description: "Demanda atualizada com sucesso",
        status: "success",
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    } catch (error) {
      setLoadingNew(false);
      toast({
        title: "Erro",
        description: "Erro ao atualizar demanda",
        status: "error",
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const requestCategories = useCallback(async () => {
    try {
      const response = await apiSituationCategory.GetCategoriesSimple();
      setCategoreis(
        response.data.data.map((item) => {
          return {
            label: item.name,
            value: item._id,
          };
        })
      );
    } catch (error) {
      toast({
        title: "Erro",
        description: "Erro ao listar as categorias",
        status: "error",
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  }, [toast])

  const requestSituation = useCallback(async () => {
    try {
      const response = await api.getSituationsActive();
      setStatus(
        response.data.data.map((item) => {
          return {
            key: item._id,
            text: `${item.name}`,
            value: item._id,
            situations: item.situations,
          };
        })
      );
    } catch (error) {
      toast({
        title: "Erro",
        description: "Erro ao listar as situações",
        status: "error",
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  }, [toast]);

  const requestAccounts = useCallback(async () => {
    try {
      const response = await apiAccount.GetAllAccountsActive();
      const newAccounts = [
        {
          key: "0",
          text: "Sem responsável",
          value: "0",
        },
      ];
      setAccounts(
        newAccounts.concat(
          response.data.data.map((item) => {
            return {
              key: item._id,
              text: item.name,
              value: item._id,
            };
          })
        )
      );
    } catch (error) {
      toast({
        title: "Erro",
        description: "Erro ao listar as usuários",
        status: "error",
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  }, [toast]);

  const updateDemandHisotric = async () => {
    try {
      if (!demandHistoric) {
        return;
      }

      setLoadingSave(true);

      await api.updateHistoricDemand(
        historiIdDemand,
        {
          demandHistoric,
          registeredPerson: userDemand.registeredPerson._id,
        }
      );

      setLoadingSave(false);
      setDemandHistoric("");
      setHistoricIdDemand("");

      toast({
        title: "Parabéns",
        description: "Informação atualizada com sucesso",
        status: "success",
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
      requestHistoric();
      onCloseModal();

    } catch (error) {
      setLoadingSave(false);
      toast({
        title: "Erro",
        description: "Algo deu errado, tente novamente",
        status: "error",
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const removeFileById = (id) => {
    const historicFiles = files;
    for (let item of historicFiles) {
      item.attachment = item.attachment.filter(attachment => attachment._id !== id);
    }
    setFiles(historicFiles.filter((item) => item.attachment.length > 0));
  }

  useEffect(() => {
    if (shoulRequest.current) {
      shoulRequest.current = false;
      setLoading(true);
      Promise.all([
        requestDemandByUserId(),
        requestHistoric(),
        requestSituation(),
        requestCategories(),
        requestAccounts(),
      ]).then(() => setLoading(false));
    }
  }, [
    requestHistoric,
    requestSituation,
    requestCategories,
    requestAccounts,
    requestDemandByUserId]);

  const toBase64 = (file, id) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let typeFile = "";

        if (file.type.includes("image")) {
          typeFile = "image";
        }
        if (file.type.includes("audio")) {
          typeFile = "audio";
        }
        if (file.type.includes("video")) {
          typeFile = "video";
        }
        if (file.type.includes("pdf")) {
          typeFile = "pdf";
        }
        const isFile = {
          name: file.name,
          id,
          type: typeFile,
          url: reader.result,
        };

        setFileBase64((oldArray) => [...oldArray, isFile]);
        resolve();
      };
      reader.onerror = reject;
    });

  const handleFileChange = (e) => {
    try {
      if (e.target.files) {
        const files = Array.from(e.target.files).map((item) => {
          const id = Math.floor(Math.random() * 1000000000000);
          toBase64(item, id);
          return {
            id,
            path: item,
          };
        });
        setFile((oldArray) => [...oldArray, ...files]);
      }
    } catch (_) { }
  };

  const removeFileList = (it) => {
    setFile(file.filter((item) => item.id !== it));
    setFileBase64(fileBase64.filter((item) => item.id !== it));
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SideBar />
      <Flex
        boxShadow="2xl"
        margin={4}
        direction="column"
        backgroundColor="white"
        borderRadius={8}
      >
        <Flex>
          <IconButton
            marginLeft={10}
            marginTop={4}
            onClick={() => history(-1)}
            icon={<ArrowBackIcon color="blue.500" />}
          />
        </Flex>

        {loading ? <LoadPageView /> : (
          <>
            <Tabs
              marginLeft={5}
              marginRight={5}
              marginTop={2}
              isManual
              variant="soft-rounded"
            >
              <TabList>
                <Tab onClick={() => requestHistoric()} marginLeft={5}>Demanda</Tab>
                <Tab onClick={async () => requestHistoricFiles()}>Feed com anexo</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Flex flexDirection="column">
                    <Flex flexDirection="column">
                      <Flex
                        borderWidth="1px"
                        borderRadius="lg"
                        w={{ sm: '100%', md: '100%' }}
                        height={{ sm: '100%', md: '18rem' }}
                        direction={{ base: 'column', md: 'row' }}
                        bg={useColorModeValue('white', 'gray.900')}
                        boxShadow={'2xl'}
                        padding={4}>
                        <Box minW="72" marginRight={4}>
                          <Image
                            flex={0.1}
                            marginRight={4}
                            boxSize="100%"
                            borderTopLeftRadius={6}
                            borderTopRightRadius={6}
                            objectFit="cover"
                            src={
                              userDemand?.registeredPerson?.photo ||
                              "https://react.semantic-ui.com/images/wireframe/image.png"
                            }
                          />
                        </Box>
                        <Flex flexDirection="column">
                          <Flex marginBottom={1} marginTop={{ base: '4', md: '0' }}>
                            <Text
                              fontSize='md'
                              fontWeight='600'
                              color={textColor}>
                              Nome:
                            </Text>
                            <Text fontSize='md' marginLeft={2}>{userDemand?.registeredPerson.name}</Text>
                          </Flex>
                          <Text

                            fontWeight='600'
                            fontSize='md'
                            marginRight={2}
                            color={textColor}>
                            Contato:
                          </Text>
                          <Flex>
                            <Text
                              fontWeight='500'
                              fontSize='md'
                              marginRight={2}
                              marginLeft={3}
                              color={textColor}>
                              WhatsApp:
                            </Text>
                            {userDemand?.registeredPerson.whatsApp && mask(userDemand?.registeredPerson?.whatsApp) ? (
                              <a
                                style={{ color: "black" }}
                                target="_blank"
                                href={`whatsapp://send?phone=${userDemand?.registeredPerson?.whatsApp}`}
                                rel="noreferrer"
                              >
                                <Text
                                  fontSize='md'
                                  color={textColor}>
                                  {mask(userDemand?.registeredPerson?.whatsApp || " Não informado")}
                                </Text>
                              </a>
                            ) : (
                              " Não informado"
                            )}
                          </Flex>
                          <Flex>
                            <Text
                              fontWeight='500'
                              fontSize='md'
                              marginRight={2}
                              marginLeft={3}
                              color={textColor}>
                              Contato sem WhatsApp:
                            </Text>
                            {userDemand?.registeredPerson?.phone ? (
                              <Text
                                fontSize='md'
                                color={textColor}>
                                {userDemand?.registeredPerson?.phone || " Não informado"}
                              </Text>
                            ) : (
                              " Não informado"
                            )}
                          </Flex>
                          <Text
                            marginTop={3}
                            fontWeight='600'
                            fontSize='md'
                            marginRight={2}
                            color={textColor}>
                            Contato para recado:
                          </Text>
                          <Flex>
                            <Text
                              fontWeight='500'
                              fontSize='md'
                              marginRight={2}
                              marginLeft={3}
                              color={textColor}>
                              WhatsApp para recado:
                            </Text>
                            <a
                              style={{ color: "black" }}
                              target="_blank"
                              href={`whatsapp://send?phone=${userDemand?.registeredPerson?.whatsAppContact}`}
                              rel="noreferrer"
                            >
                              <Text
                                fontSize='md'
                                color={textColor}>
                                {userDemand?.registeredPerson?.whatsAppContact
                                  ? `${mask(userDemand?.registeredPerson.whatsAppContact)}`
                                  : "Não informado"} {userDemand?.registeredPerson.whatsAppContactName ? `(${userDemand?.registeredPerson.whatsAppContactName})` : ""}
                              </Text>
                            </a>
                          </Flex>
                          <Flex>
                            <Text
                              fontWeight='500'
                              fontSize='md'
                              marginLeft={3}
                              color={textColor}>
                              Contato sem WhatsApp:
                            </Text>
                            <Text
                              fontSize='md'
                              marginLeft={2}
                              color={textColor}>
                              {userDemand?.registeredPerson?.phoneContact || " Não informado"} {userDemand?.registeredPerson?.phoneContactName ? `(${userDemand?.registeredPerson?.phoneContactName})` : ""}
                            </Text>
                          </Flex>
                          <Button
                            marginTop={5}
                            variant="outline"
                            colorScheme="blue"
                            onClick={() => {
                              history(
                                "/visualizar-cadastrado",
                                {
                                  state: {
                                    userId: userDemand?.registeredPerson._id,
                                  }
                                },
                              );
                            }}
                          >
                            Visualizar cadastro
                          </Button>
                        </Flex>
                      </Flex>
                    </Flex>
                    <Divider />
                    <>
                      <br />
                      <CardDemandView
                        userDemand={userDemand}
                        openEdit={() => {
                          const categoriesPayload = [];
                          setDemandDescription(userDemand.demandDescription || "");
                          setResponsible(userDemand.responsible._id);
                          setDemandStatus(userDemand.demandStatus._id);
                          setDemandStatusSituation(userDemand.demandStatusSituation._id);

                          categories?.map((item) => {
                            userDemand?.categories.map((it) => {
                              if (item.value === it._id) {
                                categoriesPayload.push({
                                  label: item.label,
                                  value: item.value,
                                });
                              }
                              return it;
                            })
                            return item;
                          });
                          setCategoriesSelected(categoriesPayload);
                          onOpenModalDemand();
                        }} />
                      <PingStatus />
                      <Flex
                        direction={{ base: 'column', md: 'row' }}
                        marginTop={10}
                        justifyContent="space-between"
                        alignItems={{ base: 'center', md: 'flex-start' }}
                      >
                        <Text
                          fontSize='md'
                          fontWeight='500'
                          color={textColor}
                          marginBottom={{ sm: '8px', md: '0px' }}
                        >
                          HISTÓRICO DA DEMANDA
                        </Text>
                        <Flex
                          direction={{ base: 'column', md: 'row' }}
                          alignItems={{ base: 'center', md: 'flex-start' }}
                        >
                          <Button
                            leftIcon={<AddIcon />}
                            colorScheme="blue"
                            onClick={() => {
                              if (userDemand?.demandStatus.name === "Resolvida") {
                                setHasReturn(userDemand.sendDoc);
                                setHasSendFile(userDemand.sendDoc);
                                setHasSendMidia(userDemand.sendMidia);
                              }
                              setDemandId(userDemand?._id);
                              setDemandDescription(userDemand?.demandDescription);
                              setDemandDescriptionOld(userDemand?.demandDescription);
                              setDemandStatus(userDemand?.demandStatus._id);
                              setDemandLabel(userDemand?.demandStatus.name);

                              const newStatus = status.filter(
                                (item) => item.value === userDemand?.demandStatus._id
                              )[0];

                              if (userDemand?.demandStatus.name !== "Resolvida") {
                                if (userDemand?.demandStatusSituation._id) {
                                  const demandStatusSituationId = newStatus?.situations.find((item) => item._id === userDemand?.demandStatusSituation._id)._id;
                                  setDemandStatusSituation(demandStatusSituationId)
                                }
                              }

                              setSituations(
                                newStatus?.situations.filter((item) => item.name !== "Resolvida").map((item) => {
                                  return {
                                    key: item._id,
                                    text: item.name,
                                    value: item._id,
                                  };
                                }) || []
                              );
                              setResponsible(userDemand?.responsible?._id || "");
                              onOpenModalNewHistoric();
                            }}
                          >
                            Novo histórico
                          </Button>
                        </Flex>
                      </Flex>
                      <br />
                      <HistoriListView
                        historicDemands={historicDemands}
                        onOpenModalToEdit={(value) => {
                          setDemandHistoric(value?.demandHistoric);
                          setHistoricIdDemand(value._id);
                          onOpenModal();
                        }}
                      />
                    </>
                    <div style={{ marginBottom: 10 }} />
                  </Flex>
                </TabPanel>
                <TabPanel >
                  {loadingFiles ? (
                    <Box
                      display="flex"
                      justifyContent="center"

                      marginTop="10"
                      height="container.lg"
                    >
                      <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        colorScheme="blue"
                        size="lg"
                      />
                    </Box>
                  ) : (
                    <div>
                      {files.length > 0 ? (
                        <>
                          {files.map((item) => {
                            return (
                              <CardList
                                demandId={userDemand._id}
                                avatarUrl={userData?.photo}
                                id={item._id}
                                onRefresh={(value) => removeFileById(value)}
                                accountName={item?.createdByUser?.name}
                                date={item.createdByUserAt}
                                path={item?.attachment || []}
                                data={{
                                  accountId: _id,
                                  accountName: name,
                                  userAction: "Editou",
                                  userId: userDemand?.registeredPerson?._id,
                                  userName: userDemand?.registeredPerson?.name,
                                  description: item.demandHistoric || ""
                                }}
                              />
                            );
                          })}
                        </>
                      ) : (
                        <Box display="flex" minH="container.lg" justifyContent="center">
                          <Text>Sem arquivos</Text>
                        </Box>
                      )}
                    </div>
                  )}
                </TabPanel>
              </TabPanels>
            </Tabs>
            <AlertDialog
              motionPreset="scale"
              // @ts-ignore
              leastDestructiveRef={cancelRef}
              onClose={onCloseModal}
              isOpen={isOpenModal}
              isCentered
              size="4xl"
            >
              <AlertDialogOverlay />
              <AlertDialogContent>
                <AlertDialogHeader

                  textAlign="center"
                  justifyContent="center"
                >
                  Histórico da demanda
                </AlertDialogHeader>
                <AlertDialogCloseButton />
                <AlertDialogBody borderStyle="solid" borderWidth={1}>
                  <Textarea
                    minHeight="56"
                    required
                    value={demandHistoric}
                    onChange={(event) => setDemandHistoric(event.target.value)}
                    placeholder="..."
                  />
                </AlertDialogBody>
                <AlertDialogFooter >
                  <Button colorScheme="red" onClick={() => {
                    onCloseModal();
                    setDemandHistoric("");
                    setHistoricIdDemand("");
                  }}>
                    Fechar
                  </Button>
                  <Button
                    marginLeft={2}
                    colorScheme="green"
                    isLoading={loadingSave}
                    isDisabled={loadingSave}
                    type="button"
                    onClick={updateDemandHisotric}
                  >
                    Salvar
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
            <AlertDialog
              motionPreset="slideInBottom"
              // @ts-ignore
              leastDestructiveRef={cancelRef}
              onClose={() => {
                onCloseModalNewHistoric();
                setDemandHistoric("");
                setDemandStatus("");
                setDemandStatusSituation("");

                setResponsible("");
                setSituations([]);
                setFile([]);
                setFileBase64([]);
                setHasReturn("");
                setHasSendMidia("");
                setHasSendFile("");
                setDemandLabel("");
                setDemandDescription("");
                setDemandDescriptionOld("");
                setSituationLabel("");
                setDemandId("");
              }}
              isOpen={isOpenModalNewHistoric}

              size="5xl"
            >
              <AlertDialogOverlay />
              <AlertDialogContent>
                <AlertDialogHeader textAlign="center">
                  Novo histórico da demanda
                </AlertDialogHeader>
                <AlertDialogCloseButton />
                <AlertDialogBody
                  borderStyle="solid"
                  borderWidth={1}
                  paddingTop={10}
                  paddingBottom={8}
                >
                  <Flex direction="column">
                    <Flex direction="column" flex={1}>
                      <Flex whiteSpace="pre-line" marginBottom="6">
                        {demandDescription}
                      </Flex>
                      <Text marginTop={4} fontWeight="semibold" marginBottom={1}>
                        Status da demanda*
                      </Text>
                      <Select
                        required
                        value={demandStatus}
                        onChange={(event) => {
                          if (status?.filter((it) => it.key === event.target.value)[0]?.text === "Resolvida") {
                            setFile([]);
                            setFiles([]);
                            setFileBase64([]);

                          }

                          setSituationLabel(
                            status?.filter((it) => it.key === event.target.value)[0]?.text
                          );

                          setDemandStatus(event.target.value);
                          setHasSendFile("");
                          setHasSendMidia("");
                          setHasReturn("");

                          if (status?.filter((it) => it.key === event.target.value)[0]?.text !== "Resolvida") {
                            setDemandStatusSituation("");

                          }
                          setSituations([]);
                          setSituations(
                            status
                              ?.filter((it) => it.value === event.target.value)[0]
                              ?.situations.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).filter((item) => item.name !== "Resolvida").map((item) => {
                                return {
                                  key: item._id,
                                  text: item.name,
                                  value: item._id,
                                };
                              })
                          );
                        }}
                      >
                        {status.map((item) => (
                          <option value={item.value}>{item.text}</option>
                        ))}
                      </Select>
                      <br />
                      {situations.length > 1 && (
                        <>
                          <Text fontWeight="semibold" marginBottom={1}>
                            Situação da demanda*
                          </Text>
                          <Select
                            required
                            onChange={(event) => {
                              setDemandStatusSituation(event.target.value);
                            }}
                            value={demandStatusSituation}
                          >
                            <option value="">Selecione</option>
                            {situations.map((item) => (
                              <option value={item.value}>{item.text}</option>
                            ))}
                          </Select>
                          <br />
                        </>
                      )}
                      {situationLabel === "Resolvida" && (
                        <>
                          <input id="myInput"
                            multiple
                            accept="image/png, image/jpeg, video/mp4, video/webm, video/mp3, video/ogv, audio/*, application/pdf"
                            type="file"
                            ref={(ref) => inputRef = ref}
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                          />
                          <Text fontWeight="semibold" marginBottom={1}>
                            Enviou algum documento ao solicitante*
                          </Text>
                          <Select
                            required
                            value={hasSendfile}
                            placeholder="Selecione"
                            onChange={(event) => {
                              if (event.target.value === "Sim") {
                                inputRef.click()
                              }
                              setHasSendFile(event.target.value)
                            }}
                          >
                            {["Sim", "Não"].map((item) => (
                              <option value={item}>{item}</option>
                            ))}
                          </Select>
                          <br />
                          <Text fontWeight="semibold" marginBottom={1}>
                            Enviou alguma mídia/arte ao solicitante?*
                          </Text>
                          <Select
                            required
                            value={hasSendMidia}
                            placeholder="Selecione"
                            onChange={(event) => {
                              if (event.target.value === "Sim") {
                                inputRef.click()
                              }
                              setHasSendMidia(event.target.value)
                            }}
                          >
                            {["Sim", "Não"].map((item) => (
                              <option value={item}>{item}</option>
                            ))}
                          </Select>
                          <br />
                          <Text fontWeight="semibold" marginBottom={1}>
                            Deu retorno ao solicitante?*
                          </Text>
                          <Select
                            required
                            value={hasReturn}
                            placeholder="Selecione"
                            onChange={(event) => {
                              setHasReturn(event.target.value);
                            }}
                          >
                            {["Sim", "Não"].map((item) => (
                              <option value={item}>{item}</option>
                            ))}
                          </Select>
                          <br />
                        </>
                      )}
                      {(situationLabel === "Resolvida" && hasReturn) && (
                        <>
                          <Text fontWeight="semibold" marginBottom={1}>
                            {hasReturn === "Sim" ? "Descreva como foi o seu retorno ao solicitante" : "Por que você não deu retorno ao solicitante?"}
                          </Text>
                          <Textarea
                            minHeight="40"
                            value={demandHistoric}
                            onChange={(event) =>
                              setDemandHistoric(event.target.value)
                            }
                            placeholder="..."
                          />
                        </>
                      )}
                      {situationLabel !== "Resolvida" && (
                        <>
                          <Text fontWeight="semibold" marginBottom={1}>
                            Explique o motivo de selecionar o Status e a Situação acima
                          </Text>
                          <Textarea
                            minHeight="40"
                            value={demandHistoric}
                            onChange={(event) =>
                              setDemandHistoric(event.target.value)
                            }
                            placeholder="..."
                          />
                        </>
                      )}
                      <Flex marginTop={5} direction="column">
                        <Text fontWeight="semibold" marginBottom={1}>
                          Atualização da Demanda
                        </Text>
                        <Textarea
                          minHeight="40"
                          maxLength={210}
                          value={demandDescription}
                          onChange={(event) =>
                            setDemandDescription(event.target.value)
                          }
                          placeholder="..."
                        />
                        <div
                          style={{
                            marginTop: 8,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <strong>
                            {210 - demandDescription?.length < 0
                              ? 0
                              : 210 - demandDescription?.length}
                          </strong>
                        </div>
                      </Flex>
                      {situationLabel === "Resolvida" || situationLabel === "Cancelada" ? null : (
                        <>
                          <Text fontWeight="semibold" marginTop={4}>
                            Responsável
                          </Text>
                          <Select
                            value={responsible}
                            onChange={(event) => {
                              setResponsible(event.target.value);
                            }}
                          >
                            {accounts.map((item) => (
                              <option value={item.value}>{item.text}</option>
                            ))}
                          </Select>
                        </>
                      )}

                    </Flex>
                    <ImagePreView
                      fileBase64={fileBase64}
                      removeFileList={(value) => removeFileList(value)}
                    />
                    <Flex>
                      <input
                        onChange={handleFileChange}
                        multiple
                        accept="image/png, image/jpeg, video/mp4, video/webm, video/mp3, video/ogv, audio/*, application/pdf"
                        type="file"
                        id="actual-btn"
                        hidden
                      />
                      <div
                        style={{
                          backgroundColor: "#2F8559",
                          color: "white",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          height: 35,
                          paddingRight: 12,
                          paddingLeft: 12,
                          justifyContent: "center",
                          borderRadius: 6,
                        }}
                      >
                        <label
                          style={{ cursor: "pointer" }}
                          // @ts-ignore
                          htmlFor="actual-btn"
                        >
                          <AttachmentIcon /> Upload de arquivo
                        </label>
                      </div>
                    </Flex>
                  </Flex>
                </AlertDialogBody>
                <AlertDialogFooter >
                  <Button
                    colorScheme="red"
                    marginRight="2"
                    disabled={loadingNew}
                    onClick={() => {
                      onCloseModalNewHistoric();
                      setDemandHistoric("");
                      setDemandStatus("");
                      setDemandStatusSituation("");

                      setResponsible("");
                      setSituations([]);
                      setFile([]);
                      setFileBase64([]);
                      setHasReturn("");
                      setHasSendMidia("");
                      setHasSendFile("");
                      setDemandLabel("");
                      setDemandDescription("");
                      setDemandDescriptionOld("");
                      setSituationLabel("");
                      setDemandId("");
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    colorScheme="green"
                    disabled={loadingNew}
                    isLoading={loadingNew}
                    onClick={() => submitNewHistoric()}
                  >
                    Salvar
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
            <AlertDialog
              motionPreset="slideInBottom"
              // @ts-ignore
              leastDestructiveRef={cancelRef}
              onClose={onCloseModalDemand}
              isOpen={isOpenModalDemand}
              isCentered
              size="4xl"
            >
              <AlertDialogOverlay />
              <AlertDialogContent>
                <AlertDialogHeader textAlign="center">
                  Demanda
                </AlertDialogHeader>
                <AlertDialogCloseButton />
                <AlertDialogBody borderStyle="solid" borderWidth={1} paddingTop={10}>
                  <Form onSubmit={submitNewHistoric} style={{ heigth: "100%" }}>
                    <div style={{ width: "100%" }}>
                      <Textarea
                        minHeight="40"
                        maxLength={210}
                        required
                        value={demandDescription}
                        onChange={(event) =>
                          setDemandDescription(event.target.value)
                        }
                        placeholder="..."
                      />
                      <div
                        style={{
                          marginTop: 8,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <strong>
                          {210 - demandDescription.length < 0
                            ? 0
                            : 210 - demandDescription.length}
                        </strong>
                      </div>
                    </div>
                    <br />
                    <FormControl>
                      <FormLabel
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        color={textColor}
                        display='flex'>
                        Responsavél
                      </FormLabel>
                      <Select
                        value={responsible}
                        onChange={(event) => {
                          setResponsible(event.target.value);
                        }}
                      >
                        {accounts.map((item) => (
                          <option value={item.value}>{item.text}</option>
                        ))}
                      </Select>
                    </FormControl>
                    <br />
                    <FormControl>
                      <FormLabel
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        color={textColor}
                        display='flex'>
                        Categoria(s)
                      </FormLabel>
                      <SelectChakra
                        isMulti
                        placeholder=""
                        onChange={(event) => {
                          setCategoriesSelected(event);

                        }}
                        colorScheme="green"
                        value={categoriesSelected}
                        options={categories}
                      />
                    </FormControl>
                    <br />
                  </Form>
                </AlertDialogBody>
                <AlertDialogFooter >
                  <Button
                    marginRight={2}
                    colorScheme="red"
                    disabled={loadingNew}
                    onClick={() => {
                      setDemandDescriptionOld("");
                      setDemandDescription("");
                      setResponsible("");
                      setCategoriesSelected([]);
                      onCloseModalDemand();
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    colorScheme="green"
                    disabled={loadingNew}
                    isLoading={loadingNew}
                    onClick={() => updateDemandOriginApi()}
                  >
                    Salvar
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </>
        )}
      </Flex>
    </Box>
  );
};
export default ShowDemandDetail;
