import { Box, Flex, IconButton, Image, Text, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import GlobalModal from "components/@globalModal";
import { memo } from "react";
import { LuZoomIn } from "react-icons/lu";


const Address = ({ data }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const textColor = useColorModeValue("navy.700", "white");

  return (
    <Flex
      borderWidth="1px"
      borderRadius="lg"
      w={{ sm: '100%', md: '100%' }}
      height={{ sm: '100%', md: '20rem' }}
      direction={{ base: 'column', md: 'row' }}
      bg={useColorModeValue('white', 'gray.900')}
      boxShadow={'2xl'}
      padding={4}
    >
      <GlobalModal isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
        <Image
          borderTopLeftRadius={6}
          borderTopRightRadius={6}
          flex={0.1}
          boxSize="100%"
          height="72"
          width="72"
          objectFit="contain"
          src={
            data?.photo_of_the_house
            ||
            "https://react.semantic-ui.com/images/wireframe/image.png"
          }
        />
      </GlobalModal>
      <Box marginRight={4} position="relative">
        <Image
          borderTopLeftRadius={6}
          borderTopRightRadius={6}
          flex={0.1}
          boxSize="100%"
          objectFit="contain"
          src={
            data?.photo_of_the_house
            ||
            "https://react.semantic-ui.com/images/wireframe/image.png"
          }
        />
        {data?.photo_of_the_house && (
          <IconButton
            position="absolute"
            bottom={2}
            right={2}
            onClick={() => onOpen()}
            icon={<LuZoomIn color="grey" />}
          />
        )}
      </Box>
      <Flex direction="column">
        <Text
          fontSize='lg'
          fontWeight='500'
          marginTop={{ base: 4, md: 0 }}
          color={textColor}>
          {data?.name}
        </Text>
        <Flex marginTop={2}>
          <Text
            fontWeight='500'
            fontSize='md'
            color={textColor}>
            País:
          </Text>
          <Text
            marginLeft={2}
            fontSize='md'
            color={textColor}>
            {data?.countryId?.name || "Não informado"}
          </Text>
        </Flex>
        <Flex>
          <Text
            fontWeight='500'
            fontSize='md'
            color={textColor}>
            Estado:
          </Text>
          <Text
            marginLeft={2}
            fontSize='md'
            color={textColor}
          >
            {data?.stateId?.name || "Não informado"}
          </Text>
        </Flex>
        <Flex>
          <Text
            fontWeight='500'
            fontSize='md'
            color={textColor}>
            Cidade:
          </Text>
          <Text
            marginLeft={2}
            fontSize='md'
            color={textColor}
          >
            {data?.cityId?.name || " Não informado"}
          </Text>
        </Flex>
        {data?.districtId && (
          <Flex>
            <Text
              fontWeight='500'
              fontSize='md'
              color={textColor}>
              Distrito:
            </Text>
            <Text
              marginLeft={2}
              fontSize='md'
              color={textColor}
            >
              {data?.districtId?.name || "Não informado"}
            </Text>
          </Flex>
        )}
        {!data?.districtId && (
          <Flex>
            <Text
              fontWeight='500'
              fontSize='md'
              color={textColor}>
              Bairro:
            </Text>
            <Text
              marginLeft={2}
              fontSize='md'
              color={textColor}
            >
              {data?.neighborhoodId?.name || "Não informado"}
            </Text>
          </Flex>
        )}
        <Flex>
          <Text
            fontWeight='500'
            fontSize='md'
            color={textColor}>
            Rua:
          </Text>
          <Text
            marginLeft={2}
            fontSize='md'
            color={textColor}
          >
            {data?.public_placeId?.name || "Não informado"}
          </Text>
        </Flex>
        <Flex>
          <Text
            fontWeight='500'
            fontSize='md'
            color={textColor}>
            Número:
          </Text>
          <Text
            marginLeft={2}
            fontSize='md'
            color={textColor}
          >
            {data?.houseNumber ? data?.houseNumber : "Não informado"}
          </Text>
        </Flex>
        <Flex>
          <Text
            fontWeight='500'
            fontSize='md'
            color={textColor}>
            Ponto de referência:
          </Text>
          <Text
            marginLeft={2}
            fontSize='md'
            color={textColor}
          >
            {data?.addressReference
              ? data?.addressReference
              : "Não informado"}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}
export default memo(Address);