import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Resizer from "react-image-file-resizer";
import { AiFillDelete, AiFillFileAdd, AiFillPlusCircle } from "react-icons/ai";
import CreatableSelect from 'react-select/creatable';
import InputMask from "react-input-mask";
import {
  Select as SelectChakra,
} from "chakra-react-select";
import { MdArrowDropDown } from 'react-icons/md'
import "react-phone-input-2/lib/style.css";

import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Webcam from "react-webcam";
import {
  Form,
} from "semantic-ui-react";
import { Checkbox } from 'semantic-ui-react'

import axiosDefault from "services/api";
import axiosCategorie from "../../services/urls/category";
import axiosUser from "../../services/urls/user";
import b64toBlob from "../../utils/blob";
import SideBar from "components/@sidebar";
import {
  Box,
  Button,
  useColorModeValue,
  Text,
  Flex,
  Input,
  InputGroup,
  Image,
  FormLabel,
  IconButton,
  FormControl,
  Select,
  Textarea,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
  useToast,
  Stack,
  Skeleton,
  Avatar,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Tooltip,
} from "@chakra-ui/react";
import AvatarCustom from "components/@avatar";
import { relationsSelect } from "../../utils/relations";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Cropper from "react-easy-crop";
import { convertImageToBase64 } from "utils/fileBase64";
import { cropImage } from "utils/cropUtils";
import ImageUploader from "components/@imageUpload";

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

const videoConstraints = {
  width: 460,
  height: 460,
  facingMode: FACING_MODE_USER,
};
const PeopleUserPage = () => {

  const { id } = useLocation().state;
  const [relations, setRelations] = useState([]);

  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef()
  const textColor = useColorModeValue("secondaryGray.900", "white");
  let shoulRequest = useRef(true);
  const history = useNavigate();
  const [facingMode, setFacingMode] = React.useState(FACING_MODE_USER);
  const [basic, setBasic] = useState(true);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [description, setDescription] = useState("");
  const [categorie, setCategorie] = useState([]);
  const [indication, setIndication] = useState("");
  const [phone, setPhone] = useState("");
  const [whats, setWhats] = useState("");
  const [photo, setPhoto] = useState("");
  const [photoPreView, setPhotoPreView] = useState("");
  const [rgOrgao, setRgOrgao] = useState("");
  const [pais, setPais] = useState("");
  const [apelido, setApelido] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [gender, setGender] = useState("");
  const [bairro, setBairro] = useState("");
  const [localization, setLocalization] = useState("");
  const [rg, setRg] = useState("");
  const [cpf, setCpf] = useState("");
  const [cnh, setCnh] = useState("");
  const [tituto, setTituto] = useState("");
  const [zona, setZona] = useState("");
  const [section, setSection] = useState("");
  const [facebook, setFacebook] = useState("");
  const [insta, setInsta] = useState("");
  const [occupation, setOccupation] = useState("");
  const [file, setfile] = useState();
  const [state, setstate] = useState("");
  const [email, setEmail] = useState("");
  const [isFace, setIsFace] = useState(false);
  const [isInst, setIsInst] = useState(false);
  const [phoneContact, setPhoneContact] = useState("");
  const [phoneContactName, setPhoneContactName] = useState("");
  const [photoBase64, setBase64] = useState("");
  const [isCitiesLoading, setIsCitiesLoading] = useState("");

  const [addressReference, setAddressReference] = useState("");
  const [whatsAppContactName, setWhatsAppContactName] = useState("");
  const [whatsAppContact, setWhatsAppContact] = useState("");
  const [susNumero, setSusNumero] = useState("");
  const [tituloError, setTituloError] = useState(false);
  const [tituloMsg, setTituloMsg] = useState(null);
  const [cpfError, setCpfError] = useState(false);
  const [cpfMsg, setCpfMsg] = useState(null);
  const [rgError, setRgError] = useState(false);
  const [rgMsg, setRgMsg] = useState(null);
  const [states, setstates] = useState([]);
  const [isLoadingStates, setIsLoadingsetSatets] = useState(false)
  const [photoId, setPhotoId] = useState("");
  const [originalPhoto, setOriginalPhoto] = useState("");
  const { isOpen: openRelation, onOpen: onOpenRelation, onClose: onCloseRelation } = useDisclosure();
  const [search, setSearch] = useState("");
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [users, setUsers] = useState([]);
  const relationSelected = useRef(null)
  const [relation, setRelation] = useState(null);
  const [isDeath, setIsDeath] = useState(false);
  const [stateId, setStateId] = useState('');
  const [countryId, setCountryId] = useState('');
  const [cityId, setCityId] = useState('');
  const [neighborhood, setNeighborhood] = useState(null);
  const [districtId, setDistrictId] = useState('');
  const [countries, setCountries] = useState([]);
  const [isLoadingSountries, setIsLoadingsetCountries] = useState(false)
  const [districts, setDistricts] = useState([]);
  const [cities, setCities] = useState([]);
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [publicPlaces, setPublicPlaces] = useState([]);
  const [publicPlace, setPublicPlace] = useState(null);
  const [isPlaceLoading, setIsLoadingPlaces] = useState(false);
  const [isLoadingDistricts, setIsLoadingDistricts] = useState(false);

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [addressImage, setAddressImage] = useState(null)

  const [addressImageBase64, setAddressImageBase64] = useState(null)
  const [addressImageBase64Key, setAddressImageBase64Key] = useState(null)

  const [addressImageFile, setAddressImageFile] = useState(null);
  const { isOpen: openAddressImage, onOpen: onOpenAddressImage, onClose: onCloseAddresImage } = useDisclosure();
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const inputFileRef = useRef(null);

  const { isOpen: openAddressImageProfile, onOpen: onOpenAddressImageProfile, onClose: onCloseAddresImageProfile } = useDisclosure();
  const [croppedAreaPixelsProfile, setCroppedAreaPixelsProfile] = useState(null)
  const inputFileProfileRef = useRef(null);
  const [photoProfile, setPhotoProfile] = useState("")

  const { _id, name: accountName } = useSelector(
    (state) => state.user
  );

  const [gategories, setCategories] = useState([]);

  const handleClick = React.useCallback(() => {
    setFacingMode((prevState) =>
      prevState === FACING_MODE_USER
        ? FACING_MODE_ENVIRONMENT
        : FACING_MODE_USER
    );
  }, []);

  const handleButtonClickProfile = () => {
    inputFileProfileRef.current.click();
  };

  const handleButtonClick = () => {
    inputFileRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileBase64 = await convertImageToBase64(file)
      setAddressImage(fileBase64)
      onOpenAddressImage()
    }
  };

  const handleFileChangeProfile = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileBase64 = await convertImageToBase64(file)
      setPhotoProfile(fileBase64);
      onOpenAddressImageProfile();
    }
  };

  const loadAllCountries = useCallback(async () => {
    try {
      setIsLoadingsetCountries(true)
      const { data } = (await axiosDefault.get("place/all-coutries")).data;
      setCountries(data.map((item) => {
        return {
          label: item.name,
          value: item._id,
        }
      }));
    } catch (error) {

    }
    finally {
      setIsLoadingsetCountries(false)
    }
  }, []);

  const loadAllNeighborhood = useCallback(async () => {
    try {
      const { data } = (await axiosDefault.get(`place/list-all-neighborhoods/${cityId?.value}`)).data;
      setNeighborhoods(data.map((item) => {
        return {
          label: item.name,
          value: item._id,
          cityId: item.cityId,
        }
      }));
    } catch (error) {

    }
  }, [cityId?.value]);

  const loadDistricts = useCallback(async () => {
    try {
      setIsLoadingDistricts(true)
      const { data } = (await axiosDefault.get(`place/list-all-districts/${stateId?.value}`)).data;
      setDistricts(data.map((item) => {
        return {
          label: item.name,
          value: item._id,
          cityId: item.cityId,
        }
      }));
      setIsLoadingDistricts(false)
    } catch (error) {
      setIsLoadingDistricts(false)
    }
  }, [stateId]);

  const loadCities = useCallback(async () => {
    try {
      const citiesReordered = []
      const pontapordId = "66199fa822eddc4da088b30d"
      setIsCitiesLoading(true)
      const { data } = (await axiosDefault.get(`place/list-all-cities/${stateId?.value}`)).data;

      const firstCity = data.find((city) => city._id === pontapordId)

      if (firstCity) {
        citiesReordered.push({
          label: firstCity.name,
          value: firstCity._id,
          stateId: firstCity.stateId,
        })
      }

      const citiesApi = data.filter((city) => city._id !== pontapordId).map((item) => {
        return {
          label: item.name,
          value: item._id,
          stateId: item.stateId,
        }
      })

      setCities(citiesReordered.concat(citiesApi));
      setIsCitiesLoading(false)
    } catch (error) {
      setIsCitiesLoading(false)
    }
  }, [stateId]);

  const loadAllPlaces = useCallback(async () => {
    try {
      setIsLoadingPlaces(true)
      const { data } = (await axiosDefault.get(`/place/all-streets/${neighborhood?.value || districtId?.value}`)).data;

      setPublicPlaces(data.map((item) => {
        return {
          label: item.name,
          value: item._id,
          stateId: item.stateId,
        }
      }));
      setIsLoadingPlaces(false)
    } catch (error) {
      setIsLoadingPlaces(false)
    }
  }, [districtId, neighborhood]);

  const requestStates = useCallback(async () => {
    try {
      setIsLoadingsetSatets(true)
      const stateMsId = "62c8305e150ad743b7e77788";
      const response = await axiosUser.getStates();
      const newStates = [];

      const msState = response?.data?.data.find((it) => it._id === stateMsId)

      if (msState) {
        newStates.push({
          key: msState._id,
          text: msState.name,
          value: msState._id,
          countryId: msState.countryId,
        })
      }

      response?.data?.data.filter((it) => it._id !== stateMsId).map((item) => {
        newStates.push({
          key: item._id,
          text: item.name,
          value: item._id,
          countryId: item.countryId,
        });
        return item;
      });

      setstates(newStates);
    } catch (error) {
      toast({
        title: '',
        description: "Erro ao buscar os Estados",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
    finally {
      setIsLoadingsetSatets(false)
    }
  }, [toast]);

  useEffect(() => {
    if (neighborhood?.value) {
      loadAllPlaces()
    }
    if (stateId?.value) {
      loadCities()
      loadDistricts()
    }
    if (cityId?.value) {
      loadAllNeighborhood()
    }
    if (districtId?.value) {
      loadAllPlaces()
    }
  }, [countryId, loadAllPlaces, loadCities, stateId, loadDistricts, cityId, loadAllNeighborhood, neighborhood, districtId])

  const requestUserData = useCallback(async () => {
    try {
      setLoading(true);
      const categoriesResponse = await axiosCategorie.GetCategoriesActive();
      const response = await axiosUser.GetUser(id);

      setPhotoId(response?.data?.data?.photo_id);
      setName(response?.data?.data?.name);
      setAge(response?.data?.data?.dateOfBirth);
      setDescription(response?.data?.data?.description);

      const categoriesPayload = [];

      if (response?.data?.data.category) {
        categoriesResponse?.data?.data?.map((item) => {
          response?.data?.data.category?.map((it) => {
            if (item._id === it._id) {
              categoriesPayload.push({
                label: item.name,
                value: item._id,
              });
            }
            return it;
          })
          return item;
        });

        setCategorie(categoriesPayload);
      }

      if (response.data?.data?.countryId) {
        setCountryId({
          value: response.data?.data?.countryId?._id,
          label: response.data?.data?.countryId?.name,
        });
      }

      if (response.data.data?.stateId) {
        setStateId({
          value: response.data.data?.stateId?._id,
          label: response.data.data?.stateId?.name
        });
      }
      if (response.data.data?.cityId) {
        setCityId({
          value: response.data.data?.cityId?._id,
          label: response.data.data?.cityId?.name,
        });
      }
      if (response.data.data?.districtId) {
        setDistrictId({
          value: response.data.data?.districtId?._id,
          label: response.data.data?.districtId?.name,
        });
      }
      if (response.data.data?.neighborhoodId) {
        setNeighborhood({
          value: response.data.data?.neighborhoodId?._id,
          label: response.data.data?.neighborhoodId?.name,
        });
      }
      if (response.data.data?.public_placeId) {
        setPublicPlace({
          value: response.data.data?.public_placeId?._id,
          label: response.data.data?.public_placeId?.name,
        })
      }

      setIsInst(response.data.data.isInst || false);
      setIsFace(response.data.data.isFace || false);
      setPhone(response?.data?.data?.phone);
      setWhats(response?.data?.data?.whatsApp);
      setIndication(response?.data?.data?.indication);
      setPhoto(response?.data?.data?.photo);
      setOriginalPhoto(response?.data?.data?.photo);
      if (response?.data?.data?.photo_of_the_id) {
        setAddressImageBase64Key(response?.data?.data?.photo_of_the_id)
      }
      if (response?.data?.data?.photo_of_the_house) {
        setAddressImageBase64(response?.data?.data?.photo_of_the_house)
      }


      if (response?.data?.data?.country) {
        if (response?.data?.data?.country === "0") {
          setPais("Brasil");
        }
        if (response?.data?.data?.country === "1") {
          setPais("Paraguay");
        }
        else {
          setPais(response?.data?.data?.country);
        }
      }

      if (response?.data?.data?.state) {
        setstate(response?.data?.data?.state._id || "");
      }
      if (response?.data?.data?.updatedTo) {
        // setUpdateTo(response.data.data.updatedTo);
      }
      setEmail(response?.data?.data?.email);
      setRgOrgao(response?.data?.data?.rgOrgao);
      setSusNumero(response?.data?.data?.susNumero);
      setWhatsAppContact(response?.data?.data.whatsAppContact);
      setWhatsAppContactName(response?.data?.data?.whatsAppContactName);
      setAddressReference(response?.data?.data?.addressReference);
      setApelido(response?.data?.data?.apelido);
      setCity(response?.data?.data?.city);
      setAddress(response?.data?.data?.street);
      setHouseNumber(response?.data?.data?.houseNumber);
      setBairro(response?.data?.data?.neighborhood);
      setLocalization(response?.data?.data?.localization);
      setRg(response?.data?.data?.rg);
      setCpf(response?.data?.data?.cpf);
      setCnh(response?.data?.data?.cnh);
      setTituto(response?.data?.data?.titulo);
      setZona(response?.data?.data?.zona);
      setSection(response?.data?.data?.section);
      setFacebook(response?.data?.data?.socialMidia_facebook);
      setInsta(response?.data?.data?.socialMidia_instagran);
      setOccupation(response?.data?.data?.occupation);
      setPhoneContact(response?.data?.data?.phoneContact);
      setPhoneContactName(response?.data?.data?.phoneContactName);
      setIsDeath(response?.data?.data?.isDeath);
      setGender(response?.data?.data?.gender);
      setLoading(false);
    } catch (error) {
      toast({
        title: '',
        description: "Não foi possível localizar o usuário.",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  }, [id, toast])

  const requestCategories = useCallback(async () => {
    try {
      const response = await axiosCategorie.GetCategoriesActive();
      setCategories(
        response?.data?.data.map((item) => {
          return {
            label: item.name,
            value: item._id,
          };
        })
      );
    } catch (error) {
      toast({
        title: '',
        description: "Erro ao buscar categorias.",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  }, [toast])

  const requestSearch = useCallback(async () => {
    try {
      setLoadingSearch(true);
      const response = await axiosUser.GetUsers(undefined, undefined, search);
      setUsers([]);
      relationSelected.current = null;
      setUsers(response.data?.data?.docs);
      setLoadingSearch(false);
    } catch (error) {
      toast({
        title: '',
        description: "Erro ao realizar a consulta.",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  }, [search, toast])

  const saveUser = async () => {
    try {
      if (tituloError) {
        toast({
          title: '',
          description: "Verifique as informações do formulário.",
          status: 'error',
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      setLoading(true);
      window.scrollTo(0, 0);
      await axiosUser.updateDataBasicUser(
        file,
        id,
        name,
        age,
        phone,
        whats,
        indication,
        categorie.length > 0 ? categorie.map((item) => item.value) : null,
        description,
        apelido,
        photo,
        pais,
        city,
        address,
        houseNumber,
        bairro,
        localization,
        rg,
        cpf,
        cnh,
        tituto,
        zona,
        section,
        facebook,
        insta,
        occupation,
        photoId || undefined,
        state.toUpperCase() || undefined,
        rgOrgao,
        whatsAppContact,
        whatsAppContactName,
        addressReference,
        susNumero,
        email,
        _id,
        moment().format("DD/MM/YYYY [às] HH:mm:ss"),
        isFace,
        isInst,
        _id,
        accountName,
        moment().format("DD/MM/YYYY"),
        moment().format("HH:mm:ss"),
        "Editou",
        id,
        name,
        moment().format(),
        phoneContact,
        phoneContactName,
        gender,
        isDeath,
        countryId?.value,
        stateId?.value,
        cityId?.value,
        neighborhood?.__isNew__ ? undefined : neighborhood?.value,
        publicPlace?.__isNew__ ? undefined : publicPlace?.value,
        neighborhood?.__isNew__ ? neighborhood?.label : undefined,
        publicPlace?.__isNew__ ? publicPlace?.label : undefined,
        districtId?.value,
        addressImageFile,
        addressImageBase64Key,
        addressImageBase64,
      );
      setfile(null);
      setBase64("");
      toast({
        title: '',
        description: "Informações atualizada com sucesso.",
        status: 'success',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
      history(
        "/visualizar-cadastrado",
        {
          state: {
            userId: id,
          }
        },
      );
      setLoading(false);
    } catch (error) {
      toast({
        title: '',
        description: error?.response?.data?.error,
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const createFile = (ImageURL) => {
   try {
      const block = ImageURL.split(";");
      const contentType = block[0].split(":")[1];
      const realData = block[1].split(",")[1];
      const blob = b64toBlob(realData, contentType, "");
      setfile(blob);
   } catch (error) {
    console.log(error)
   }
  };

  const webcamRef = React.useRef(null);
  const capture = React.useCallback(() => {
    // @ts-ignore
    const imageSrc = webcamRef.current.getScreenshot();
    setPhoto(imageSrc);
    setPhotoPreView(imageSrc);
    createFile(imageSrc);
  }, [webcamRef]);

  const removeLeation = async (idRelation) => {
    try {
      await axiosUser.removeRelations(id, idRelation, {
        accountId: _id,
        accountName,
        day: moment().format("DD/MM/YYYY"),
        hour: moment().format("HH:mm:ss"),
        userAction: "Excluiu",
        userId: id,
        userName: name,
        dateTime: moment().format(),
      });
      setRelations(relations.filter((item) => item._id !== idRelation));
      toast({
        title: '',
        description: "Relacionamento removido com sucesso.",
        status: 'success',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: '',
        description: "Erro ao remover relação",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const requestInsertRelation = async (relationId) => {
    try {
      if (!relation) {
        toast({
          title: '',
          description: "Selecione o tipo de relacionamento.",
          status: 'success',
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      await axiosUser.insertRelation(
        id,
        relationId,
        relation.type,
        relation.relation,
        _id,
        accountName,
        moment().format("DD/MM/YYYY"),
        moment().format("HH:mm:ss"),
        "Adicionou",
        id,
        name,
        moment().format(),
        "Adicionou um novo relacionamento."
      );
      setRelation(null);
      requestRelations();
      toast({
        title: '',
        description: "Relacionamento criado com sucesso",
        status: 'success',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: '',
        description: error?.response?.data?.error,
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const requestRelations = useCallback(async () => {
    try {
      const response = await axiosUser.GetRelations(id);
      const data = response?.data?.data.map(
        (item) => ({
          email: item.relation.email,
          _id: item.relation._id,
          name: `${item.relation.name}`,
          relation: `${item.relationName}`,
          src: item.relation.photo,
        })
      );
      setRelations(data);
    } catch (error) {
      toast({
        title: '',
        description: error?.response?.data?.error,
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  }, [id, toast])

  const getImage = (file) =>
    new Promise(() => {
      Resizer.imageFileResizer(
        file,
        400,
        200,
        "PNG",
        100,
        0,
        (uri) => {
          setPhoto(uri.toString());
        },
        "base64"
      );
    });

  const WebcamCapture = () => (
    <>
      <Webcam
        audio={false}
        height={400}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        width={300}
        videoConstraints={{
          ...videoConstraints,
          facingMode,
        }}
      />
      <div
        style={{
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Button
          marginTop={4}
          colorScheme="green"
          onClick={capture}
        >
          Capturar foto
        </Button>
      </div>
    </>
  );

  useEffect(() => {
    if (search.length > 2) requestSearch();
  }, [requestSearch, search]);

  const whatsAppIsValid = useCallback(() => {
    if (whats.includes("55") || whats.includes("595")) {
      return true
    }
    return false
  }, [whats])

  const whatsAppContactIsValid = useCallback(() => {
    if (whatsAppContact.includes("55") || whatsAppContact.includes("595")) {
      return true
    }
    return false
  }, [whatsAppContact])


  const requests = useCallback(async () => {
    Promise.all([
      await requestUserData(),
      await requestCategories(),
      await requestRelations(),
      await loadAllCountries(),
      await requestStates(),
    ])
  }, [
    requestCategories,
    requestRelations,
    requestStates,
    requestUserData,
    loadAllCountries,
  ]);


  useEffect(() => {
    if (shoulRequest.current) {
      requests();
      shoulRequest.current = false;
    }
  }, [requests]);

  useEffect(() => {
    if (basic) {
      setCpfError(false);
      setCpfMsg(null);
      setRgError(false);
      setRgMsg(null);
      setTituloError(false);
      setTituloMsg(null);
    }
  }, [basic]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SideBar />
      <Flex
        flexDirection="column"
        borderRadius={10}
        padding={6}
        marginTop={4}
        marginBottom="10"
        marginLeft={4}
        marginRight={4}
        backgroundColor="white">
        {loading ? (
          <Stack>
            <Flex justifyContent="center">
              <Skeleton
                borderRadius={15}
                width="400px"
                height='300px' />
            </Flex>
            <Skeleton marginTop="0" borderRadius={15} height='100px' />
            <Skeleton marginTop="2" borderRadius={15} height='50px' />
          </Stack>
        ) : (
          <Form onSubmit={saveUser} loading={loading}>
            <Flex marginTop="10" justifyContent="center" alignItems="center">
              <Flex display="flex" justifyContent="center" alignItems="center">
                {photo && (
                  <Flex flexDirection="column" marginRight={2}>
                    <Box boxSize='64'>
                      <Image
                        borderRadius={6}
                        boxSize='250px'
                        objectFit='cover'
                        src={photo === "" ? "" : photo}
                      />
                    </Box>
                    <Flex justifyContent="center">
                      {photo && (
                        <IconButton
                          marginTop={2}
                          onClick={() => {
                            setfile(null);
                            setPhoto("");
                            setPhotoPreView("");
                          }}>
                          <AiFillDelete color="red" />
                        </IconButton>
                      )}
                    </Flex>
                  </Flex>
                )}
                {!photo && (
                  <Flex flexDirection="column" alignItems="center">
                    <ImageUploader
                      isProfile
                      imageBase64={photo}
                      onFileChange={handleFileChangeProfile}
                      onButtonClick={handleButtonClickProfile}
                      inputFileRef={inputFileProfileRef}
                      label="Foto do cadastrado"
                      tooltip="Adicionar foto do cadastrado"
                      textColor={textColor}
                      onRemoveImage={() => {
                        setfile(null);
                        setPhoto("");
                        setPhotoPreView("");
                      }}
                    />
                    <Text>Ou</Text>
                    <Button
                      variant='brand'
                      marginTop={2}
                      marginLeft={2.5}
                      onClick={() =>
                        onOpen()}>
                      Abrir câmera
                    </Button>
                  </Flex>
                )}
              </Flex>
            </Flex>
            <Flex>
              <Text
                color={textColor}
                fontSize='18px'
                fontWeight='700'
                marginBottom={10}
                marginTop={10}
                marginLeft={2}
                lineHeight='100%'>
                Apresentação Pessoal
              </Text>
            </Flex>
            <Flex display={{ md: 'flex' }}>
              <FormControl m={2}>
                <FormLabel
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  display='flex'>
                  Nome<Text>*</Text>
                </FormLabel>
                <InputGroup size='md'>
                  <Input
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    fontSize='sm'
                    isRequired
                    mb='24px'
                    size='lg'
                    type={"text"}
                    variant='auth'
                  />
                </InputGroup>
              </FormControl>
              <FormControl m={2}>
                <FormLabel
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  display='flex'>
                  Gênero<Text>*</Text>
                </FormLabel>
                <Select
                  mb='24px'
                  size='lg'
                  borderRadius={15}
                  variant='outline'
                  isRequired
                  value={gender}
                  onChange={(event) => event.target.value ? setGender(event.target.value) : {}}
                  icon={<MdArrowDropDown />}>
                  <option value={""}>Selecione</option>
                  {["Masculino", "Feminino"].map((item) => (
                    <option value={item}>{item}</option>
                  ))}
                </Select>
              </FormControl>
              <FormControl m={2}>
                <FormLabel
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  display='flex'>
                  Como gostaria de ser chamado
                </FormLabel>
                <InputGroup size='md'>
                  <Input
                    value={apelido}
                    onChange={(event) => setApelido(event.target.value)}
                    fontSize='sm'
                    mb='24px'
                    size='lg'
                    type={"text"}
                    variant='auth'
                  />
                </InputGroup>
              </FormControl>
              <FormControl m={2}>
                <FormLabel
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  display='flex'>
                  Data de nascimento<Text>*</Text>
                </FormLabel>
                <InputGroup size='md'>
                  <Input
                    value={age}
                    isRequired
                    onChange={(event) => {
                      const day = moment(moment().format("YYYY-MM-DD"));
                      const diff = day.diff(event.target.value, "day");
                      if (diff >= 0) {
                        setAge(event.target.value);
                      } else {
                        toast({
                          title: '',
                          description: "Data inválida.",
                          status: 'warning',
                          duration: 5000,
                          position: "top-right",
                          isClosable: true,
                        });
                      }
                    }}
                    fontSize='sm'
                    mb='24px'
                    size='lg'
                    type={"date"}
                    variant='auth'
                  />
                </InputGroup>
              </FormControl>
            </Flex>
            <Flex marginLeft={2}>
              <Checkbox
                label=" Óbito"
                checked={isDeath}
                onChange={() => setIsDeath(!isDeath)}
              >
              </Checkbox>
            </Flex>
            <br />
            <Flex>
              <Text
                color={textColor}
                fontSize='18px'
                fontWeight='700'
                marginBottom={10}
                marginTop={10}
                marginLeft={2}
                lineHeight='100%'>
                Contato do cadastrado
              </Text>
            </Flex>
            <Flex flexDirection="column">
              <FormControl marginLeft={2}>
                <FormLabel
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  display='flex'>
                  WhatsApp
                </FormLabel>

                <PhoneInput
                  placeholder=""
                  isValid={whats?.length > 2 ? whatsAppIsValid() : true}
                  value={whats}
                  buttonStyle={{
                    borderTopLeftRadius: 16,
                    borderBottomLeftRadius: 16,
                  }}
                  inputStyle={{
                    flex: 1,
                    borderRadius: 16,
                    height: 50,
                    borderColor: '#E0E5F2',
                    width: '100%',
                  }}
                  country='br'
                  onlyCountries={['br', 'py']}
                  onChange={setWhats}
                />
              </FormControl>
              <br />
              <FormControl m={2}>
                <FormLabel
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  display='flex'>
                  Contato sem WhatsApp
                </FormLabel>
                <InputGroup size='md'>
                  <Input
                    value={phone}
                    onChange={(event) => setPhone(event.target.value)}
                    fontSize='sm'
                    mb='24px'
                    size='lg'
                    type={"tel"}
                    variant='auth'
                  />
                </InputGroup>
              </FormControl>
            </Flex>
            <Flex>
              <Text
                color={textColor}
                fontSize='18px'
                fontWeight='700'
                marginBottom={10}
                marginTop={10}
                marginLeft={2}
                lineHeight='100%'>
                Contato para recado
              </Text>
            </Flex>
            <Flex flexDirection="column">
              <Flex display={{ md: 'flex' }}>
                <FormControl m={2}>
                  <FormLabel
                    ms='4px'
                    fontSize='sm'
                    fontWeight='500'
                    color={textColor}
                    display='flex'>
                    WhatsApp para recado
                  </FormLabel>
                  <PhoneInput
                    placeholder=""
                    isValid={whatsAppContact?.length > 2 ? whatsAppContactIsValid() : true}
                    value={whatsAppContact}
                    buttonStyle={{
                      borderTopLeftRadius: 16,
                      borderBottomLeftRadius: 16,
                    }}
                    inputStyle={{
                      flex: 1,
                      borderRadius: 16,
                      height: 50,
                      borderColor: '#E0E5F2',
                      width: '100%',
                    }}
                    country='br'
                    onlyCountries={['br', 'py',]}
                    onChange={setWhatsAppContact}
                  />
                </FormControl>
                <FormControl m={2}>
                  <FormLabel
                    ms='4px'
                    fontSize='sm'
                    fontWeight='500'
                    color={textColor}
                    display='flex'>
                    Com quem vamos falar?
                  </FormLabel>
                  <InputGroup size='md'>
                    <Input
                      value={whatsAppContactName}
                      onChange={(event) => setWhatsAppContactName(event.target.value)}
                      fontSize='sm'
                      mb='24px'
                      size='lg'
                      type={"text"}
                      variant='auth'
                    />
                  </InputGroup>
                </FormControl>
              </Flex>
              <Flex display={{ md: 'flex' }}>
                <FormControl m={2}>
                  <FormLabel
                    ms='4px'
                    fontSize='sm'
                    fontWeight='500'
                    color={textColor}
                    display='flex'>
                    Contato sem WhatsApp
                  </FormLabel>
                  <InputGroup size='md'>
                    <Input
                      value={phoneContact}
                      onChange={(event) => setPhoneContact(event.target.value)}

                      fontSize='sm'
                      mb='24px'
                      size='lg'
                      type={"tel"}
                      variant='auth'
                    />
                  </InputGroup>
                </FormControl>
                <FormControl m={2}>
                  <FormLabel
                    ms='4px'
                    fontSize='sm'
                    fontWeight='500'
                    color={textColor}
                    display='flex'>
                    Com quem vamos falar?
                  </FormLabel>
                  <InputGroup size='md'>
                    <Input
                      value={phoneContactName}
                      onChange={(event) => setPhoneContactName(event.target.value)}
                      fontSize='sm'
                      mb='24px'
                      size='lg'
                      type={"text"}
                      variant='auth'
                    />
                  </InputGroup>
                </FormControl>
              </Flex>
            </Flex>

            {basic && (
              <>
                <Flex>
                  <Text
                    color={textColor}
                    fontSize='18px'
                    fontWeight='700'
                    marginBottom={10}
                    marginTop={10}
                    marginLeft={2}
                    lineHeight='100%'>
                    Endereço
                  </Text>
                </Flex>
                <Flex flexDirection={{ base: "column", md: "row" }}>
                  <FormControl m={2}>
                    <FormLabel
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      color={textColor}
                      display='flex'>
                      País
                    </FormLabel>
                    <CreatableSelect
                      isMulti={false}
                      isLoading={isLoadingSountries}
                      value={countryId}
                      onChange={(newValue, _) => {
                        if (newValue) {
                          setCountryId(newValue)
                        }
                        else {
                          setCountryId(null)
                        }
                        setStateId(null)
                        setCityId(null)
                        setNeighborhood(null)
                        setPublicPlace(null)
                        setDistrictId(null)
                      }}
                      menuPortalTarget={document.body}
                      placeholder='Selecione'
                      styles={{
                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                        container: () => ({
                          flex: 1,
                          color: 'blue'
                        }),
                        control: (base, _) => ({
                          ...base,
                          borderRadius: '15px',
                          borderColor: '#EAEEF2',
                          borderWidth: 1,
                        }),
                        valueContainer: (base, state) => ({
                          ...base,
                          height: '45px',
                          padding: '0 6px',
                          borderRadius: 30,
                        }),
                        input: (base, state) => ({
                          ...base,
                          margin: '0px',
                          padding: '0px',
                        }),
                        menu: () => ({
                          zIndex: 2,
                          position: 'relative',
                          backgroundColor: '#F4F7FD'
                        })
                      }}
                      isClearable={false}
                      options={countries}
                    />
                  </FormControl>
                  <FormControl m={2}>
                    <FormLabel
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      color={textColor}
                      display='flex'>
                      Estado
                    </FormLabel>
                    <CreatableSelect
                      isMulti={false}
                      isLoading={isLoadingStates}
                      isDisabled={!countryId}
                      value={stateId}
                      onChange={(newValue, _) => {
                        setStateId(newValue);
                        setNeighborhood(null)
                        setPublicPlace(null)
                        setCityId(null)
                      }}
                      menuPortalTarget={document.body}
                      placeholder='Selecione'
                      noOptionsMessage={() => 'Nenhuma opção disponível'}
                      formatCreateLabel={inputValue => `Criar "${inputValue}"`}
                      styles={{
                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                        container: () => ({
                          flex: 1,
                          color: 'blue'
                        }),
                        control: (base, _) => ({
                          ...base,
                          borderRadius: '15px',
                          borderColor: '#EAEEF2',
                          borderWidth: 1,
                        }),
                        valueContainer: (base, state) => ({
                          ...base,
                          height: '45px',
                          padding: '0 6px',
                          borderRadius: 30,
                        }),
                        input: (base, state) => ({
                          ...base,
                          margin: '0px',
                          padding: '0px',
                        }),
                        menu: () => ({
                          zIndex: 2,
                          position: 'relative',
                          backgroundColor: '#F4F7FD'
                        })
                      }}
                      isClearable
                      options={states.filter((it) => it.countryId === countryId?.value).map((state) => ({ value: state.value, label: state.text }))}
                    />
                  </FormControl>
                  <FormControl m={2}>
                    <FormLabel
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      color={textColor}
                      display='flex'>
                      Cidade
                    </FormLabel>
                    <CreatableSelect
                      isLoading={isCitiesLoading}
                      isMulti={false}
                      isDisabled={!stateId}
                      value={cityId}
                      onChange={(newValue, _) => {
                        setCityId(newValue)
                        setNeighborhood(null)
                        setPublicPlace(null)
                        setDistrictId(null)
                        setPublicPlaces([])
                      }}
                      menuPortalTarget={document.body}
                      placeholder='Selecione'
                      noOptionsMessage={() => 'Nenhuma opção disponível'}
                      formatCreateLabel={inputValue => `Criar "${inputValue}"`}
                      styles={{
                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                        container: () => ({
                          flex: 1,
                          color: 'blue'
                        }),
                        control: (base, _) => ({
                          ...base,
                          borderRadius: '15px',
                          borderColor: '#EAEEF2',
                          borderWidth: 1,
                        }),
                        valueContainer: (base, state) => ({
                          ...base,
                          height: '45px',
                          padding: '0 6px',
                          borderRadius: 30,
                        }),
                        input: (base, state) => ({
                          ...base,
                          margin: '0px',
                          padding: '0px',
                        }),
                        menu: () => ({
                          zIndex: 2,
                          position: 'relative',
                          backgroundColor: '#F4F7FD'
                        })
                      }}
                      isClearable
                      isValidNewOption={() => false}
                      options={cities}
                    />
                  </FormControl>
                </Flex>
                <Flex flexDirection={{ base: "column", md: "row" }}>
                  <FormControl m={2}>
                    <FormLabel
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      color={textColor}
                      display='flex'>
                      Distrito
                    </FormLabel>
                    <CreatableSelect
                      isMulti={false}
                      isLoading={isLoadingDistricts}
                      value={districtId}
                      isDisabled={!cityId}
                      isValidNewOption={() => false}
                      onChange={(newValue, _) => {
                        setDistrictId(newValue)
                        setNeighborhood(null);
                        setPublicPlace(null)
                        setPublicPlaces([])
                      }}
                      menuPortalTarget={document.body}
                      placeholder='Selecione'
                      noOptionsMessage={() => 'Nenhuma opção disponível'}
                      formatCreateLabel={inputValue => `Criar "${inputValue}"`}
                      styles={{
                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                        container: () => ({
                          flex: 1,
                          color: 'blue'
                        }),
                        control: (base, _) => ({
                          ...base,
                          borderRadius: '15px',
                          borderColor: '#EAEEF2',
                          borderWidth: 1,
                        }),
                        valueContainer: (base, state) => ({
                          ...base,
                          height: '45px',
                          padding: '0 6px',
                          borderRadius: 30,
                        }),
                        input: (base, state) => ({
                          ...base,
                          margin: '0px',
                          padding: '0px',
                        }),
                        menu: () => ({
                          zIndex: 2,
                          position: 'relative',
                          backgroundColor: '#F4F7FD'
                        })
                      }}
                      isClearable
                      options={districts.filter((district) => district.cityId === cityId?.value)}
                    />
                  </FormControl>
                  {!districtId && (
                    <>
                      <FormControl m={2}>
                        <FormLabel
                          ms='4px'
                          fontSize='sm'
                          fontWeight='500'
                          color={textColor}
                          display='flex'>
                          Bairro
                        </FormLabel>
                        <CreatableSelect
                          isMulti={false}
                          value={neighborhood}
                          isDisabled={!cityId}
                          isValidNewOption={() => false}
                          onChange={(newValue, _) => {
                            setNeighborhood(newValue);
                            setPublicPlace(null)
                            setDistrictId(null)
                            setPublicPlaces([])
                          }}
                          menuPortalTarget={document.body}
                          placeholder='Selecione'
                          noOptionsMessage={() => 'Nenhuma opção disponível'}
                          formatCreateLabel={inputValue => `Criar "${inputValue}"`}
                          styles={{
                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                            container: () => ({
                              flex: 1,
                              color: 'blue',
                              textTransform: "capitalize",
                            }),
                            control: (base, _) => ({
                              ...base,
                              borderRadius: '15px',
                              borderColor: '#EAEEF2',
                              borderWidth: 1,
                            }),
                            valueContainer: (base, state) => ({
                              ...base,
                              height: '45px',
                              padding: '0 6px',
                              borderRadius: 30,
                            }),
                            input: (base, state) => ({
                              ...base,
                              margin: '0px',
                              padding: '0px',
                            }),
                            menu: () => ({
                              zIndex: 2,
                              position: 'relative',
                              backgroundColor: '#F4F7FD',
                              textTransform: "capitalize",
                            }),
                          }}
                          isClearable

                          options={neighborhoods.filter((neighborhood) => neighborhood.cityId === cityId?.value)}
                        />
                      </FormControl>
                    </>
                  )}
                </Flex>
                <Flex flexDirection={{ base: "column", md: "row" }}>
                  <FormControl m={2}>
                    <FormLabel
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      color={textColor}
                      display='flex'>
                      Rua
                    </FormLabel>

                    <InputGroup size='md'>
                      <Flex flex={1}>
                        <CreatableSelect
                          isMulti={false}
                          isLoading={isPlaceLoading}
                          value={publicPlace}
                          onChange={(newValue, _) => {
                            setPublicPlace(newValue);
                          }}
                          menuPortalTarget={document.body}
                          placeholder='Selecione'
                          noOptionsMessage={() => 'Nenhuma opção disponível'}
                          formatCreateLabel={inputValue => `Criar "${inputValue}"`}
                          styles={{
                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                            container: () => ({
                              flex: 1,
                              color: 'blue'
                            }),
                            control: (base, _) => ({
                              ...base,
                              borderRadius: '15px',
                              borderColor: '#EAEEF2',
                              borderWidth: 1,
                              textTransform: "capitalize",
                            }),
                            valueContainer: (base, state) => ({
                              ...base,
                              height: '45px',
                              padding: '0 6px',
                              borderRadius: 30,
                            }),
                            input: (base, state) => ({
                              ...base,
                              margin: '0px',
                              padding: '0px',
                            }),
                            menu: () => ({
                              zIndex: 2,
                              position: 'relative',
                              backgroundColor: '#F4F7FD'
                            })
                          }}

                          isClearable
                          options={publicPlaces}
                        />
                      </Flex>
                    </InputGroup>
                  </FormControl>
                  <FormControl m={2}>
                    <FormLabel
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      color={textColor}
                      display='flex'>
                      Número
                    </FormLabel>
                    <InputGroup size='md'>
                      <Input
                        value={houseNumber}
                        onChange={(event) => setHouseNumber(event.target.value)}
                        fontSize='sm'
                        mb='24px'
                        size='lg'
                        type={"number"}
                        variant='auth'
                      />
                    </InputGroup>
                  </FormControl>
                </Flex>
                <Flex flexDirection="row">
                  <FormControl m={2}>
                    <FormLabel
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      color={textColor}
                      display='flex'>
                      Ponto de referência
                    </FormLabel>
                    <InputGroup size='md'>
                      <Input
                        value={addressReference}
                        onChange={(event) => setAddressReference(event.target.value)}
                        fontSize='sm'
                        mb='24px'
                        size='lg'
                        type="text"
                        variant='auth'
                      />
                    </InputGroup>
                  </FormControl>
                  <FormControl m={2}>
                    <FormLabel
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      color={textColor}
                      display='flex'>
                      Link da localização
                    </FormLabel>
                    <InputGroup size='md'>
                      <Input
                        value={localization}
                        onChange={(event) => setLocalization(event.target.value)}
                        fontSize='sm'
                        mb='24px'
                        size='lg'
                        type="text"
                        variant='auth'
                      />
                    </InputGroup>
                  </FormControl>
                </Flex>
              </>
            )}
            <Flex marginLeft={2} direction="column">
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Foto da residência
              </FormLabel>
              {addressImageBase64 && (
                <Flex flexDirection="column" marginLeft={1}>
                  <Box boxSize="44" position="relative">
                    <Image
                      borderRadius={6}
                      objectFit="cover"
                      src={addressImageBase64 === "" ? "" : addressImageBase64}
                    />
                    {addressImageBase64 && (
                      <IconButton
                        position="absolute"
                        top={2}
                        left={2}
                        onClick={() => {
                          setAddressImage(null);
                          setAddressImageBase64(null);
                          setAddressImageFile(null)
                          if (addressImageBase64Key) {
                            setAddressImageBase64Key(addressImageBase64Key)
                          }
                          else {
                            setAddressImageBase64Key(null)
                          }
                        }}
                        icon={<AiFillDelete color="red" />}
                      />
                    )}
                  </Box>
                </Flex>
              )}
            </Flex>
            {!addressImageBase64 && (
              <Box marginTop={0} marginLeft={2}>
                <Tooltip placement='right' label='Adicionar foto da casa'>
                  <IconButton
                    colorScheme="blue"
                    onClick={handleButtonClick}
                    icon={<AiFillFileAdd />}
                  />
                </Tooltip>
                <input
                  type="file"
                  ref={inputFileRef}
                  style={{ display: 'none' }}
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </Box>
            )}
            {basic && (
              <Flex direction="column">
                <Flex>
                  <Text
                    color={textColor}
                    fontSize='18px'
                    fontWeight='700'
                    marginBottom={10}
                    marginTop={10}
                    marginLeft={2}
                    lineHeight='100%'>
                    Documento pessoal
                  </Text>
                </Flex>
                <Flex flexDirection={{ base: "column", md: "row" }}>
                  <FormControl m={2}>
                    <FormLabel
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      color={textColor}
                      display='flex'>
                      RG
                    </FormLabel>
                    <InputGroup size='md'>
                      <InputMask
                        value={rg}
                        onChange={(event) => setRg(event.target.value)}
                        fontSize='sm'
                        mb='24px'
                        style={
                          {
                            height: 46,
                            paddingLeft: 12,
                            borderRadius: 14,
                            borderColor: "#E0E5F2",
                            borderWidth: 1,
                            width: "100%"
                          }}
                        size='lg'
                        type={"text"}
                        mask="**.***.***-*"
                        variant='auth'
                      />
                    </InputGroup>
                  </FormControl>
                  <FormControl m={2}>
                    <FormLabel
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      color={textColor}
                      display='flex'>
                      Órgão expedidor
                    </FormLabel>
                    <InputGroup size='md'>
                      <Input
                        value={rgOrgao}
                        onChange={(event) => setRgOrgao(event.target.value)}
                        fontSize='sm'
                        mb='24px'
                        size='lg'
                        type={"text"}
                        variant='auth'
                      />
                    </InputGroup>
                  </FormControl>
                  <FormControl m={2}>
                    <FormLabel
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      color={textColor}
                      display='flex'>
                      CPF
                    </FormLabel>
                    <InputGroup size='md'>
                      <InputMask
                        value={cpf}
                        onChange={(event) => setCpf(event.target.value)}
                        fontSize='sm'
                        mb='24px'
                        style={
                          {
                            height: 48,
                            paddingLeft: 12,
                            borderRadius: 14,
                            borderColor: "#E0E5F2",
                            borderWidth: 1,
                            width: "100%"
                          }}
                        size='lg'
                        type={"text"}
                        mask="***.***.***-**"
                        variant='auth'
                      />
                    </InputGroup>
                  </FormControl>
                  <FormControl m={2}>
                    <FormLabel
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      color={textColor}
                      display='flex'>
                      CNH
                    </FormLabel>
                    <InputGroup size='md'>
                      <Input
                        value={cnh}
                        onChange={(event) => setCnh(event.target.value)}
                        fontSize='sm'
                        mb='24px'
                        size='lg'
                        type={"number"}
                        variant='auth'
                      />
                    </InputGroup>
                  </FormControl>
                  <FormControl m={2}>
                    <FormLabel
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      color={textColor}
                      display='flex'>
                      Número do SUS
                    </FormLabel>
                    <InputGroup size='md'>
                      <Input
                        value={susNumero}
                        onChange={(event) => setSusNumero(event.target.value)}
                        fontSize='sm'
                        mb='24px'
                        size='lg'
                        type={"tel"}
                        variant='auth'
                      />
                    </InputGroup>
                  </FormControl>
                </Flex>
              </Flex>
            )}
            {basic && (
              <>
                <Flex>
                  <Text
                    color={textColor}
                    fontSize='18px'
                    fontWeight='700'
                    marginBottom={10}
                    marginTop={10}
                    marginLeft={2}
                    lineHeight='100%'>
                    Título Eleitoral
                  </Text>
                </Flex>
                <Flex flexDirection="row">
                  <FormControl m={2}>
                    <FormLabel
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      color={textColor}
                      display='flex'>
                      Número do título
                    </FormLabel>
                    <InputGroup size='md'>
                      <Input
                        value={tituto}
                        onChange={(event) => setTituto(event.target.value)}
                        fontSize='sm'
                        mb='24px'
                        size='lg'
                        type={"tel"}
                        variant='auth'
                      />
                    </InputGroup>
                  </FormControl>
                  <FormControl m={2}>
                    <FormLabel
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      color={textColor}
                      display='flex'>
                      Zona
                    </FormLabel>
                    <InputGroup size='md'>
                      <Input
                        value={zona}
                        onChange={(event) => setZona(event.target.value)}
                        fontSize='sm'
                        mb='24px'
                        size='lg'
                        type={"text"}
                        variant='auth'
                      />
                    </InputGroup>
                  </FormControl>
                  <FormControl m={2}>
                    <FormLabel
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      color={textColor}
                      display='flex'>
                      Sessão
                    </FormLabel>
                    <InputGroup size='md'>
                      <Input
                        value={section}
                        onChange={(event) => setSection(event.target.value)}
                        fontSize='sm'
                        mb='24px'
                        size='lg'
                        type={"text"}
                        variant='auth'
                      />
                    </InputGroup>
                  </FormControl>
                </Flex>
                <Modal size="full" isOpen={openAddressImage} onClose={onCloseAddresImage}>
                  <ModalOverlay />
                  <ModalContent>
                    <ModalCloseButton />
                    <ModalBody>
                      <Cropper
                        image={addressImage}
                        crop={crop}
                        zoom={zoom}
                        aspect={4 / 4}
                        onCropChange={setCrop}
                        onCropComplete={(_, croppedAreaPixels) => {
                          setCroppedAreaPixels(croppedAreaPixels);
                        }}
                        onComplete={(imagePromisse) => {
                          imagePromisse.then((image) => {

                          });
                        }}
                        onZoomChange={setZoom}
                        containerStyle={{
                          position: "relative",
                          width: "100%",
                          height: 300,
                          background: "#333"
                        }}
                      />
                    </ModalBody>

                    <ModalFooter>
                      <Button colorScheme='red' mr={3} onClick={() => {
                        onCloseAddresImage()
                        setAddressImage(null)
                      }}>
                        Fechar
                      </Button>
                      <Button colorScheme='green' onClick={async () => {
                        const fileCrooped = await cropImage(addressImage, croppedAreaPixels, console.log)
                        setAddressImageBase64(fileCrooped)
                        setAddressImageFile(fileCrooped)
                        onCloseAddresImage()
                      }}>Selecionar</Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
                <Modal size="full" isOpen={openAddressImageProfile} onClose={onCloseAddresImageProfile}>
                  <ModalOverlay />
                  <ModalContent>
                    <ModalCloseButton />
                    <ModalBody>
                      <Cropper
                        image={photoProfile}
                        crop={crop}
                        zoom={zoom}
                        aspect={4 / 4}
                        onCropChange={setCrop}
                        onCropComplete={(_, croppedAreaPixels) => {
                          setCroppedAreaPixelsProfile(croppedAreaPixels);
                        }}
                        onZoomChange={setZoom}
                        containerStyle={{
                          position: "relative",
                          width: "100%",
                          height: 300,
                          background: "#333"
                        }}
                      />
                    </ModalBody>

                    <ModalFooter>
                      <Button colorScheme='red' mr={3} onClick={() => {
                        onCloseAddresImageProfile()
                        setPhoto("")
                        setPhotoPreView("")
                      }}>
                        Fechar
                      </Button>
                      <Button colorScheme='green' onClick={async () => {
                        const fileCrooped = await cropImage(photoProfile, croppedAreaPixelsProfile, console.log)
                        setPhoto(fileCrooped)
                        setPhotoPreView(fileCrooped)
                        createFile(fileCrooped)
                        onCloseAddresImageProfile()
                      }}>Selecionar</Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
                <Flex>
                  <Text
                    color={textColor}
                    fontSize='18px'
                    fontWeight='700'
                    marginBottom={10}
                    marginTop={10}
                    marginLeft={2}
                    lineHeight='100%'>
                    Redes sociais
                  </Text>
                </Flex>
                <Flex flexDirection="row" marginRight={2}>
                  <Flex flexDirection="column" w="full">
                    <FormControl m={2}>
                      <FormLabel
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        color={textColor}
                        display='flex'>
                        Link do Facebook
                      </FormLabel>
                      <InputGroup size='md'>
                        <Input
                          value={facebook}
                          onChange={(event) => setFacebook(event.target.value)}
                          fontSize='sm'
                          mb='24px'
                          size='lg'
                          type={"text"}
                          variant='auth'
                        />
                      </InputGroup>
                    </FormControl>
                    <Checkbox
                      label=" Não tem Facebook"
                      checked={isFace || false}
                      onChange={() => setIsFace(!isFace)}
                    ></Checkbox>
                  </Flex>
                  <Flex style={{ width: 10 }} />
                  <Flex flexDirection="column" w="full">
                    <FormControl m={2}>
                      <FormLabel
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        color={textColor}
                        display='flex'>
                        Link do Instagram
                      </FormLabel>
                      <InputGroup size='md'>
                        <Input
                          value={insta}
                          onChange={(event) => setInsta(event.target.value)}
                          fontSize='sm'
                          mb='24px'
                          size='lg'
                          type={"text"}
                          variant='auth'
                        />
                      </InputGroup>
                    </FormControl>
                    <Checkbox
                      label=" Não tem Instagram"
                      checked={isInst || false}
                      onChange={() => setIsInst(!isInst)}
                    ></Checkbox>
                  </Flex>
                </Flex>
              </>
            )}
            <Flex>
              <Text
                color={textColor}
                fontSize='18px'
                fontWeight='700'
                marginBottom={10}
                marginTop={10}
                marginLeft={2}
                lineHeight='100%'>
                Relacionamentos
              </Text>
            </Flex>
            <Flex flexWrap="wrap">
              {relations.map((item) => (
                <AvatarCustom
                  action={() => removeLeation(item._id)}
                  relation={item.relation}
                  name={item.name}
                  src={item.src}
                  id={item._id}
                />
              ))}
              <IconButton
                colorScheme="blue"
                onClick={() => onOpenRelation()}
                icon={<AiFillPlusCircle />}
              />
            </Flex>
            <Flex>
              <Text
                color={textColor}
                fontSize='18px'
                fontWeight='700'
                marginBottom={10}
                marginTop={10}
                marginLeft={2}
                lineHeight='100%'>
                Outros
              </Text>
            </Flex>
            <Flex display={{ md: 'flex' }}>
              <FormControl m={2}>
                <FormLabel
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  display='flex'>
                  Foi indicado por
                </FormLabel>
                <InputGroup size='md'>
                  <Input
                    value={indication}
                    onChange={(event) => setIndication(event.target.value)}
                    fontSize='sm'
                    mb='24px'
                    size='lg'
                    type={"text"}
                    variant='auth'
                  />
                </InputGroup>
              </FormControl>
              <FormControl m={2}>
                <FormLabel
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  display='flex'>
                  Ocupação
                </FormLabel>
                <InputGroup size='md'>
                  <Input
                    value={occupation}
                    onChange={(event) => setOccupation(event.target.value)}
                    fontSize='sm'
                    mb='24px'
                    size='lg'
                    type={"text"}
                    variant='auth'
                  />
                </InputGroup>
              </FormControl>
              <FormControl m={2}>
                <FormLabel
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  display='flex'>
                  E-mail
                </FormLabel>
                <InputGroup size='md'>
                  <Input
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    fontSize='sm'
                    mb='24px'
                    size='lg'
                    type={"text"}
                    variant='auth'
                  />
                </InputGroup>
              </FormControl>
            </Flex>
            <Flex>
              <FormControl m={2}>
                <FormLabel
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  display='flex'>
                  Categoria
                </FormLabel>

                <SelectChakra
                  isMulti
                  placeholder=""
                  onChange={(event) => setCategorie(event)}
                  colorScheme="green"
                  value={categorie}
                  options={gategories}
                />
              </FormControl>
            </Flex>
            <br />
            <Flex>
              <FormControl m={2}>
                <FormLabel
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  display='flex'>
                  Observação do cadastrado
                </FormLabel>
                <InputGroup size='md'>
                  <Textarea
                    value={description}
                    onChange={(event) =>
                      setDescription(event.target.value)
                    }
                    placeholder="Observação do cadastrado"
                  />
                </InputGroup>
              </FormControl>
            </Flex>
            <Flex marginTop={10} justifyContent="flex-end">
              <Button
                isLoading={loading}
                w="60"
                type="submit"
                variant='brand'
              >
                Salvar
              </Button>
            </Flex>
          </Form>
        )}
        <AlertDialog
          size="4xl"
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={() => {
            setOpen(false);
            setPhotoPreView("");
            setPhoto("");
            setfile(null);
            onClose();
          }}
          isCentered
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                Capturar imagem
              </AlertDialogHeader>
              <AlertDialogBody>
                {photoPreView ? (
                  <Image bgSize="cover" src={photoPreView} />
                ) : (
                  <div style={{ width: 300, height: 400 }}>
                    <WebcamCapture />
                  </div>
                )}
                {photo && (
                  <Button
                    onClick={() => {
                      setPhoto(photoPreView);
                      setOpen(false);
                    }}
                  />
                )}
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button
                  colorScheme="red"
                  marginRight={2}
                  onClick={() => {
                    onClose();
                    setPhotoPreView("");
                    setPhoto("");
                    setfile(null);
                  }}
                >
                  Fechar
                </Button>
                {photo && (
                  <Button
                    marginRight={2}
                    colorScheme="red"
                    onClick={() => {
                      setPhotoPreView("");
                      setPhoto("");
                    }}
                  >
                    Capturar outra foto
                  </Button>
                )}
                <Button
                  marginRight={2}
                  colorScheme="messenger"
                  onClick={() => {
                    handleClick();
                  }}
                >
                  Alterar câmera
                </Button>
                {photo && (
                  <Button
                    colorScheme="green"
                    onClick={() => {
                      setPhoto(photoPreView);
                      onClose();
                    }}
                  >
                    Selecionar está imagem
                  </Button>
                )}
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>

        <AlertDialog
          size="5xl"
          isOpen={openRelation}
          leastDestructiveRef={cancelRef}
          onClose={() => {
            setSearch("")
            setUsers([])
            setRelation(null)
            onCloseRelation()
          }}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='lg' fontWeight='bold' />
              <AlertDialogBody>
                <Input
                  fluid
                  onChange={(event) => setSearch(event.target.value)}
                  placeholder="Pesquisar cadastrado"
                  mb='24px'
                  size='lg'
                  type={"text"}
                  variant='auth'
                />
                {loadingSearch ? (
                  <Stack>
                    <Skeleton borderRadius={15} height='50px' />
                    <Skeleton marginTop="2" borderRadius={15} height='50px' />
                    <Skeleton marginTop="2" borderRadius={15} height='50px' />
                  </Stack>
                ) : (
                  <Flex direction="column">
                    {users
                      .filter((it) => it._id !== id)
                      .map((item) => (
                        <Flex marginTop={2} justifyContent="space-between" alignItems="center">
                          <Flex alignItems="center">
                            {item.photo ? (
                              <Avatar
                                rounded="md"
                                size="xl"
                                src={item.photo}
                              />
                            ) : (
                              <Avatar
                                rounded="md"
                                size="xl"
                                src="https://react.semantic-ui.com/images/wireframe/image.png"
                              />
                            )}
                            <Text
                              fontSize='md'
                              fontWeight='500'
                              marginLeft={2}
                              color={textColor}
                            >{item.name}</Text>
                          </Flex>
                          <Flex>
                            <Text
                              fontSize='sm'
                              fontWeight='500'
                              color={textColor}
                            >O que ele será do cadastrado?</Text>
                            <div style={{ marginRight: 6 }} />
                            <Select
                              onChange={(event) => {
                                const selected = relationsSelect.filter(
                                  (item) => item.key === Number(event.target.value)
                                )[0];
                                relationSelected.current = {
                                  userId: item._id,
                                  type: event.target.value
                                };
                                setRelation(selected.type);
                              }}
                              key={item._id}
                              placeholder='Tipo de relacionamento'
                            >
                              {relationsSelect.map((it) => (
                                <option value={it.value}>{it.text}</option>
                              ))}
                            </Select>
                            <IconButton
                              marginLeft={2}
                              colorScheme="blue"
                              onClick={() => requestInsertRelation(item._id)}
                              icon={<AiFillPlusCircle />}
                            />
                          </Flex>
                        </Flex>
                      ))}
                  </Flex>
                )}
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button ref={cancelRef}
                  variant='brand'
                  onClick={() => {
                    setSearch("")
                    setUsers([])
                    setRelation(null)
                    onCloseRelation()
                  }}>
                  Fechar
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Flex>
    </Box>
  );
};

export default PeopleUserPage;