import {
  Box,
  Flex,
  Text,
  IconButton,
  HStack,
  VStack,
  Stack,
  Collapse,
  Menu,
  MenuButton,
  Avatar,
  MenuDivider,
  MenuList,
  MenuItem,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  BellIcon
} from '@chakra-ui/icons';
import { useNavigate } from "react-router-dom";
import { useContext } from 'react';
import { AuthContext } from '../../context';
import { useDispatch, useSelector } from 'react-redux';
import { setCleanUser } from 'store/ducks/auth/actions';
import enviroment from '../../enviroment';

export default function SideBar() {
  const {
    name
  } = useSelector((state) => state.user);
  const userName = localStorage.getItem("name")?.split(" ")[0];
  const { setISAuth } = useContext(AuthContext);
  const { isOpen, onToggle, onClose } = useDisclosure();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const exitApp = () => {
    dispatch(setCleanUser());
    navigate("/login");
    localStorage.removeItem("@token");
    setISAuth(false);
  }
  const { setMenuOpen } = useContext(AuthContext);

  return (
    <Box position="fixed" width="full" zIndex={1} top={0}>
      <Flex
        color={useColorModeValue('gray.600', 'white')}
        backgroundColor={process.env.REACT_APP_ENVIRONMENT === "development" ? "#F9FAFE" : "#F9FAFE"}
        minH={'80px'}
        onMouseOut={() => setMenuOpen(true)}
        onMouseOver={() => setMenuOpen(false)}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align={'center'}>
        <Flex
          flex={{ base: 1, md: 'auto' }}
          ml={{ base: -2 }}
          display={{ base: 'flex', md: 'none' }}>
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
          <Text
            textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
            fontFamily={'heading'}
            color={useColorModeValue('gray.800', 'white')}>
          </Text>
          <Flex
            display={{ base: 'none', md: 'flex' }} ml={10}
          >
            <DesktopNav />
          </Flex>
        </Flex>
        <Box marginRight={4}>
          <BellIcon />
        </Box>
        <Box
          borderWidth={1}
          borderColor="grey.50"
          backgroundColor="#E3E8F4"
          paddingLeft={6}
          paddingRight={6}
          borderRadius={20}
        >
          <Stack
            justify={'flex-end'}
            direction={'row'}
            spacing={6}>
            <Flex alignItems={'center'}>
              <Menu>
                <MenuButton
                  py={2}
                  transition="all 0.3s"
                  _focus={{ boxShadow: 'none' }}>
                  <HStack>
                    <Avatar
                      size={'sm'}
                      src={
                        'https://www.clipartmax.com/png/middle/296-2969961_no-image-user-profile-icon.png'
                      }
                    />
                    <VStack
                      display={{ base: 'none', md: 'flex' }}
                      alignItems="flex-start"
                      spacing="1px">
                      <Text color="black" fontSize="sm">{userName}</Text>
                      <Text fontSize="14px" color="gray.600">
                        {name.split(' ')[0]}
                      </Text>
                    </VStack>
                  </HStack>
                </MenuButton>
                <MenuList
                  bg={useColorModeValue('white', 'gray.900')}
                  borderColor={useColorModeValue('gray.200', 'gray.700')}>
                  <MenuItem onClick={() => navigate("/usuarios")}>Usuários</MenuItem>
                  <MenuDivider />
                  <MenuItem onClick={() => navigate("/atualizar-senha")}>Alterar senha</MenuItem>
                  <MenuDivider />
                  <MenuItem onClick={() => navigate("/log")}>Log</MenuItem>
                  <MenuDivider />
                  <MenuItem onClick={() => exitApp()}>Sair</MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          </Stack>
        </Box>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav onClose={onClose} />
      </Collapse>
    </Box>
  );
}

const DesktopNav = () => {
  const { path, setPath } = useContext(AuthContext);
  const popoverContentBgColor = useColorModeValue('white', 'gray.800');
  const navigate = useNavigate();

  return (
    <div >
      <Stack
        direction={'row'} spacing={4}>
        {NAV_ITEMS.map((navItem) => (
          <Box key={navItem.label}>
            <Popover trigger={'hover'} placement={'bottom-start'}>
              <Box>
                <PopoverTrigger>
                  <Link
                    marginTop={10}
                    onClick={() => {
                      if ((navItem.href?.includes("/"))) {
                        setPath({
                          label: navItem.label,
                          href: undefined,
                          menu: navItem.label,
                        });
                        navigate(navItem.href);
                      }
                    }}
                    p={2}
                    fontSize={'md'}
                    fontWeight={500}
                    textDecoration=''
                    borderRadius={4}
                    paddingLeft="7"
                    paddingTop="2"
                    paddingBottom="2"
                    paddingRight="7"
                    backgroundColor={path.menu === navItem.label ? "#E3E8F4" : "transparent"}
                    color={"#24335A"}
                    _hover={{
                      textDecoration: 'none',
                      color: "grey",
                    }}>
                    {navItem.label}
                  </Link>
                </PopoverTrigger>
              </Box>

              {navItem.children && (
                <PopoverContent
                  border={0}
                  boxShadow={'xl'}
                  bg={popoverContentBgColor}
                  p={4}
                  rounded={'xl'}
                  minW={'sm'}>
                  <Stack>
                    {navItem.children.map((child) => (
                      <DesktopSubNav key={child.label} {...child} />
                    ))}
                  </Stack>
                </PopoverContent>
              )}
            </Popover>
          </Box>
        ))}
      </Stack>
    </div>
  );
};

const DesktopSubNav = ({ label, href, subLabel, menu }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setPath } = useContext(AuthContext);

  return (
    <Box
      onClick={() => {
        if (href) {
          setPath({
            label: label,
            href: href,
            menu: menu,
          });
          localStorage.setItem("label", label);
          localStorage.setItem("href", href);
          navigate(href);
          dispatch({
            type: "@SET_CLEAN_FILTER",
          });
          dispatch({
            type: "@SET_CLEAN_FILTER_LEGISLATION",
          });
        }
      }}
      role={'group'}
      display={'block'}
      p={2}
      rounded={'md'}
      _hover={{ bg: useColorModeValue('#F9FAFE', 'gray.900') }}>
      <Stack direction={'row'} align={'center'}>
        <Box>
          <Text
            fontSize={'sm'}
            transition={'all .3s ease'}
            _groupHover={{ color: 'black' }}
            fontWeight={500}>
            {label}
          </Text>
          <Text fontSize={'sm'}>{subLabel}</Text>
        </Box>
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify={'flex-end'}
          align={'center'}
          flex={1}>
          <Icon color={'black'} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Box>
  );
};

const MobileNav = ({onClose}) => {
  return (
    <Stack
      bg={useColorModeValue('white', 'gray.800')}
      p={4}
      display={{ md: 'none' }}>
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} onClose={onClose} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href, onClose }) => {
  const { isOpen, onToggle } = useDisclosure();
  const navigate = useNavigate();
  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        onClick={() => {
          if (href.includes('/')) {
            navigate(href)
          }
        }}
        justify={'space-between'}
        align={'center'}
        _hover={{
          textDecoration: 'none',
        }}>
        <Text
          fontWeight={600}
          color={useColorModeValue('gray.600', 'gray.200')}>
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={'all .25s ease-in-out'}
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        )}
      </Flex>
      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.700')}
          align={'start'}>
          {children &&
            children.map((child) => (
              <Link
                onClick={() => {
                  if (child.href) {
                    onClose()
                    localStorage.setItem("label", child.label);
                    localStorage.setItem("href", child.href);
                    navigate(child.href)
                  }
                }}
                key={child.label}
                py={2}
              >
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

const NAV_ITEMS = [
  {
    label: 'DASHBOARD',
    href: '/dashboard',
  },
  {
    label: 'CADASTROS',
    href: 'CADASTROS',
    children: [
      {
        menu: 'CADASTRO RÁPIDO',
        label: 'CADASTRO RÁPIDO',
        href: '/cadastro-rapido',
        
      },
      // {
      //   menu: 'CADASTRO DE PESSOAS',
      //   label: 'CADASTRO DE PESSOAS',
      //   href: '/cadastrodepessoa',
      // },
      {
        menu: 'CADASTRO DE PROJETO DE LEI',
        label: 'CADASTRO DE PROJETO DE LEI',
        href: '/cadastro-lei-de-projeto',
      },
      {
        menu: 'CADASTRO DE CATEGORIA DE PESSOAS',
        label: 'CADASTRO DE CATEGORIA DE PESSOAS',
        href: '/cadastro-de-categoria-de-pessoa',
      },
      {
        menu: 'CADASTRO DE CATEGORIA DE DEMANDAS',
        label: 'CADASTRO DE CATEGORIA DE DEMANDAS',
        href: '/cadastro-de-categoria-de-demandas',
      },
    ],
  },
  {
    label: 'LISTAGEM',
    href: 'LISTAGEM',
    children: [
      {
        menu: 'PESSOAS',
        label: 'PESSOAS',
        href: '/cadastrados',
      },
      {
        menu: 'PROJETOS DE LEI',
        label: 'PROJETOS DE LEI',
        href: '/projetos-de-lei',
      },
      {
        menu: 'MAPA',
        label: 'MAPA',
        href: '/mapa',
      },
      {
        menu: 'DEMANDAS',
        label: 'DEMANDAS',
        href: '/demandas',
      },
      {
        menu: 'CATEGORIA DE PESSOAS',
        label: 'CATEGORIA DE PESSOAS',
        href: '/categorias-de-pessoas',
      },
      {
        menu: 'CATEGORIA DE DEMANDAS',
        label: 'CATEGORIA DE DEMANDAS',
        href: '/listagem-de-categorias-de-demanda',
      },
      {
        menu: 'BAIRROS',
        label: 'BAIRROS',
        href: '/bairros',
      },
    ],
  },
];
