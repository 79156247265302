/* eslint-disable prefer-const */
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Text, useColorModeValue, useToast } from '@chakra-ui/react'
import { AiOutlineEdit, AiFillDelete, AiFillCheckCircle } from "react-icons/ai";
import Cardcomponent from "components/@card";

import apiCategory from "../../services/urls/category";
import SideBar from "components/@sidebar";
import {
  Input,
  AlertDialog,
  AlertDialogBody,
  Button,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Flex,
  IconButton,
  Skeleton,
  Icon,
  Stack,
  useDisclosure
} from "@chakra-ui/react";
import { UltimatePagination } from "components/pagination";
import { FaRegUser } from "react-icons/fa";
import { debounce } from "lodash";


const CategoriesOfPeoplePage = () => {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const toast = useToast();
  let shoulRequest = useRef(true);
  const { _id: accountId, name: accountName } = useSelector(
    (state) =>
      state.user
  );
  const [search, setSearch] = useState("")
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [page, setPage] = useState(1);
  const [perPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [isEmpty, setIsEmpty] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef()

  const [categories, setCategories] = useState([]);

  const requestCategories = useCallback(async () => {
    try {
      setLoading(true);
      const response = await apiCategory.GetCategories(page, perPage, search);
      setCategories(
        response?.data?.data?.docs.map(
          (item) => {
            return {
              key: item._id,
              text: item.name,
              value: item._id,
              active: item.active,
              _id: item._id,
              userCount: item.userCount,
            };
          }
        )
      );
      if (response?.data?.data?.docs.length === 0) {
        setIsEmpty(true)
      }
      else {
        setIsEmpty(false)
      }
      setTotalPages(response?.data?.data?.totalPages);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: '',
        description: "Erro ao listar as categorias, tente novamente.",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  }, [page, perPage, search, toast])

  const deleteCategory = useCallback(async (id, name) => {
    try {
      await apiCategory.deleteCategorie(id, {
        accountId,
        accountName,
        day: moment().format("DD/MM/YYYY"),
        hour: moment().format("HH:mm:ss"),
        userAction: "Desativou",
        userId: undefined,
        userName: undefined,
        description: `Desativou a categoria ${name}`,
        dateTime: moment().format(),
      });
      toast({
        title: '',
        description: "Categoria apagada com sucesso",
        status: 'success',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
      setCategories(
        categories.filter((category) => category._id !== id)
      );
    } catch (error) {
      toast({
        title: '',
        description: "Erro ao desativar categoria, tente novamente.",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  }, [accountId, accountName, categories, toast])

  const editCategory = async () => {
    try {
      setLoadingSave(true);
      await apiCategory.updateCategorie(categoryId, {
        categoryName,
        accountId,
        accountName,
        day: moment().format("DD/MM/YYYY"),
        hour: moment().format("HH:mm:ss"),
        userAction: "Editou",
        userId: undefined,
        userName: undefined,
        description: `Editou a categoria ${categoryName}`,
        dateTime: moment().format(),
      });
      toast({
        title: '',
        description: "Categoria atualizada com sucesso.",
        status: 'success',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
      setCategories(
        categories.map((item) => {
          if (item._id === categoryId) {
            item.text = categoryName;
            return item;
          }
          return item;
        })
      );
      setCategoryId("");
      setCategoryName("");
      onClose();
      setLoadingSave(false);
      requestCategories();
    } catch (error) {
      setLoadingSave(false);
      toast({
        title: '',
        description: "Erro ao atualizar categoria, tente novamente.",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const prepareData = (data) => {
    try {
      setCategoryId(data._id);
      setCategoryName(data.text);
      onOpen();
    } catch (error) {
      toast({
        title: '',
        description: "Erro ao preparar dados para edição.",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (shoulRequest.current) {
      shoulRequest.current = false;
      requestCategories();
    }
  }, [requestCategories]);


  const debouncedSearchCategory = useCallback(
    debounce(() => {
      if (search) {
        requestCategories()
      }
    }, 600),
    [requestCategories, search]
  )

  useEffect(() => {
    debouncedSearchCategory()
    return () => {
      debouncedSearchCategory.cancel()
    }
  }, [debouncedSearchCategory, requestCategories, search])

  useEffect(() => {
    if (!search) {
      requestCategories()
    }
  }, [page, requestCategories, search])

  useEffect(() => {
    setPage(1);
  }, [search]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SideBar />
      <Flex padding={10} flexDirection="column">
        <Input
          value={search}
          bg="white"
          onChange={(event) => setSearch(event.target.value)}
          variant='auth'
          fontSize='sm'
          ms={{ base: "0px", md: "0px" }}
          type='text'
          placeholder="Pesquisar"
          mb='24px'
          fontWeight='500'
          size='lg'
        />
        {loading ? (
          <Stack>
            <Skeleton borderRadius={15} height='80px' />
            <Skeleton marginTop="2" borderRadius={15} height='80px' />
            <Skeleton marginTop="2" borderRadius={15} height='80px' />
            <Skeleton marginTop="2" borderRadius={15} height='80px' />
            <Skeleton marginTop="2" borderRadius={15} height='80px' />
          </Stack>
        ) : (
          <>
            {categories?.map((item) => (
              <Box marginTop={2}>
                <Cardcomponent
                  endContent={
                    <Flex>
                      <Flex marginRight={6} justifyContent="center" alignItems="center">
                        <Text
                          color={textColor}
                          fontSize='lg'
                          textTransform="capitalize"
                          marginRight={2}
                          mt={1}
                          fontWeight="500">
                          {item.userCount}
                        </Text>
                        <FaRegUser color='#ccc' />
                      </Flex>
                      <IconButton
                        w='36px'
                        h='36px'
                        onClick={() => prepareData(item)}
                        color="orange.500"
                        bg={"gray.200"}
                        icon={
                          <Icon
                            as={AiOutlineEdit}
                          />
                        }
                        marginRight={1}
                      />
                      <IconButton
                        w='36px'
                        h='36px'
                        isDisabled={item.userCount > 0}
                        color="red.300"
                        bg={"gray.200"}
                        onClick={() => {
                          deleteCategory(item._id, item.text);
                        }}
                        icon={<Icon as={AiFillDelete} />}
                      />
                    </Flex>
                  }
                  name={`${item.text}`}
                />
              </Box>
            ))}
          </>
        )}
        {isEmpty && (
          <Flex justifyContent="center">
            <Text>Sem resultados</Text>
          </Flex>
        )}
        {!loading && !isEmpty && (
          <Flex justifyContent="center" marginTop="6">
            {totalPages > 0 && (
              <UltimatePagination
                currentPage={page}
                onChange={setPage}
                totalPages={totalPages}
              />
            )}
          </Flex>
        )}
      </Flex>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => {
          setCategoryId("");
          setCategoryName("");
          onClose();
        }}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Editar categoria
            </AlertDialogHeader>

            <AlertDialogBody>
              <Input
                maxLength={80}
                type="text"
                value={categoryName}
                onChange={(event) => setCategoryName(event.target.value)}
                placeholder="Nome da categoria"
              />
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                disabled={loadingSave}
                ref={cancelRef}
                onClick={() => {
                  setCategoryId("");
                  setCategoryName("");
                  onClose();
                }}>
                Fechar
              </Button>
              <Button
                isLoading={loadingSave}
                variant='brand'
                onClick={editCategory}
                ml={3}>
                Salvar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};
export default CategoriesOfPeoplePage;
