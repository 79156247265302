import axiosDefault from "../../api";

const GetCategories = async (
  page,
  perPage,
  search,
) => {
  const data = axiosDefault.get(`categories`, {
    params: {
      perPage,
      page,
      categoryName: search,
    },
  });
  return data;
};
const GetCategoriesActive = async () => {
  const data = axiosDefault.get(`categories-active`);
  return data;
};

const deleteCategorie = async (
  id,
  payload
) => {
  const data = axiosDefault.post(`delete-category/${id}`, payload);
  return data;
};
const activeCategorie = async (
  id,
  payload
) => {
  const data = axiosDefault.put(`category/${id}`, payload);
  return data;
};
const saveCategorie = async (payload) => {
  const data = axiosDefault.post(`category`, payload);
  return data;
};
const updateCategorie = async (
  id,
  payload
) => {
  const data = axiosDefault.put(`category/${id}`, payload);
  return data;
};
export default {
  GetCategories,
  deleteCategorie,
  activeCategorie,
  saveCategorie,
  updateCategorie,
  GetCategoriesActive,
};
