import React from "react";
import ReactDOM from "react-dom/client";
import "assets/css/App.css";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import "video-react/dist/video-react.css";

import Store, { persistor } from "./store/index";
import { ToastContainer } from "react-toastify";
import AuthProvider from './context';
import App from "app";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://85dde307a9d8c399f1aacad04f7b805c@o4508094706614272.ingest.us.sentry.io/4508095553273856",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  tracesSampleRate: 1.0, 
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
  document.getElementById("root")
);

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <AuthProvider>
        <ToastContainer />
        <Provider store={Store}>
          <PersistGate persistor={persistor}>
            <ThemeEditorProvider>
              <App />
            </ThemeEditorProvider>
          </PersistGate>
        </Provider>
      </AuthProvider>
    </ChakraProvider>
  </React.StrictMode>,
);
